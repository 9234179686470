import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { InventoryService } from '../../services/inventory.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-nonstock',
	templateUrl: './nonstock.component.html',
	styleUrls: ['./nonstock.component.scss']
})
export class NonstockComponent implements OnInit {
	@Output() cancel = new EventEmitter < boolean > ();
	@Output() nonstockadded = new EventEmitter < any > ();
	@ViewChild('nonstock') nonstockref: ElementRef;

	config: any = [];
	nonStockForm: UntypedFormGroup;
	librarysearch = new UntypedFormControl('');
	librarySearchResults: any = [];
	librarySearchSelected: any = false;
	librarySearching: any = false;
	brands: any = [];
	selectedsupplier: any = false;
	estprice: any = 0.00
	suggestedMarkup = 0;
	loadingItem: boolean;
	constructor(private fb: UntypedFormBuilder,private inventoryService: InventoryService,public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private location: Location, private globalsService: GlobalsService, private modalService: NgbModal) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {
		this.setForm();
	}

	getMarkup() {
		//costing markup

		// 0-76 + 10
		// 77-175 *.88
		// 176-288 + 20
		// 200+ 25$
		let markup = 0
		const basecost = this.financial(this.nonStockForm.get('cost').value);
		if(basecost) {

			const cost = this.financial(basecost)

			if(cost > 0 && cost <= 75) {
				markup = 10;
			}

			if(cost > 75 && cost <= 175) {
				markup = this.financial(cost * 0.88);
			}

			if(cost > 175 && cost <= 300) {
				markup = 20;
			}

			if(cost > 300) {
				markup = 25;
			}

		}
		this.suggestedMarkup = markup;
		this.estprice = this.financial(basecost + markup);
		this.nonStockForm.get('price').setValue(this.estprice)
		return this.estprice;
	}

	setForm() {
		this.selectedsupplier = false;
		this.librarysearch.setValue('')
		this.nonStockForm = this.fb.group({
			stockid: [this.config.features.nonstockitem, [Validators.required]],
			description: ['', [Validators.required]],
			price: ['', [Validators.required]],
			cost: ['', [Validators.required]],
			//supplier: ['', [Validators.required]],
			brand: ['', [Validators.required]],
			barcode: ['',[Validators.required]],
			partno: ['',[Validators.required]],
			qty: [1, [Validators.required]],
			supplier: ['', [Validators.required]],
			fet: [''],
		});

		if(this.config.env.package != 'tires') {
			this.nonStockForm.get('barcode').setValidators(null);
			this.nonStockForm.get('partno').setValidators(null);
			this.nonStockForm.get('cost').setValidators(null);
			this.nonStockForm.updateValueAndValidity();
		}

		this.nonStockForm.get('brand').valueChanges.subscribe( newValue => {
			if(newValue != '') {
				this.inventoryService.getSupplierFromBrand(newValue).subscribe( r => {
					if(r[0]) {
						if(this.config.env.package != 'tires') {
							this.selectedsupplier = r[0];
							this.nonStockForm.get('supplier').setValue(r[0].supplierid)
						}
					}
				});
			}
		})


		this.nonStockForm.get('cost').valueChanges.subscribe( newValue => {
			this.getMarkup();
		})


		this.nonStockForm.markAllAsTouched();

	}

	searchTireLibrary() {
		this.inventoryService.librarySearch(this.librarysearch.value).subscribe((newValue: any) => {
			this.librarySearch(newValue);
		})
	}

	selectLibraryItem(item: any) {

		this.loadingItem = true;
		const data = { item: item }
		this.inventoryService.librarySelect(data).subscribe((lib: any) => {

			const brandexists = this.brands.filter(r => {
				return r.name == lib.mfg
			});

			if (!brandexists.length) {
				const newbrand = {
					manufacturer_id: '-1',
					mfg_code: lib.mfg,
					name: lib.mfg,
				}
				this.brands.push(newbrand)
				this.nonStockForm.get('brand').setValue(newbrand);
			} else {
				this.nonStockForm.get('brand').setValue(brandexists[0]);
			}

			this.librarySearchSelected = lib
			this.librarySearchResults = false;
			this.nonStockForm.get('description').setValue(lib.name);

			this.nonStockForm.get('partno').setValue(lib.itemcode);
			if (lib.upc) {
				this.nonStockForm.get('barcode').setValue(lib.upc);
			} else {
				this.nonStockForm.get('barcode').setValue(lib.itemcode);
			}
			this.loadingItem = false;
		});
	}

	selectNonStockVendor(event: any) {
		this.selectedsupplier = event;
		this.nonStockForm.get('supplier').setValue(event);
	}

	librarySearch(search: any) {

		if (this.librarySearching) {
			this.librarySearching.unsubscribe()
		}

		const data = { keywords: search, details: this.nonStockForm.value }

		this.librarySearching = this.inventoryService.librarySearch(data).subscribe((lib: any) => {
			this.librarySearchResults = lib
			this.librarySearching = false;
		});

	}

	addNonStock() {

		if(this.nonStockForm.valid) {
			this.nonstockadded.emit(this.nonStockForm.value);
			this.setForm();
			this.modalService.dismissAll();
		} else {
			this.getFormValidationErrors();
		}
	}

	nonStockModal() {

		this.librarysearch.valueChanges.subscribe(newValue => {
			this.librarySearch(newValue);
		})

		this.inventoryService.getBrands().subscribe(results => {
			this.brands = results
		})
		this.modalService.open(this.nonstockref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
			this.setForm();
		}, (reason) => {

		});
	}

	getFormValidationErrors() {
		Object.keys(this.nonStockForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.nonStockForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.nonStockForm.markAllAsTouched();
					this.globalSearchService.showNotification(key + ' ' + keyError , 'danger', 'bottom', 'right')
				});
			}
		});
	}

	financial(num: number): number {
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
		return parseFloat(rounded.toFixed(2));
	}
}
