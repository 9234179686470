  <table class="mt-3 table table-hover table-striped">
	<thead class="sticky-top thead-dark">
	  <tr>
		<th scope="col">#</th>
		<th scope="col">Location</th>
		<th scope="col">Item ID</th>
		<th scope="col">Description</th>
		<th scope="col">Quantity</th>
		<th scope="col">Actions</th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let item of negativeItems; let i = index">
		<th scope="row">{{ i + 1 }}</th>
		<td>{{ item.location }}</td>
		<td>{{ item.item }}</td>
		<td>{{ item.description }}</td>
		<td>{{ item.cnt }}</td>
		<td>
		  <a [routerLink]="'/inventory/view/'+item.item" >View</a>
		</td>
	  </tr>
	</tbody>
  </table>
