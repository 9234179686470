
<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Qty To Release</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body text-dark">
		<div class="row mb-3">
			<div class="col-md-6 card-group mb-3">
				<div class="card m-0">
					<div class="card-header">
						From:
					</div>
					<div class="card-body pre-line">
						{{ fromaddress }}
					</div>
				</div>
			</div>
			<div class="col-md-6 card-group mb-3">
				<div class="card m-0">
					<div class="card-header">
						To:
					</div>
					<div class="card-body pre-line">
						{{ toaddress }}
					</div>
				</div>
			</div>
		</div>
		<div class="row table-header">
			<div class="col-md-2">
				Item Code
			</div>
			<div class="col-md-3">
				Description
			</div>
			<div class="col-md-1">
				Bin
			</div>
			<div class="col-md-1">
				Req'd Qty
			</div>
			<div class="col-md-1">
				Bin Qty
			</div>
			<div class="col-md-2 text-center">
				Qty to Release <mat-checkbox (change)="toggleAll($event)" class="text-white"></mat-checkbox>
			</div>
			<div class='col-md-1 text-center'>
				Status
			</div>
		</div>
		<div class="row bottom-divider" *ngFor="let bin of items; let i = index">
			<div class="col-md-2">
				{{bin.stockid}}
			</div>
			<div class="col-md-3 text-left">
				{{bin.description}}
			</div>
			<div class="col-md-1">
				{{bin.bincode}}
			</div>
			<div class="col-md-1">
				{{bin.transferqty}}
			</div>
			<div class="col-md-1">
				{{bin.from_bin_qoh}}
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput tabindex="{{ i + 2 }}" type="number" [value]="bin.quantity" autofocus (input)="updateBinQuantity($event, bin)" class="text-right">
				</mat-form-field>
			</div>
			<div class="col-md-1" [ngClass]="{
				'warn': parseF(bin.transferqty) > parseF(bin.quantity),
				'good': parseF(bin.transferqty) == parseF(bin.quantity),
				'bad': parseF(bin.transferqty) < parseF(bin.quantity)
			}">
				<mat-icon>check_box</mat-icon>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-10"></div>
		<div class="col-md-2">
			<button class="btn btn-success" (click)="releaseItems(items)">Release Items</button>
		</div>
	</div>
</ng-template>


<div class="container-fluid">

	<div class="row">

		<div class="container-fluid">
			<form [formGroup]="searchForm">
				<div class="row">
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>Start Date</mat-label>
							<input matInput [matDatepicker]="picker1" formControlName="startdate" (dateChange)="getTransfers()" />
							<mat-datepicker-toggle matSuffix [for]="picker1">
							</mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>End Date</mat-label>
							<input matInput [matDatepicker]="picker2" formControlName="enddate" (dateChange)="getTransfers()" />
							<mat-datepicker-toggle matSuffix [for]="picker2">
							</mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>From Location</mat-label>
							<mat-select formControlName="fromloc" (selectionChange)="getTransfers()">
								<mat-option value="">
									---- Any ----
								</mat-option>
								<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
									{{loc.locationname }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
			<div class="table ov">
				<div class="text-right"> Results ({{transferlist.length}})</div>
				<table class="table table-sm">
					<tr>
						<th>Document #</th>
						<th>Input Date</th>
						<th>From Location</th>
						<th>To Location</th>
						<th>Notes</th>
						<th>Status</th>
						<th>Total</th>
					</tr>
					<tr *ngFor="let t of transferlist">
						<td><button class="btn btn-sm button-primary" (click)="getTransferData(t.transno);">{{ t.transno }}</button></td>
						<td>{{ t.inputdate | date }}</td>
						<td>{{ t.fromstkloc }}</td>
						<td>{{ t.tostkloc }}</td>
						<td>{{ t.notes }}</td>
						<td>
							<div [ngClass]="statuses[t.status].code == 0 ? 'entered' : statuses[t.status].code == 1 ? 'released' : statuses[t.status].code == 2 ? 'received' : statuses[t.status].code == 3 ? 'cancelled' : ''">{{ statuses[t.status].description }}</div>
						</td>
						<td>{{t.ovasaletotal | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</div>
