import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent,NgxDropzonePreviewComponent } from 'ngx-dropzone';
import { SystemService } from '../../../services/system.service';

@Component({
	selector: 'app-banners',
	templateUrl: './banners.component.html',
	styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
	banners: any = false;
	banner = { images: [{ title: '', link: '', image: '', thumb: '', sort_order: '' }] };
	@ViewChild('bannerEdit') bannerEditRef: ElementRef;
	bannerForm: any;
	statuses = [{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }];
	uploadimages: any = [];
	constructor(
		private formBuilder: FormBuilder,
		public systemService: SystemService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.bannerForm = this.formBuilder.group({
			images: this.formBuilder.array([])
		});
		this.loadBanners();
	}

	get images() {
		return this.bannerForm.get('images') as FormArray;
	}

	loadBanners() {
		const data = [];
		this.systemService.getBanners(data).subscribe(r => {
			this.banners = r.banners;
		});
	}

	editBanner(banner: any) {
		this.banner = banner;
		this.bannerForm = this.formBuilder.group({
			banner_id: [banner.banner_id],
			name: [banner.name],
			image: [banner.image],
			status: [true, Validators.required],
			images: [banner.images],
		});

		this.modalService.open(this.bannerEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
			// Handle modal close
		}, (reason) => {
			// Handle modal dismiss
		});
	}

	createBanner() {
		this.bannerForm = this.formBuilder.group({
			banner_id: [''],
			name: [''],
			image: [''],
			status: [true, Validators.required],
			images: [
				[], Validators.required
			],
		});

		this.modalService.open(this.bannerEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
			// Handle modal close
		}, (reason) => {
			// Handle modal dismiss
		});
	}

	addBanner() {
		const bannerGroup = this.formBuilder.group({
			title: ['', Validators.required],
			link: ['', Validators.required],
			image: ['', Validators.required],
			thumb: [''],
			sort_order: ['', Validators.required]
		});
		this.images.push(bannerGroup);
	}

	removeBanner(banner: any) {
		const data = { bannerid: banner.banner_id };
		this.systemService.removeBanner(data).subscribe(() => {
			this.banners = this.banners.filter(b => b.banner_id !== banner.banner_id);
		});
	}

	saveBanner() {
		this.systemService.saveBanners(this.bannerForm.value).subscribe(() => {
			this.modalService.dismissAll();
			this.loadBanners();
		});
	}

	addBannerImage() {
		this.banner.images.push({
			title: '',
			link: '',
			image: '',
			thumb: '',
			sort_order: ''
		});
	}

	removeBannerImage(index: any) {
		this.banner.images.splice(index, 1);
	}

	onFileChange(event: NgxDropzoneChangeEvent, index: number) {
		const file = event.addedFiles[0];
		const reader = new FileReader();
		reader.onload = () => {
			const formArray = this.bannerForm.get('images') as FormArray;
			if (index >= 0 && index < formArray.length) {
				const thumbControl = formArray.at(index).get('thumb');
				thumbControl.setValue(reader.result as string);
				this.updateThumbPreview(reader.result as string, index);
			}
		};
		if (file instanceof Blob) {
			reader.readAsDataURL(file);
		}
	}

	onSelect(event: any, index: any) {

		this.uploadimages.push(...event.addedFiles);
		for (let i = 0; i < this.uploadimages.length; i++) {
			this.readFile(this.uploadimages[i]).then(fileContents => {
				//stuff
				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.systemService.uploadFile(upload, this.bannerForm.get('banner_id').value).subscribe((result: any) => {
					 this.banner.images[index].image = result.file;
					 this.banner.images[index].thumb = result.thumb;
				 });

			});
		}
	}

	onRemove(event: any) {
		this.uploadimages.splice(this.uploadimages.indexOf(event), 1);
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
		  const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.uploadimages = [];
	}

	updateThumbPreview(thumb: string, index: number) {
		this.banner.images[index].thumb = thumb;
		this.banner.images[index].image = thumb;
	}

	updateTitle(title: string, index: number) {
		this.banner.images[index].title = title;
	}

	updateLink(link: string, index: number) {
		this.banner.images[index].link = link;
	}

	updateSortOrder(sort_order: string, index: number) {
		this.banner.images[index].sort_order = sort_order;
	}
}
