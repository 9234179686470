import { Component, EventEmitter,OnInit, Input, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { UsersService } from '../../../services/users.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

@Component({
	selector: 'app-customer-lookup',
	templateUrl: './customer-lookup.component.html',
	styleUrls: ['./customer-lookup.component.scss']
})

export class CustomerLookupComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild('customerinput') customerinput: ElementRef;
	@Output() customer_selected = new EventEmitter < any > ();
	@Input() creating = false;
	@Input() lookupOnly: any = false;
	title = 'customers';
	outlined = 'outline';
	customers: any = false;
	all_customers: any = false;
	allcustomers: any = false;
	filteredItems: any = [];
	customersearchservice: any;
	timeout: any = null;

	searching = false;
	headercolumns: any = [
		'actions',
		'name',
		'billingaddress',
		//  'phoneno'
	];

	customersearching = false;
	customersearchsubscription: any;
	noresults = false;
	ready = true;
	sending = false;
	color: any = 'blue';

	customertypes: any = false;
	customertype= new UntypedFormControl('')
	customersearch = new UntypedFormControl('')
	include_disabled = new UntypedFormControl(false);
	search_by_order = new UntypedFormControl(false);
	phoneonly = new UntypedFormControl(false);

	config: any = false;
	user: any = false;
	isPosUser: any = false;
	constructor(private usersService: UsersService, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, public customerService: CustomerService, public router: Router) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;

		});

		this.globalSearchService.userData.subscribe(r => {
			this.user = r;
		});
	}

	ngOnInit(): void {
		this.ready = false;
		this.noresults = true;
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.mesagesallowed.next(true);
		if(this.creating) {
			this.outlined = 'outline';
		}
		this.globalSearchService.customertypes.subscribe( r => {
			this.customertypes = r;
		})
		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isPosUser = true;
			}
		})
	}

	filterCustomers() {
		this.ready = false;

		if (this.customersearchsubscription) {
			this.customersearchsubscription.unsubscribe();
		}

		const search = {
			customertype: this.customertype.value,
			keywords: this.customersearch.value,
			include_disabled: this.include_disabled.value,
			search_by_order: this.search_by_order.value,
			phoneonly: this.phoneonly.value
		}
		this.sending = true;
		this.customersearching = true;
		this.customersearchsubscription = this.customerService.getCustomerSearch(search).subscribe((results: any) => {
			this.sending = false;
			this.all_customers = results;



			if (this.include_disabled) {
				//results = results
			} else {
				results = results.filter((customer) => {
					return customer.disabletrans == this.include_disabled;
				})
			}
			this.customersearching = false;
			this.ready = true;
			this.noresults = (results.length) ? false : true;
			if(results.length == 1) {
				this.customer_selected.emit(results[0]);
				this.clearForm();
			} else {
				if(this.user && this.user.user && this.user.user.issalesman) {
					this.globalSearchService.blurInputElement(this.customerinput)
				}
			}

			this.customers = new MatTableDataSource(results);
			this.customers.sort = this.sort;
			this.customers.paginator = this.paginator;
		});
	}

	clearForm() {
		this.noresults = true;
		this.customersearching = false;
		this.customers = false;
		this.all_customers = false;
		this.customersearch.setValue('')
	}

	filterDisabled() {
		if (this.include_disabled.value) {
			this.customers = this.all_customers;
			this.customers.sort = this.sort;
			this.customers.paginator = this.paginator;
		} else {
			this.customers = this.all_customers.filter((customer) => {
				return customer.disabletrans == this.include_disabled.value;
			})
			this.customers.sort = this.sort;
			this.customers.paginator = this.paginator;
		}
	}

	selectCustomer(row: any) {
		this.clearForm();
		this.customer_selected.emit(row);
	}

	announceSortChange(sortState: Sort) {

	}

	ngAfterViewInit() {
		//fromEvent(this.customerinput.nativeElement, 'keyup')
		//	.pipe(
		//		debounceTime(150),
		//		distinctUntilChanged(),
		//	)
		//	.subscribe();
	}
}
