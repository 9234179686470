<ng-template #assignModal let-modal>
<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add Products to {{ editcat.name }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
			<h4 class="text-center">Add Products: Search Criteria</h4>

							<div class="row">
                                <div class="col-4">
										<mat-form-field>
										<mat-label>Manufacturer</mat-label>
                                        <mat-select [formControl]="mfgs" multiple="multiple" >
												<mat-option *ngFor="let mfg of manus" [value]="mfg.manufacturer_id"(MatSelectChange)="productSearch()">
														{{mfg.name }}
													</mat-option>

                                        </mat-select>
										</mat-form-field>
                                </div>
                                <div class="col-4">
										<mat-form-field>
										<mat-label>Stock Category</mat-label>
                                        <mat-select [formControl]="cats" multiple="multiple" >
												<mat-option *ngFor="let cat of stockcats" [value]="cat.categoryid" (MatSelectChange)="productSearch()">
														{{cat.categorydescription }}
													</mat-option>

                                        </mat-select>
										</mat-form-field>
                                </div>
                                <div class="col-4">
										<mat-form-field>
										<mat-label>Product Line</mat-label>
                                        <mat-select [formControl]="pls" multiple="multiple">
												<mat-option *ngFor="let pl of prodlines" [value]="pl.line_field" (MatSelectChange)="productSearch()">
														{{pl.line_description }}
													</mat-option>

                                        </mat-select>
										</mat-form-field>
                                </div>
							</div>
				<div class="row">
                                <div class="col-12">
                                <br />
								<mat-form-field>
								<mat-label>Description Search</mat-label>
                                <input matInput type="text" name="keywords" [formControl]="prodsrch" class="rule form-control" placeholder="Description Search" value="" (change)="productSearch()">
								</mat-form-field>
                                </div>

                </div>
				<div class="row">
                <div class="col-12">
                        <h5 class="text-center">Exclusions</h5>
                        <div id="exlusions" class="well well-body" style="padding-left:5px;">
						<div class="row" *ngFor="let item of exclusions">
						<div class="col-3">
                                        {{item.stockid}}</div>
						<div class="col-9 text-center">{{item.description}}</div>
						</div>

						</div>
                </div>
				</div>
				<div class="row">
                <div class="col-12">
                        <h5 class="text-center">Search Results</h5>
                        <hr>
                        <div class="table well table-sm">
                                <span class="text-success" *ngIf="resultitems.length == 0"><small>Enter Search Criteria</small></span>
								<table *ngIf="resultitems.length != 0" class="table compact table-sm table-bordered table-striped collapsed" id="affected">
                                <tr><th class="text-center">Stockid</th><th class="text-center">Description</th><th class="text-center"></th></tr>
                                <tr *ngFor="let item of resultitems" id="exlude-{{item.stockid}}">
										<td class="">
                                        {{item.stockid}}</td>
										<td class="text-center">{{item.description}}</td>
										<td class="text-center">
										<button mat-stroked-button class="btn btn-danger"  (click)="exclude(item)">Exclude</button></td>
								</tr>
                                </table>
								<div class="row"><div class="col-xs-10 text-center" *ngIf="resultitems.length != 0">
								<br>
								<button mat-stroked-button class="btn btn-success" (click)="saveProducts()"><mat-icon>add</mat-icon> Add to Category </button>
								</div>
								</div>


                        </div>

                </div>
				</div>
				<div class="row">
                <div class="col-12" id="container">
                        <h4 class="text-center">Current Products</h4>
                </div>
				</div>
				<div class="row">
                <div class="table scrollable" id="scrollable" >

                                <div class="cf nestable-lists" *ngIf="items.length != 0">
                                        <div class="dd" id="productlist">
                                                <ol class="dd-list">
                                                <li *ngFor="let item of items | keyvalue" class="dd-item" data-id="{{item.value.sorting}}" data-stockid="{{item.value.product_id}}" id="item-{{item.value.product_id}}">

                                                        <div class="dd-handle">
                                                                <div class="row" style="width:90%;border-bottom: 1px solid black;">
																				<div class="col-3">

																				<button mat-stroked-button class="btn btn-danger" (click)="removeItem(item.key,item.value.stockid);"><mat-icon>delete</mat-icon>
																				</button>
																				</div>
                                                                                <div class="col-3 text-left text-nowrap">
																						<div *ngIf="item.discontinued == 1" class="badge badge-pill badge-danger badge-sm" style="font-size:5px;">
                                                                                                <small>&nbsp;</small>
                                                                                        </div>
                                                                                        <b>{{item.value.stockid}}</b><br>
                                                                                        <small>{{item.value.description}}</small>
                                                                                </div>
                                                                                <div class="col-5 text-right p-0 m-0">
                                                                                        <small>{{item.value.price}} ({{item.value.qoh}})</small>
                                                                                </div>
                                                                </div>
                                                        </div>
                                                </li>
                                                </ol>
                                        </div>
                                </div>
                                        <div class="content bg-danger" *ngIf="!items.length == 0">
                                                No Results
                                        </div>
                        </div>
						</div>
                </div>
	<div class="modal-footer">
			<button mat-stroked-button class="btn btn-danger" (click)="modal.dismiss('Cross click')">
			<mat-icon>save</mat-icon>
			Save
			</button>
	</div>

</ng-template>

<ng-template #subCatModal let-modal>
<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add Sub-Category to {{ editcat.name }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i
				class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
					<div class="col-sm-12">
					<mat-form-field appearance="outline">
					<mat-label>Category Name</mat-label>
					<input matInput type="text" [formControl]="catname" />
					</mat-form-field>
					</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
			<button mat-stroked-button (click)="addCat();">
			<mat-icon>add</mat-icon>
			Add Category
			</button>
	</div>
</ng-template>

<ngx-nestable [(list)]="list" [options]="options" [template]="itemTemplate" (listChange)="changeArray($event)">
  </ngx-nestable>

  <ng-template #itemTemplate let-row>

    <button mat-icon-button [ngxNestableDragHandle]="row">
      <mat-icon>drag_handle</mat-icon>
    </button>

    <button mat-icon-button *ngIf="row.item.children && row.item.children.length; else empty_item" [ngxNestableExpandCollapse]="row">
      <mat-icon>{{row.item.$$expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
      </mat-icon>
    </button>
	<div class="col-sm-4">
    <div>{{row.item.name}}</div>
	</div>
	<mat-slide-toggle [checked]="row.item.isallowed == 1" (change)="changeAllowed(row.item.id,$event);">Show</mat-slide-toggle>
	&nbsp;
	<button mat-stroked-button (click)="assign(row.item)">
      <mat-icon>edit
      </mat-icon> Products
    </button>
	<div class="col-sm-2">

	<ngx-dropzone [multiple]="false" (change)="onSelect(row.item.id, $event)" style="max-width:50px;max-height:50px;">
	    <img src="{{row.item.image}}" style="width:100%;height:100%">
	</ngx-dropzone>
	</div>
	<div class="col-sm-3 pull-right">

	<button mat-stroked-button class="pull-right" (click)="removeCat(row.item)">
      <mat-icon>delete
      </mat-icon>
    </button>
	<button mat-stroked-button (click)="addSubCat(row.item);" class="pull-right">
      <mat-icon>add
      </mat-icon> Sub Category
    </button>
	</div>
  </ng-template>

  <ng-template #empty_item>
    <span style="width: 40px"></span>
  </ng-template>