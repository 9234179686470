<ng-template #preorderdetails let-modal>
	<div class="modal-header">
		<h4>Pre-Order Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<mat-card class="demo-inline-calendar-card">
			<mat-calendar [(selected)]="preorderDate.value" (selectedChange)="validateDate($event)"></mat-calendar>
		</mat-card>
		<div class="p-2">
			<h4>
				<span class="font-weight-lighter">Pre-Order Date: &nbsp;&nbsp;</span>
				<span *ngIf="preorderDate.status" class="font-weight-bold">{{preorderDate.value | date: 'M/d/yy'}}</span>
				<span *ngIf="!preorderDate.status" class="font-weight-bold bg-warning">{{preorderDate.value}}</span>
			</h4>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-raised-button (click)="placePreOrder(false)">Cancel</button>
		<button mat-raised-button [disabled]="!preorderDate.status" (click)="placePreOrder(true)">Save Pre-Order</button>
	</div>
</ng-template>
<ng-container *ngIf="invoicing">
	<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox" @navIn>
		<button class="nav-item ml-2 mr-2  " mat-raised-button (click)="resetOrder()" color="warn">Reset</button>
	</nav>
	<app-order-invoice [orderno]="invoicing" (forceReset)="resetOrder($event)" (reloadCustomer)="emitCustomerUpdate($event);" (invoiced)="showInvoiceResultandReset($event)" *ngIf="invoicing"> </app-order-invoice>
</ng-container>
<ng-container *ngIf="!invoicing">
	<h4 class="text-center mt-3" *ngIf="order_details && !orderResults">Editing Order #{{order_details.header.orderno}}</h4>

	<div class="row m-0 p-0 mt-3 d-flex align-items-center" *ngIf="!orderResults && cart_items.length" @navIn>
		<div class="col mb-1 ml-0 text-left">
			<button mat-raised-button (click)="resetOrder()" *ngIf="type == '10' && cart_items.length" color="warn">Reset</button>
		</div>

		<div class="col mb-1 text-left" *ngIf="flip === 'active' && !editing_order && config.env.package !== 'beauty'">
			<button mat-raised-button color="accent" (click)="saveQuote()" *ngIf="type === '10' && cart_items.length && !editing_order && flip === 'active'">
				<mat-icon>save</mat-icon>
				<span>Save Quote</span>
			</button>
		</div>
		<div class="col mb-1 text-left" *ngIf="flip === 'active'">
			<button mat-raised-button (click)="cancelOrder()" *ngIf="cart_items.length && editing_order && flip === 'active'">Cancel Order</button>
		</div>
		<div class="col mb-1 text-left">
			<button mat-raised-button class="pl-0" [matMenuTriggerFor]="menu" *ngIf="flip === 'active' && !editing_order && config.env.package === 'beauty'" color="primary">
				<mat-icon>more_vert</mat-icon> Save Options
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item (click)="saveQuote()" *ngIf="type === '10' && cart_items.length && !editing_order && flip === 'active'">
					<mat-icon>save</mat-icon>
					<span>Save Quote</span>
				</button>
				<button mat-menu-item (click)="openPreOrder()" *ngIf="type === '10' && cart_items.length && !editing_order && flip === 'active'">
					<mat-icon>save_alt</mat-icon>
					<span>Save Pre-Order</span>
				</button>
			</mat-menu>
		</div>

		<div class="col mb-1 text-right">
			<button mat-raised-button (click)="toggleFlip()" color="accent">
				<span *ngIf="flip === 'active'">Add items</span>
				<span *ngIf="flip === 'inactive' && cart_items.length">Review Order</span>
			</button>
		</div>

		<div class="col mb-1 text-right" *ngIf="flip === 'active'">
			<ng-container *ngIf="!sending && cart_items.length">
				<button mat-raised-button (click)="placeOrder()" color="accent">
					<span *ngIf="!editing_order">Create</span>
					<span *ngIf="editing_order">Update</span>
					Order
				</button>
			</ng-container>
			<span *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
		</div>
	</div>


	<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox" *ngIf="!orderResults && cart_items.length" @navIn>
	</nav>
	<ng-container *ngIf="orderResults">
		<h4 class="mt-3 bold text-center">Order#: {{ orderResults.orderno }}
			<span [innerHTML]="editing_order ? 'Updated' : 'Created'"></span>
		</h4>
		<nav class="navbar navbar-expand navbar-light no-shadow bg-light nobox">
			<button mat-raised-button color="accent" class="nav-item ml-auto mr-auto" (click)="editOrder(orderResults.orderno)" *ngIf="!user.issalesman">Edit Order</button>
			<a class="nav-item ml-auto mr-auto" href="{{ baselink }}{{ orderResults.orderno }}" mat-raised-button color="primary">Re-print Pick</a>
			<a class="nav-item ml-auto mr-auto" href="{{ pickingInvoiceLink }}{{ orderResults.orderno }}" mat-raised-button>Customer Copy</a>
			<button mat-raised-button (click)="resetOrder()" class="nav-item  ml-auto mr-auto">New Order</button>
			<a [routerLink]="'/orders/pick/'+orderResults.orderno" mat-raised-button color="primary" class="nav-item  ml-auto mr-auto" *ngIf="!user.issalesman">Pick Order</a>
			<button mat-raised-button color="accent" class="nav-item ml-auto mr-auto" (click)="invoiceOrder(orderResults.orderno)" *ngIf="!user.issalesman">Invoice Order</button>
		</nav>

		<app-order-entry-po [orderno]="orderResults.orderno" *ngIf="config.pofromorder"></app-order-entry-po>
	</ng-container>
	<ng-container *ngIf="!orderResults">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="tp-box__side tp-box__back w-100 m-0 p-0" [ngClass]="flip == 'inactive' ? 'd-none' : ''">
				<app-order-review [customer]="customer" [cart_items]="cart_items" [cart_totals]="cart_totals" (updateCart)="updateCartItem($event)" (refreshCart)="updateCart()" (removeItem)="removeFromOrder($event)" (updateCustomer)="updateCustomer($event)" (freight_charge)="updateShipping($event)"></app-order-review>
			</div>
			<div id="frontside" class="row mt-3 tp-box__side tp-box__front m-0 p-0" [ngClass]="flip == 'active' ? 'd-none' : ''">
				<app-item-search [customer]="customer" (addEdu)="addEdu($event)" (addMix)="addPromo($event)" (addItem)="addItems($event)" (updateCart)="updateCart()" class="w-100"></app-item-search>
			</div>
			<div class="row m-0 p-0 " *ngIf="!cart_items.length">
				<div class="col-md-4 ml-auto mr-0">
					<p @addFilters class="alert alert-info text-center"><b>No Items, Add items above and review order.</b></p>
				</div>
			</div>
		</div>
		<!---- CART NEEDS ABSTRACTED-->
		<div class="fixed-plugin">
			<div class="dropdown show-dropdown text-center cart-fixed" *ngIf="cart_items">
				<a href="#" data-toggle="dropdown" aria-expanded="false">
					<!-- <mat-icon>shopping_cart</mat-icon> 2x?-->
					<i class="ml-auto mr-auto fa fa-shopping-cart fa-1x text-white" [ngClass]="!proceed ? 'text-danger' : ''">
						({{ cart_items.length }})
					</i>
					<span class="text-white" *ngIf="total_cart_qty"><br><i class="fa fa-caret-left fa-1x text-white" aria-hidden="true"></i> ({{total_cart_qty}})</span>
					<span class="text-white"><br> {{ getTotal() | currency}}</span>
					<!-- <button class="nav-item ml-auto mr-auto" mat-raised-button (click)="placeOrder()" color="accent" *ngIf="flip =='active'">
						<span *ngIf="!editing_order">Create</span>
						<span *ngIf="editing_order">Update</span>
					 </button> -->
				</a>
				<ul class="dropdown-menu cart-fixed-content" x-placement="bottom-start" id="rightcart" *ngIf="cart_items">
					<li class="header-title" [ngClass]="{'hasitems': cart_items.length }">Current Order
						<ng-container *ngIf="cart_items.length">
							<nav class="navbar navbar-expand navbar-light bg-light no-shadow nobox">
								<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="saveQuote()" *ngIf="type == '10'" color="secondary">Save Quote</button>
								<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="flip ='active'" color="primary" *ngIf="flip == 'inactive'">
									<span>Review Order</span>
								</button>
								<button class="nav-item ml-auto mr-auto " mat-raised-button (click)="flip ='inactive'" color="primary" *ngIf="flip == 'active'">
									<span>Add Items</span>
								</button>
								<!-- <button class="nav-item ml-auto mr-auto" mat-raised-button (click)="placeOrder()" color="accent" *ngIf="flip =='active'">
								<span *ngIf="!editing_order">Create</span>
								<span *ngIf="editing_order">Update</span>
							 	Order</button> -->
							</nav>
						</ng-container>
					</li>
					<ng-container>
						<div class="container text-right" id="rightcart-content" *ngIf="!cart_items.length">
							<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
								<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
									<h4>No Current Items</h4>
								</div>
							</div>
						</div>
						<div class="container text-right" id="rightcart-content">

							<ng-container *ngIf="cart_items.length" (click)="stopProp($event)">

								<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font" *ngFor="let item of cart_items" (click)="stopProp($event)" [ngClass]="{'parent': ((item.option != '') && (item.option != 'Component')) ? 'true' : '', 'component': ((item.option != '') && (item.option == 'Component')) ? 'true' : ''}">
									<div class="col-md-12 mt-0 pt-0 text-left">
										<b>{{item.stockid}}</b>&nbsp;&nbsp;<span class="bold">{{item.description}}<span *ngIf="item.option && item.option != 'Component'"><span class="parent-tag"> KIT </span></span></span>
									</div>
									<div class="text-center col-md-3">
										<div *ngIf="item.option && item.option == 'Component'">
											<div class="flex-row">
												<mat-icon class="h-19">segment</mat-icon><span class="component-tag">component</span>
											</div>
										</div>
										<div [ngClass]="{ 'bg-warning': item.porequired == '1', 'bg-success': item.porequired == '0' }" *ngIf="!item.parent_id && config.pofromorder">
											<p class="text-light mt-3" *ngIf="item.porequired == '0'"> Available</p>
											<p class="text-dark mt-3" *ngIf="item.porequired == '1'"> PO Required</p>
										</div>
									</div>

									<div class="col-md-2 text-right" *ngIf="config.discounts && item.mbflag != 'F' && editsallowed.discounts">
										<mat-form-field appearance="outline" class="text-right">
											<mat-label>Discount</mat-label>
											<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (input)="updateDiscount($event,item)" appNumericField>
											<span matSuffix>%</span>
										</mat-form-field>
									</div>
									<div class="col-md-4 m-0 p-0 ">
										<mat-form-field appearance="outline" class="m-0 p-0 text-right">
											<mat-label>Price</mat-label>
											<span matPrefix>$</span>
											<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)" appNumericField [disabled]="!editsallowed.discounts">
											<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
												Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-0 mb-0 pt-0 pb-0 text-right">
										<mat-form-field appearance="outline" class="m-0 p-0 text-center">
											<mat-label>Qty</mat-label>
											<span matPrefix>#</span>
											<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)" appNumericField>
										</mat-form-field>
									</div>
									<div class="col-1 m-0 p-0 pt-2 text-right">
										<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
											<mat-icon>remove</mat-icon>
										</button>
									</div>
								</div>
								<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
									<span *ngIf="(total.text !== '0.00' && total.title == 'Discount Total') || !['Discount Total'].includes(total.title)">
										<b>{{total.title }}</b>: {{ total.text | currency }}
									</span>

								</div>
							</ng-container>
						</div>
					</ng-container>
				</ul>
			</div>
		</div>
	</ng-container>
</ng-container>
