<!-- <div class="main-content">
	<div class="row m-0">
		<div class="container-fluid tp-box">
			connect scanners
			<button mat-raised-button type="button" aria-label="Close" (click)="getBatteryLevel();" class="m-0 p-0">
				Connect Device
			</button>


		<div class="card">
			<div class="card-header card-header-{{color}}-6">
				<div class="row m-0 p-0 ">
					<div class="col-9 text-left">
						<h4 class="card-title ">
							LOADING
						</h4>
					</div>
					<div class="col-3 text-right">



					</div>
				</div>
			</div>
			<div class="card-body" id="columnfocus">

				<mat-form-field appearance="fill">
					<mat-label>SCAN ITEMS</mat-label>
					<input matInput class="text-right" id="scantoadd" [formControl]="scantoadd1" #scantoadd >
				</mat-form-field>

				<div class="w-75 p-0 m-0 rightside" *ngIf="sortcolumns">
					<div class="small" *ngFor="let column of sortcolumns">
						<div class="p-0 m-0 ">
							<div class="row p-0 m-0 table">
								<div class="row p-0 m-0 table">
									<div class="col-12 col-lg-12 pt-0 pb-0 mt-0 mb-0 card-header pl-2 pr-0 mr-0 ml-0 card-header-{{color}}-6 no-radius">
										<div class="row m-0 p-0 ">
											<div class="col-auto smaller-font">


											</div>
											<div class="col-auto ml-auto  smaller-font mt-2">

											</div>
											<div class="col-auto mr-0 smaller-font mt-2">

											</div>
											<div class="col-auto ml-auto mr-0 smaller-font ">
												<ng-container>

												</ng-container>
											</div>
											<div class="col-auto ml-auto mr-0 smaller-font mt-2">

											</div>
										</div>
									</div>
									<div class="col-12 col-lg-12 scrolling-wrapper pl-0 ml-0">
										ONE
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
-->


<div class="main-content">
	<div class="row m-0 w-100 bay-container">
		<ng-container *ngFor="let bay of bays | keyvalue">
			<div class="card col-4 p-3 bay" [ngClass]="bay.value.removing.status ? 'bayRemoveMode' : ''">
				<div class="row p-0 m-0">
					<div class="col-4">
						<h2 (click)="resetLoads(bay.value)">Bay {{bay.value.id}}</h2>
					</div>

					<div class="col-8 text-right" *ngIf="bay.value.total_items">
						<h4 class="font-weight-light ">
							{{bay.value.truck.name}}
							<ng-container *ngIf="bay.value.truck.driver">
								({{bay.value.truck.driver}})
							</ng-container>
						</h4>
						<h2 class="font-weight-bold">{{bay.value.scans / bay.value.total_items | percent}}</h2>
					</div>
				<mat-progress-bar class="mb-5 rounded bigger-bar" mode="determinate" [value]="(bay.value.scans / bay.value.total_items) * 100"></mat-progress-bar>

				</div>
				<div class="w-100">
					<div class="w-100 display-4" *ngIf="bay.value.removing.last && bay.value.removing.status" class="bg-danger text-white">{{bay.value.removing.last}}</div>
					<div class="row">
						<div class="col-10 m-0 p-0">
							<mat-form-field appearance="outline">
								<mat-label>scan tester</mat-label>
								<input matInput placeholder="" [(ngModel)]="bay1input" (keyup)="sendScan(bay.value)">
							</mat-form-field>
						</div>
						<div class="col-1 p-0 m-0"><button class="w-100" (click)="sendScan(bay.value)">scan</button></div>
					</div>
				</div>
				<mat-spinner *ngIf="bay.value.completing" color="accent" diameter="30"> </mat-spinner>
				<ul class="m-0 p-0 list-unstyled" *ngIf="bay.value.dispatch && !bay.value.completing">
					<li *ngFor="let ord of bay.value.dispatch; let top = index">
						<ng-container *ngIf="ord.ordertype != 0">
							<!-- active scan card -->
							<div class="card card-spacing" *ngIf="top == 1;">
								<div class="card-header card-header-{{color}}-6">
									<span class="float-left">{{ord.header.brname}}</span>
									<span class="float-right font-weight-bolder">#{{ord.header.orderno}}</span>
								</div>
								<div class="card-body">
									<ul class="p-0 m-0 list-unstyled">
										<li *ngFor="let i of ord.orderdetails">
											<ul class="entry-list m-0 p-0 larger-font display-4">
												<li>
													<div class="spec-label w-25">ITEM#</div>
													<div class="spec-value w-75 fw-500">{{ i.stkcode }}</div>
												</li>
												<li>
													<div class="spec-label w-25">DESC#</div>
													<div class="spec-value w-75 p-2">{{ i.itemdesc }}</div>
												</li>
												<li>
													<div class="spec-label w-25">ORDER#</div>
													<div class="spec-value w-75">{{ ord.header.orderno }}</div>
												</li>
												<li>
													<div class="spec-label w-25">BIN</div>
													<div class="spec-value w-75">
														<b>
															<span *ngIf="i.bin ==''">NEED</span>
															{{ i.bin }}
														</b>
													</div>
												</li>
												<li>
													<div class="spec-label w-25">QTY</div>
													<div class="spec-value w-75 color-white"
														[ngClass]="i.qtyloaded == 0 || i.qtyloaded == undefined ? 'bg-danger' : i.qtyloaded < i.quantity ? 'bg-warning': i.qtyloaded == i.quantity ? 'bg-success' : 'bg-danger'">
														<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(bay.value, ord, i)">
															<small>
																<i class="material-icons">remove</i>
															</small>
														</button>
														<b>{{i.qtyloaded }} / {{i.quantity}}</b>
														<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(bay.value, ord, i)">
															<small><i class="material-icons">add</i></small>
														</button>

													</div>
												</li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
							<!-- next ups runs off of index of list-->
							<div class="card card-spacing opacity-half" *ngIf="top > 1">
								<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1">
									<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
									<div class="d-inline-block">{{ord.header.brname}}</div>
									<div class="d-inline-block">{{ord.totalqty}}</div>
								</div>
							</div>

						</ng-container>
					</li>
				</ul>
				<!-- fulfilled scan cards -->
				<ng-container *ngIf="bay.value.scans == bay.value.total_items && bay.value.scans != 0 ">
					<div class="bg-success text-white rounded">
						<h4 class="p-2 font-weight-bold display-3 text-center">Run loaded - Scan finalize barcode</h4>
					</div>
				</ng-container>
				<ul class="m-0 p-0 list-unstyled" *ngIf="bay.value.dispatch && bay.value.fulfilledOrds">
					<ng-container *ngFor="let ord of bay.value.fulfilledOrds">
						<div class="card card-spacing opacity-half border-green" [ngClass]="ordScanAmount(ord) == ord.totalqty ? 'border-green' : ordScanAmount(ord) < ord.totalqty ? 'border-warn' : 'border-dnger'">
								<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1" >
									<div class="col-1 d-inline-block"><mat-icon class="text-success">local_shipping</mat-icon></div>
									<div class="col-2 d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
									<div class="col-4 d-inline-block">{{ord.header.brname}}</div>
									<div class="col-2 d-inline-block text-right">{{ordScanAmount(ord)}} / {{ord.totalqty}} </div>
									<div class="col-3 d-inline-block text-right font-heavy">{{ord.orderdetails[0].stockid}}</div>
								</div>
							</div>
					</ng-container>
				</ul>
				<div *ngIf="!bay.value.dispatch">
					<div class="m-auto">No Dispatch active</div>
				</div>
			</div>
		</ng-container>

		<!-- <button (click)="discover()">discover</button> -->
	</div>
</div>