import { Component, NgZone, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation, Injector } from '@angular/core';
import { ViewportScroller, Location, DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BluetoothService } from '../../services/bluetooth.service'
import { L, R } from '@angular/cdk/keycodes';
import { data } from 'jquery';


declare const google: any;

interface scanner {
	value: string;
	viewValue: string;
}


@Component({
	selector: 'app-warehouse-checking',
	templateUrl: './warehouse-checking.component.html',
	styleUrls: ['./warehouse-checking.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('300ms ease-out')),
			transition('inactive => active', animate('300ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	],
	providers: [BluetoothService]
})
export class WarehouseCheckingComponent implements OnInit {
	device: any;
	batteryLevel: any = '';
	color: any = 'blue';
	user: any = false;
	config: any = false;
	sortcolumns: any = [];
	scantoadd1 = new UntypedFormControl('');
	bay1input = '';
	// bluetoothService: BluetoothService;
	bays = {
		'1': {
			'id': 1,
			'completionRate': 0,
			'scans': 0,
			'total_items': 0,
			'dispatch': [],
			'fulfilledOrds': [],
			'truck': [],
			'active': false,
			'removing': {
				'status': false,
				'last': false
			},
			'completing': false
		},
		'2': {
			'id': 2,
			'completionRate': 0,
			'scans': 0,
			'total_items': 0,
			'dispatch': [],
			'fulfilledOrds': [],
			'truck': [],
			'active': false,
			'removing': {
				'status': false,
				'last': false
			},
			'completing': false
		},
		'3': {
			'id': 3,
			'completionRate': 0,
			'scans': 0,
			'total_items': 0,
			'dispatch': [],
			'fulfilledOrds': [],
			'truck': [],
			'active': false,
			'removing': {
				'status': false,
				'last': false
			},
			'completing': false
		}
	}

	emptybay = {
			'id': null,
			'completionRate': 0,
			'scans': 0,
			'total_items': 0,
			'dispatch': [],
			'fulfilledOrds': [],
			'truck': [],
			'active': false,
			'removing': {
				'status': false,
				'last': false
			},
			'completing': false
		}

	alltrucks: any;
	selectedtruck: any;
	activetrucks: any;
	truckisactive: boolean;
	dispatches: any;
	allpoints: any;
	total_order_volume: any;
	total_items: any;
	loadingdata: any;
	loadingrun: any;
	active_dispatches: any;
	active_allpoints: any;

	constructor(private bluetoothService: BluetoothService, public _zone: NgZone, private printService: PrintService, private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}

	ngOnInit(): void {
		this.loadData();
		this.startSocketListener();
	}


	startSocketListener(){
		const token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToLoadingBays((err, data) => {
				this.loadData();
			});
		}
	}


	loadData() {
		//get trucks
		this.getTruckData();
		//get highest priority disp per bay
		this.dispatchService.getBayDisps().subscribe((trucks: any) => {

			//empty bay resets
			if(trucks['1'].length == 0){
				this.bays['1'] = this.resetBay(1)
			}
			if(trucks['2'].length == 0){
				this.bays['2'] = this.resetBay(2);
			}
			if(trucks['3'].length == 0){
				this.bays['3'] = this.resetBay(3);
			}


			trucks['data'].forEach(disp => {

				this.dispatchService.getActivePicked(disp.truckid).subscribe((results: any) => {
					switch (disp.bay_id) {
						case '1':
							if(this.bays['1'].active != disp.dispnumber){
								this.bays['1'].dispatch = results;
								this.bays['1'].total_items = this.calcExpectedScans(results);
								this.bays['1'].scans = this.calcCompletedScans(results);
								this.bays['1'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
								this.bays['1'].active = disp.dispnumber;
								this.fulfilledOrders(this.bays['1']);
							}
							break;
						case '2':
							if(this.bays['2'].active != disp.dispnumber){
								this.bays['2'].dispatch = results;
								this.bays['2'].total_items = this.calcExpectedScans(results);
								this.bays['2'].scans = this.calcCompletedScans(results);
								this.bays['2'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
								this.bays['2'].active = disp.dispnumber;
								this.fulfilledOrders(this.bays['2']);
							}
							break;
						case '3':
							if(this.bays['3'].active != disp.dispnumber){
								this.bays['3'].dispatch = results;
								this.bays['3'].total_items = this.calcExpectedScans(results);
								this.bays['3'].scans = this.calcCompletedScans(results);
								this.bays['3'].truck = this.alltrucks.filter((truck: any) => { return truck.id == disp.truckid })[0];
								this.bays['3'].active = disp.dispnumber;
								this.fulfilledOrders(this.bays['3']);
							}
							break;
					}
				});
			});
		})
	}

	getTruckData() {
		this.dispatchService.getTrucks().subscribe((result: any) => {
			if (result.length == 1) {
				const truck = result.filter(t => {
					return t.id == result[0].id
				})[0];
			}
			this.alltrucks = result
		});
	}

	resetBay(id){
		var empty = this.emptybay;
		empty.id = id;
		return empty;
	}



	sendScan(bay){
		const data = {
			barcode: this.bay1input,
			dispatch: bay.dispatch,
			filled: bay.fulfilledOrds
		}

		this.dispatchService.verifyLoadScan(data).subscribe((res)=>{
			if(res.success){

				if(bay.removing.status){
					if(res.isFilled){
						var da = bay.fulfilledOrds[res.order];
						var item = da.orderdetails[res.item];
					}else{
						var da = bay.dispatch[res.order];
						var item = da.orderdetails[res.item];
					}
					this.removePick(bay, da, item);
				}else{
					var da = bay.dispatch[res.order]
					var item = bay.dispatch[res.order].orderdetails[res.item];
					this.addPick(bay, da, item);
				}

			}else{
				//wasn't a 1 to 1 match, possible missing/mult/function barcode
				if(res.function){
					this.handleControl(res.function, bay);
				}
				if(res.multBarcode){
					console.log("mult barcode");
					//modal to choose?
				}
				if(res.noBarcode){
					//bring in add barcode component
				}

			}
		})
	}

	handleControl(control, bay){
		switch(control){
			case 'previous':
				//prev load?
				break;
			case 'next':
				//next load?
				break;
			case 'history':
				//possibly?
				break;
			case 'remove':
				bay.removing.status = bay.removing.status == false ? true : false;
				break;
			case 'complete':
				bay.completing = true;
				this.completeBay(bay);
				break;
		}
	}

	removePick(bay: any, da: any, item: any) {
		if (item.qtyloaded > 0) {
			item.qtyloaded -= 1

			const request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
			}

			this.dispatchService.loadItemToTruck(request).subscribe(results => {
				if (results.success) {
					this.updateBayProgress(bay);
					bay.removing.last = `-1 x ${item.stockid} for ${da.orderno}`;
				}
			})
		}
	}


	addPick(bay: any, da: any, item: any) {
		if (item.qtyloaded < item.quantity) {
			item.qtyloaded += 1

			const request = {
				dispatch: da,
				item: item,
				qty: item.qtyloaded,
			}

			this.dispatchService.loadItemToTruck(request).subscribe(results => {
				if (results.success) {
					if (item.qtyloaded == item.quantity) {
						const ordIndex = bay.dispatch.indexOf(da);
						const ord = bay.dispatch.splice(ordIndex, 1)[0];
						bay.fulfilledOrds.push(ord);
					}
					this.updateBayProgress(bay);
				}
			})
		}
	}


	//recalculate total scans for bay
	updateBayProgress(bay) {
		bay.scans = this.calcCompletedScans(bay.dispatch) + this.calcCompletedScans(bay.fulfilledOrds);
	}


	//calc expected total amount for dispatch
	calcExpectedScans(dispatch) {
		return dispatch.reduce((acc, row) => {
			return Number(row.totalqty) + acc;
		}, 0);
	}


	//calc completed scans of dispatch
	calcCompletedScans(dispatch) {
		return dispatch.reduce((acc, row) => {
			let scans = 0;
			if (Array.isArray(row.orderdetails) && row.orderdetails.length > 0) {
				row.orderdetails.forEach((i) => {
					scans += Number(i.qtyloaded);
				});
			}
			return scans + acc;
		}, 0);
	}


	//completed scans of indvidual orders
	ordScanAmount(ord) {
		return (ord.orderdetails).reduce((acc, row) => {
			return acc += Number(row.qtyloaded);
		}, 0);
	}


	//called on loading of bay - splits completed scans from bay.dispatch and moves to bay.fulfulilled
	fulfilledOrders(bay) {
		if (bay.active) {

			const dispatch = bay.dispatch;
			var ordsFull = [];

			(dispatch).forEach(da => {
				if (da.ordertype != 0) {
					(da.orderdetails).forEach(item => {
						if (item.quantity == item.qtyloaded) {
							var ordIndex = dispatch.indexOf(da);
							bay.fulfilledOrds.push(dispatch[ordIndex]);
							ordsFull.push(dispatch[ordIndex]);
						}
					});
				}
			});

			ordsFull.forEach((ord)=>{
				let orig_da_index = bay.dispatch.indexOf(ord);
				bay.dispatch.splice(orig_da_index, 1);
				ord['orig_da_index'] = orig_da_index;
			})
		}
	}

	completeBay(bay){
		const bay_const = bay;
		this.dispatchService.completeBay({'disp': bay.active}).subscribe((result)=>{
			if(result.success){
				const data = this.buildDiscrepancy(bay_const);
				this.dispatchService.mailLoadDiscrepancy(data).subscribe((res)=>{
					if(res.success){
						this.bays[bay_const.id] = this.resetBay(bay_const.id)
						this.loadData();
					}
				})
			}
		})
	}



	buildDiscrepancy(bay){
		//maybe need truck route
		let table = "<table>";
		let head = "<thead><tr>";
		head += "<th>&nbsp;Order#&nbsp;</th>"
		head += "<th>&nbsp;Cust#&nbsp;</th>"
		head += "<th>&nbsp;Name&nbsp;</th>"
		head += "<th>&nbsp;Loaded&nbsp;</th>"
		head += "<th>&nbsp;Ordered&nbsp;</th>"
		head += "<th>&nbsp;Stock ID&nbsp;</th>"
		head += "</tr></thead>";

		let body = "<tbody>"
		let body_end = "</tbody>";
		let table_end = "</table>";

		var lines = []
		if(bay.dispatch){
			(bay.dispatch).forEach((disp)=>{
				if(disp.ordertype != 0){
					let row = "<tr>";
					row += "<td>"+disp.header.orderno+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/customers/view/' +  disp.header.debtorno + '">'+disp.header.debtorno+'</td>'
					row += "<td>"+disp.header.brname+"</td>"
					row += "<td style='text-align: center'>"+this.ordScanAmount(disp)+"</td>"
					row += "<td style='text-align: center'>"+disp.totalqty+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/inventory/view/' + disp.orderdetails[0].stockid + '">'+disp.orderdetails[0].stockid+'</a></td>'
					row += "</tr>";
					body+=row;
				}
			})
		}

		if(bay.fulfilledOrds){
			(bay.fulfilledOrds).forEach((disp)=>{
				if(disp.ordertype != 0){
					let row = "<tr>";
					row += "<td>"+disp.header.orderno+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/customers/view/' +  disp.header.debtorno + '">'+disp.header.debtorno+'</td>'
					row += "<td>"+disp.header.brname+"</td>"
					row += "<td style='text-align: center'>"+this.ordScanAmount(disp)+"</td>"
					row += "<td style='text-align: center'>"+disp.totalqty+"</td>"
					row += "<td>"+ '<a href="https://clevehilltire.com/v8/#/inventory/view/' + disp.orderdetails[0].stockid + '">'+disp.orderdetails[0].stockid+'</a></td>'

					row += "</tr>";
					body+=row;
				}
			})
		}

		const loads = table+head+body+body_end+table_end +"<br>";

		table = "<table>";
		head = "<thead><tr>";
		head += "<th>&nbsp;Truck&nbsp;</th>"
		head += "<th>&nbsp;Driver&nbsp;</th>"
		head += "<th>&nbsp;Phone&nbsp;</th>"
		head += "</tr></thead>";

		body = "<tbody><tr>";
		body += "<td class='text-center'>&nbsp;&nbsp;"+bay.truck.name+"&nbsp;&nbsp;</td>";
		body += "<td class='text-center'>&nbsp;&nbsp;"+bay.truck.driver+"&nbsp;&nbsp;</td>";
		body += "<td class='text-center'>&nbsp;&nbsp;"+bay.truck.phone+"</td>&nbsp;&nbsp;</tr>";

		const truck = table+head+body+body_end+table_end + "<br>";

		const html = `${truck}<br><br>${loads}`;

		const res = {
			'html':{
				truck: truck,
				timestamp: null,
				loads: loads
			},
			'truck' : bay.truck
		}
		return res;
	}



	resetLoads(bay) {
		[...bay.dispatch, ...bay.fulfilledOrds].forEach((ord) => {
			if (ord.orderdetails) {
				ord.orderdetails.forEach(item => {
					while (item.qtyloaded > 0) {
						this.removePick(bay, ord, item);
					}
				});
			}
		})
	}





	//------------------------------bluetooth------------------------------//
	discover() {
		this.bluetoothService.discover();
	}

	getDeviceStatus() {
		this.bluetoothService.getDevice().subscribe(
			(device) => {

				if (device) {
					this.device = device;
				}
				else {
					// device not connected or disconnected
					this.device = null;
					this.batteryLevel = '--';
				}
			}
		);
	}

	getBatteryLevel() {
		return this.bluetoothService.value().subscribe(this.showBatteryLevel.bind(this));
	}

	showBatteryLevel(value: number) {
		// force change detection
		this._zone.run(() => {
			this.batteryLevel = '' + value;
		});
	}
}
