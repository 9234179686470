import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './shared/app-config.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AppConfig {
	static settings: IAppConfig;
	private config: any;
	private availableConfigs: string[];
	private currentConfig: string = 'dev';

	constructor(private http: HttpClient) {}

	load() {
		const jsonFile = `assets/config/config.${environment.name}.json`;
		return new Promise < void > ((resolve, reject) => {
			this.http.get(jsonFile).toPromise().then((response: any) => {
				AppConfig.settings = < IAppConfig > response;
				resolve();
			}).catch((response: any) => {
				reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
			});
		});
	}

	loadConfig(configName: string): Observable < any > {

		let jsonFile = `assets/config/config.${environment.name}.json`;

		if(configName !== environment.name) {
			jsonFile = `assets/config/config.${configName}.json`;
		}

		return this.http.get(jsonFile).pipe(
			tap(config => {
				this.config = config;
				this.currentConfig = configName;
				if(config && config.apiServer) {
					//this._configSvc.setConfig(config);
					//lobalSearchService.setConfig(config);
					//this.globalSearchService.reload()
				}
			})
		);
	}
}
