import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class SystemService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';

	constructor(private globalSearchService:GlobalSearchService, private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public saveEmailSettings(data: any) {
		const method = 'globals/settings/email'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getEmailSettings() {
		const method = 'globals/settings/email';
		return this.http.get(this.setEndPoint(method));
	}


	public updatetEmailSettings(data: any) {
		const method = 'system/settings/email'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getWebCategories(){
		const method = 'storefront/categories';
		return this.http.get(this.setEndPoint(method));
	}
	public addWebCategory(data: any){
		const method = 'storefront/catadd'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeWebCategory(data: any){
		const method = 'storefront/catremove'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public updateCategoryList(data: any){
		const method = 'storefront/catupdate'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public uploadCatFile(formdata: any, cat: any) {
		const method = 'storefront/catupload&cat=' + cat;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}
	public removeCategoryItems(data: any){
		const method = 'storefront/removeitem'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public addCategoryItems(data: any){
		const method = 'storefront/saveitems'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getCategoryItems(data: any){
		const method = 'storefront/getcatprod'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getBanners(data: any){
		const method = 'storefront/getbanners'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	
	public getWebCustomers(){
		const method = 'storefront/customers'
		return this.http.get(this.setEndPoint(method));
	}
	public denyCustomer(data: any){
		const method = 'storefront/denycust'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public undenyCustomer(data: any){
		const method = 'storefront/undenycust'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public approveCustomer(data: any){
		const method = 'storefront/approvecust'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public uploadFile(formdata: any, bannerid: any) {
		const method = 'storefront/uploadimage&bannerid=' + bannerid;
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}
	
	public getInfo(){
		const method = 'storefront/information'
		return this.http.get(this.setEndPoint(method));
	}
	
	public getLayouts(){
		const method = 'storefront/layouts'
		return this.http.get(this.setEndPoint(method));
	}
	
	public getStores(){
		const method = 'storefront/stores'
		return this.http.get(this.setEndPoint(method));
	}
	
	public saveInfo(data: any){
		const method = 'storefront/saveinfo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public deleteInfo(data: any){
		const method = 'storefront/deleteinfo';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public saveBanners(data: any){
		const method = 'storefront/savebanners'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public removeBanner(data: any){
		const method = 'storefront/removebanners'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}



	public getCategoryItemSearch(data: any){


		const method = 'storefront/getitems'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getStoreSales(){
		const method = 'system/storesale'
		return this.http.get(this.setEndPoint(method));
	}
	public getStoreSale(data: any){
		const method = 'system/storesale/getsale'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public saveStoreSale(data: any){
		const method = 'system/storesale/editsale'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public deleteStoreSale(data: any){
		const method = 'system/storesale/deletesale'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public pcStoreSale(data: any){
		const method = 'system/storesale/pricecheck'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getStoreSaleRule(data: any){
		const method = 'system/storesale/getrule'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public saveStoreSaleRule(data: any){
		const method = 'system/storesale/addrule'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public deleteStoreSaleRule(data: any){
		const method = 'system/storesale/deleterule'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId;
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}
}
