import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class DashboardService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getOptions = {
		headers: new HttpHeaders({

		})
	};

	public login(input) {
		localStorage.setItem('user', JSON.stringify(input));
		return true;
	}

	public logout() {
		localStorage.removeItem('user');
		return true;
	}

	public getDashBoard() {
		const method = 'dashboard' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getNegative() {
		const method = 'dashboard/negativeinv' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getNeedLocation() {
		const method = 'dashboard/needloc' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getCatAndLines() {
		const method = 'dashboard/getcatandlines' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getThisYearOverLast() {
		const method = 'dashboard/thisoverlast' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getThisMonthOverLast(data) {
		const method = 'dashboard/daybyday';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getThisDayOverLast(data) {
		const method = 'dashboard/daysales';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getOpenCOunt() {
		const method = 'dashboard/openorders' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getOrderCountByDay() {
		const method = 'dashboard/getordercountbyday' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getReceievedTotalByDay() {
		const method = 'dashboard/getposcountbyday' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	public getTaxOrders() {
		const method = 'dashboard/gettaxorders' + this.getKey();
		return this.http.get(this.setEndPoint(method), this.getOptions);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	public getBrandReport(data:any) {
		const method = 'dashboard/brandbylocation';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}

}
