import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { PaymentsService } from '../../services/payments.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-statements',
	templateUrl: './statements.component.html',
	styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {
	color = 'blue';
	statementForm: UntypedFormGroup;
	statementEmailForm: UntypedFormGroup;
	today = new Date();
	loading: any = false;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '0',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: false,
		showToolbar: false,
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{ class: 'arial', name: 'Arial' },
			{ class: 'times-new-roman', name: 'Times New Roman' },
			{ class: 'calibri', name: 'Calibri' },
			{ class: 'comic-sans-ms', name: 'Comic Sans MS' }
		],
		customClasses: [{
			name: 'quote',
			class: 'quote',
		},
		{
			name: 'redText',
			class: 'redText'
		},
		{
			name: 'titleText',
			class: 'titleText',
			tag: 'h1',
		},
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			['bold', 'italic'],
			['fontSize']
		]
	};

	minutes = [
		{ label: '0 min', value: '00:00' },
		{ label: '1 min', value: '01:00' },
		{ label: '2 min', value: '02:00' },
		{ label: '3 min', value: '03:00' },
		{ label: '4 min', value: '04:00' },
		{ label: '5 min', value: '05:00' },
		{ label: '6 min', value: '06:00' },
		{ label: '7 min', value: '07:00' },
		{ label: '8 min', value: '08:00' },
		{ label: '9 min', value: '09:00' },
		{ label: '10 min', value: '10:00' },
		{ label: '11 min', value: '11:00' },
		{ label: '12 min', value: '12:00' },
		{ label: '13 min', value: '13:00' },
		{ label: '14 min', value: '14:00' },
		{ label: '15 min', value: '15:00' },
		{ label: '16 min', value: '16:00' },
		{ label: '17 min', value: '17:00' },
		{ label: '18 min', value: '18:00' },
		{ label: '19 min', value: '19:00' },
		{ label: '20 min', value: '20:00' },
		{ label: '21 min', value: '21:00' },
		{ label: '22 min', value: '22:00' },
		{ label: '23 min', value: '23:00' },
		{ label: '24 min', value: '24:00' },
		{ label: '25 min', value: '25:00' },
		{ label: '26 min', value: '26:00' },
		{ label: '27 min', value: '27:00' },
		{ label: '28 min', value: '28:00' },
		{ label: '29 min', value: '29:00' },
		{ label: '30 min', value: '30:00' },
		{ label: '31 min', value: '31:00' },
		{ label: '32 min', value: '32:00' },
		{ label: '33 min', value: '33:00' },
		{ label: '34 min', value: '34:00' },
		{ label: '35 min', value: '35:00' },
		{ label: '36 min', value: '36:00' },
		{ label: '37 min', value: '37:00' },
		{ label: '38 min', value: '38:00' },
		{ label: '39 min', value: '39:00' },
		{ label: '40 min', value: '40:00' },
		{ label: '41 min', value: '41:00' },
		{ label: '42 min', value: '42:00' },
		{ label: '43 min', value: '43:00' },
		{ label: '44 min', value: '44:00' },
		{ label: '45 min', value: '45:00' },
		{ label: '46 min', value: '46:00' },
		{ label: '47 min', value: '47:00' },
		{ label: '48 min', value: '48:00' },
		{ label: '49 min', value: '49:00' },
		{ label: '50 min', value: '50:00' },
		{ label: '51 min', value: '51:00' },
		{ label: '52 min', value: '52:00' },
		{ label: '53 min', value: '53:00' },
		{ label: '54 min', value: '54:00' },
		{ label: '55 min', value: '55:00' },
		{ label: '56 min', value: '56:00' },
		{ label: '57 min', value: '57:00' },
		{ label: '58 min', value: '58:00' },
		{ label: '59 min', value: '59:00' }
	];

	hours = [
		{ label: '1 am', value: '01:' },
		{ label: '2 am', value: '02:' },
		{ label: '3 am', value: '03:' },
		{ label: '4 am', value: '04:' },
		{ label: '5 am', value: '05:' },
		{ label: '6 am', value: '06:' },
		{ label: '7 am', value: '07:' },
		{ label: '8 am', value: '08:' },
		{ label: '9 am', value: '09:' },
		{ label: '10 am', value: '10:' },
		{ label: '11 am', value: '11:' },
		{ label: '12 pm', value: '12:' },
		{ label: '1 pm', value: '13:' },
		{ label: '2 pm', value: '14:' },
		{ label: '3 pm', value: '15:' },
		{ label: '4 pm', value: '16:' },
		{ label: '5 pm', value: '17:' },
		{ label: '6 pm', value: '18:' },
		{ label: '7 pm', value: '19:' },
		{ label: '8 pm', value: '20:' },
		{ label: '9 pm', value: '21:' },
		{ label: '10 pm', value: '22:' },
		{ label: '11 pm', value: '23:' },
		{ label: '12 am', value: '00:' }
	];


	descriptionHtmlContent: JSON;
	statement_text: any = [];
	defaultTypes = ['credit', 'debit']
	statement_count: any = false;
	jobs: any = [];
	terms: any = false;
	terms_selected: any = [];

	constructor(private ordersService: OrdersService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute, public router: Router) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		const tenthofnext = new Date();
		tenthofnext.setMonth(tenthofnext.getMonth() + 1, 1);
		tenthofnext.setDate(10);

		this.globalSearchService.terms.subscribe(async (tt: any) => {
			this.terms = tt;
			this.terms_selected = tt
			  .filter(f => f.donot_print === '0')
			  .map(f => f.termsindicator);

			this.customerService.getStatementConfig().subscribe(r => {
				this.statement_text = r

				this.customerService.getScheduledEmails({}).subscribe( s => {
					this.jobs = s.jobs;
				});

				this.statementForm = this.fb.group({
					FromCust: ['0', Validators.required],
					ToCust: ['ZZZZZZZZ', Validators.required],
					dateto: [this.today, Validators.required],
					datedue: [tenthofnext, Validators.required],
					blurbs: [this.statement_text],
					printonly: [true, Validators.required],
					type: [this.defaultTypes],
					paymentterms: [this.terms_selected]
				});

				this.statementEmailForm = this.fb.group({
					FromCust: ['0', Validators.required],
					ToCust: ['ZZZZZZZZ', Validators.required],
					dateto: [this.today, Validators.required],
					datedue: [tenthofnext, Validators.required],
					job_schedule_time: ['07:', Validators.required],
					job_schedule_time_min: ['00:00', Validators.required],
					blurbs: [this.statement_text],
					emailonly: [true, Validators.required],
					type: [this.defaultTypes],
					schedule: [true],
					script: ['autosender.php'],
					paymentterms: [this.terms_selected]
				});

				this.customerService.getStatmentCounts(this.statementEmailForm.value).subscribe(re => {
					this.statement_count = re;
				})
			})
		});
	}

	scheduleEmail() {
		this.customerService.scheduleEmail(this.statementEmailForm.value).subscribe(re => {
			if(re.success) {
				this.globalSearchService.showNotification('Job Scheduled', 'success', 'bottom', 'right');

				this.customerService.getScheduledEmails({}).subscribe( s => {
					this.jobs = s.jobs;
				});

			}
		})
	}

	updateValue(event: any, r: any) {
		r.confvalue = event.editor.getContent()
	}

	downloadPdf(base64String, fileName) {
		const source = `data:application/pdf;base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}`
		link.click();
	}

	saveMessages() {
		const data = this.statement_text
		this.customerService.saveStatementConfig(data).subscribe(r => {
			this.statement_text = r;
			this.globalSearchService.showNotification('Saved', 'success', 'bottom', 'left');
		});
	}
	removeJob(jobid) {

		const data = {
			job_id: jobid
		}
		this.customerService.removeJob(data).subscribe(r => {
			this.statement_text = r;
			this.globalSearchService.showNotification('Removed', 'success', 'bottom', 'left');
			this.customerService.getScheduledEmails({}).subscribe( s => {
				this.jobs = s.jobs;
			});
		});

	}
	printStatements() {
		this.loading = true;

		this.customerService.getBatchStatements(this.statementForm.value).subscribe((result: any) => {
			this.downloadPdf(result.pdf, result.filename)
			this.loading = false;
		});
	}

}
