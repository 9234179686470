<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Select Bins</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-3">
				Item Code
			</div>
			<div class="col-md-3">
				Bin
			</div>
			<div class="col-md-2">
				Total Needed
			</div>
			<div class="col-md-2">
				Qty In Bin
			</div>
			<div class="col-md-2">
				Add
			</div>
		</div>
		<div class="row" *ngFor="let bin of needbin">
			<div class="col-md-3">
				{{bin.stockid}}
			</div>
			<div class="col-md-3">
				{{bin.bin}}
			</div>
			<div class="col-md-2">
				{{bin.needquantity}}
			</div>
			<div class="col-md-2">
				{{bin.binmax}}
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ bin.quantity | number }}" autofocus (input)="updateBinQuantity($event, bin )" class="text-right">
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-10">
		</div>
		<div class="col-md-2">
			<button class="btn btn-success" (click)="addBins(needbin)"> Add Items To Transfer </button>
		</div>
	</div>
</ng-template>
<div class="main-content">
	<div class="row mt--75">
		<div class="col-md-9">
			<h3>Create Transfer </h3>
		</div>
		<div class="col-md-3">
			<button mat-raised-button color="accent" (click)="flip()" class="resetbtn">
				Reset
			</button>
		</div>
	</div>
	<div class="container-fluid">
		<form [formGroup]="transferForm">
			<div class="row ">
				<div class="col-md-6 card-group">
					<div class="card m-0">
						<div class="card-header">
							From Location: <mat-form-field appearance="outline">
								<mat-label>From Location</mat-label>
								<mat-select formControlName="fromstkloc" (selectionChange)="updateHeader()">
									<mat-option *ngFor="let loc of tranlocations" [value]="loc.loccode">
										{{loc.locationname }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="card-body" style="white-space: pre-line">
							{{ fromaddress }}
						</div>
					</div>
				</div>
				<div class="col-md-6 card-group">
					<div class="card m-0">
						<div class="card-header">
							To Location: <mat-form-field appearance="outline">
								<mat-label>To Location</mat-label>
								<mat-select formControlName="tostkloc" (selectionChange)="updateHeader()">
									<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
										{{loc.locationname }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<ng-container *ngIf="transferData.header.fromstkloc === transferData.header.tostkloc"><br><span>Duplicate From and To locations</span><mat-icon style="color: red;">warning</mat-icon></ng-container>
						</div>
						<div class="card-body" style="white-space: pre-line" *ngIf="transferData.header.tostkloc != ''">
							{{ toaddress }}
						</div>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="tabreset == 1">
				<div class="col-md-12 pr-3 ">
					<mat-form-field class="ml-1 w-100 full-width" appearance="fill">
						<mat-label>Transfer Note</mat-label>
						<textarea matInput formControlName="notes" placeholder="Transfer notes"></textarea>
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<mat-tab-group class="w-100 " [(selectedIndex)]="tabreset">
				<mat-tab label="Item Lookup" preserveContent="true">

					<h4 *ngIf="transferData.header.tostkloc == ''">Please select a to location.</h4>

					<div class="row w-100 p-0">
							<div class="col-md-3 m-0 p-0">
								<mat-paginator id="paginator" [pageSizeOptions]="items_per_page" [pageSize]="50" [ngClass]="transferData.header.tostkloc != '' ? '' : 'hide'"></mat-paginator>
							</div>
							<div class="col-md-1 m-0 p-0">
								<ng-container *ngIf="searching"> <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"> </circle>
									</svg> </ng-container>
							</div>
							<div class="col-md-8 m-0 p-0">
								<mat-form-field appearance="outline" class="searchbar" *ngIf="transferData.header.tostkloc != ''">
									<input matInput #itemrec formControlName="searchVal" id="itemrec" class="margin-top" autocomplete="off" placeholder="* Item Search  " (keyup.enter)="$event.stopPropagation();$event.preventDefault();itemSearch();$event.target.blur();">
									<div matSuffix class="text-right">
										<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();itemSearch()">
											<mat-icon>search</mat-icon>
										</button>
									</div>
								</mat-form-field>
							</div>
						<table  *ngIf="items.length > 0" class="m-auto w-98" #print_items>
							<tr>
								<th>Item #</th>
								<th>Description</th>
								<th>Price</th>
								<th>QOH at {{transferData.header.fromstkloc}}</th>
								<th>QOH at {{transferData.header.tostkloc}}</th>
							</tr>
							<tr *ngFor="let part of dataObs | async" style="border-bottom: 1px solid gainsboro;" [ngClass]="part.quantity > 0 ? 'item_to_add' : ''">
								<td>
									{{part.stockid}}
								</td>
								<td>
									{{part.description}}
								</td>
								<td>{{part.price | currency}}</td>
								<td>
									{{part.fromqoh}}
								</td>
								<td>
									{{part.toqoh}}
								</td>
								<td>
									<div class="row">
										<div class="col-2 mt-3 ml-0 mr-0 ml-auto">
											<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(part)">
												<small><i class="material-icons">remove</i></small>
											</button>
										</div>
										<div class="col-6 m-0 ">
											<mat-form-field appearance="outline">
												<mat-label>Qty</mat-label>
												<span matPrefix>#</span>
												<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ part.quantity | number }}" autofocus (input)="updateSearchQuantity($event, part )" class="text-right">
											</mat-form-field>
										</div>
										<div class="col-2 mt-3 ml-0 mr-0 mr-auto ">
											<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(part)">
												<small>
													<i class="material-icons">add</i></small>
											</button>
										</div>
										<div class="col-2 mt-3 ml-0 mr-0 mr-auto">
											<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2" (click)="addItems(items)">
												<small><i class="material-icons">add_box</i></small>

											</button>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</mat-tab>
				<mat-tab label="Transfer Review ({{transfer_qty }} &nbsp;items @ {{transfer_total | currency}})">
					<div class="row m-0 p-0">
						<ng-container *ngIf="transferData.lines.length > 0">
							<table class="m-auto w-90">
								<thead>
									<tr>
										<th>Item Number</th>
										<th>Description</th>
										<th>Bin Location</th>
										<th>Qty to Transfer</th>
										<th>Price</th>
										<th>Ext.</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let line of transferData.lines" class="reviewRow">
										<td>{{line.stockid}}</td>
										<td>
											<ng-container *ngIf="line.narrative != ''">{{line.narrative}}</ng-container>
											<ng-container *ngIf="line.narrative == ''">{{line.description}}</ng-container>
										</td>
										<td>{{line.bincode}}
										<td>
											<mat-form-field appearance="outline" class="w-50">
												<mat-label>Qty</mat-label><input matInput [disabled]="!(line.releasedqty == 0)" value="{{line.transferqty}}" (input)="updateLineQty($event, line )">
											</mat-form-field>
										</td>
										<td>{{line.price | currency}}</td>
										<td>{{line.price * line.transferqty | currency}}</td>
										<td><button style="display: none;">dummy button</button><button tabindex="-1" class="btn-danger btn-just-icon btn-sm btn btn-fab btn-round" (click)="$event.stopPropagation();$event.preventDefault();removeItem(line)" (keyup.enter)="$event.stopPropagation();$event.preventDefault();"><small><i class="material-icons">remove_box</i></small></button></td>
									</tr>
								</tbody>
							</table>
							<hr class="w-100 ">
							<div class="row m-auto w-90 justify-content-end ">
								<h4 class="line-h45">Transfer Total: {{transfer_total | currency}} &nbsp;&nbsp;</h4>
								<button class="btn btn-success" (click)="submitTransfer()" [disabled]="transferData.header.fromstkloc === transferData.header.tostkloc">
									Submit Transfer
								</button>
							</div>
						</ng-container>
						<ng-container *ngIf="transferData.lines.length == 0" >
							<h3  class="m-auto w-90" style="overflow: hidden; text-align: center;">No items on transfer.</h3>
							{{tabreset}}
						</ng-container>

					</div>
				</mat-tab>
				</mat-tab-group>
			</div>
		</form>
	</div>
</div>
