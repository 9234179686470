import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BluetoothCore } from '@manekinekko/angular-web-bluetooth';
import { map , mergeMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BluetoothService {
	static GATT_CHARACTERISTIC_BATTERY_LEVEL = 'human_interface_device';
	static GATT_PRIMARY_SERVICE = 'human_interface_device';


	constructor(public readonly ble: BluetoothCore) {
	}

	discover() {
		return this.ble.discover$({
			  acceptAllDevices: true,
			  optionalServices: [BluetoothService.GATT_PRIMARY_SERVICE]
		});
	}

	getDevice() {
		// call this method to get the connected device
		const device = this.ble.getDevice$();
		return device;
	}

	stream() {
		// call this method to get a stream of values emitted by the device for a given characteristic
		return this.ble.streamValues$().pipe(

			map((value: DataView) => {
				value.getInt8(0)
			})
		);
	}

	disconnectDevice() {
		// call this method to disconnect from the device. This method will also stop clear all subscribed notifications
		this.ble.disconnectDevice();
	}

	value() {

		return this.ble

			// 1) call the discover method will trigger the discovery process (by the browser)
			.discover$({
			  acceptAllDevices: true,
			  optionalServices: [BluetoothService.GATT_PRIMARY_SERVICE,0x1812,'battery_service','00001812-0000-1000-8000-00805f9b34fb']
			})
			.pipe(

			  // 2) get that service
			  mergeMap((gatt: BluetoothRemoteGATTServer) => {
					return this.ble.getPrimaryService$(gatt, 0x1812);
			  }),

			  // 3) get a specific characteristic on that service
			  mergeMap((primaryService: BluetoothRemoteGATTService) => {
					return this.ble.getCharacteristic$(primaryService, BluetoothService.GATT_CHARACTERISTIC_BATTERY_LEVEL);
			  }),

			  // 4) ask for the value of that characteristic (will return a DataView)
			  mergeMap((characteristic: BluetoothRemoteGATTCharacteristic) => {
					return this.ble.readValue$(characteristic);
			  }),

			  // 5) on that DataView, get the right value
			  map((value: DataView) => value.getUint8(0))
			)
	  }
}
