import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform,ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as FileSaver from 'file-saver';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { CreditService } from '../../services/credit.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { OmsService } from '../../services/oms.service';


@Component({
	selector: 'app-credit-load',
	templateUrl: './credit-load.component.html',
	styleUrls: ['./credit-load.component.scss'],
	encapsulation: ViewEncapsulation.Emulated
})
export class CreditLoadComponent implements OnInit {
	@ViewChild('itemdetails') itemDetails: ElementRef;
	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('creditover') creditover: ElementRef;
	@ViewChild('configproductele') configproductele: ElementRef;
	@ViewChild('itemHistoryRef') itemHistoryRef: ElementRef;
	@ViewChild('nonstock') nonstockref: ElementRef;

	CHAT_ROOM = 'OrderBoard';
	orderResults: any = [];
	customer_data: any = false;
	debtorno: any = '';
	branchcode: any = '';
	today = new Date();
	//cart
	dspcost = true;
	showGuide = false;
	total_cart_qty = 0;
	cart_items: any = [];
	cart_totals: any = [];
	selectedindex = 0;
	activeLink: any = 'order_entry';

	//subscription to search
	inventorysearching: any = false;

	search_items: any = [];
	filtered_items: any = [];
	searching = false;

	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];
	itemhistory: any = [];



	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	addingitems = false;
	customerinfo = true;
	//viewing item
	item_details: any = false;
	editing_order = false;
	credit_invoice = false;
	order_details: any = [];
	purchase_items: any = [];
	user: any = [];

	showfilters = false;
	overcredit = false;
	allowed_credit = true;
	proceed = true;
	overcredit_override = '';
	selecteditem: any = false;
	config: any = [];


	color = 'blue'


	constructor(private generalLedgerService: GeneralLedgerService, private ordersService: OrdersService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private creditService: CreditService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {

		this.config = this.globalsService.getApiConfig();

		this.color = this.globalSearchService.getColor();


		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});

	}

	ngOnInit(): void {
		this.globalSearchService.mesagesallowed.next(false);

		this.route.params.subscribe(params => {
			if (params['debtor'] && params['debtor'] != '') {
				this.debtorno = params['debtor'];
				this.branchcode = params['branch'];
			}
			//crediting an invoice
			if (params['id'] && params['id'] != '') {
				this.creditService.getTransactionById(params['id']).subscribe((order: any) => {

					this.debtorno = order.debtorno;
					this.branchcode = order.branchcode;
					this.credit_invoice = params['id']
					this.loadCustomer();
					this.editing_order = false;
					this.creditService.loadCreditFromSalesOrder(order.order_).subscribe((results: any) => {
						if (results.header.options) {
							this.customer_data.options = results.header.options;
						}
						this.order_details = results;
					});
				});
			} else {
				if(this.customer_data ) {
					this.loadCustomer();
				}
			}
			//load customer. cart. and totals.
		});
	}


	loadCustomer() {
		this.creditService.getCustomer(this.debtorno, this.branchcode).subscribe((results: any) => {
			this.customer_data = results;
		});
	}

	updateFilteredItems(event: any) {
		this.searching = true;
		this.creditService.getItemFilter(event).subscribe((items: any) => {
			//this.search_items = items
			this.search_items = items
			this.filtered_items = items
			this.searching = false;
		});
	}

	viewOptions(item: any) {
		this.selecteditem = item;
		if (!this.selecteditem.product_type) {
			this.selecteditem.product_type = {}
			this.selecteditem.product_type.requiresconfig = false;
		}

		this.modalService.open(this.configproductele, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			this.selecteditem = false;
		});
	}


	toggleFilters() {
		this.showfilters = (this.showfilters) ? false : true;
	}

	reloadOrder(event: any) {

		this.creditService.getTransactionById(event).subscribe((order: any) => {
			this.debtorno = order.debtorno;
			this.branchcode = order.branchcode;
			this.credit_invoice = event
			this.editing_order = false;
			this.creditService.loadSalesOrder(order.order_).subscribe((results: any) => {
				if (results.header.options) {
					this.customer_data.options = results.header.options;
				}
				this.order_details = results;
			});
		});

		this.selectedindex = 0;
	}

	selectCustomer(data: any) {
		this.debtorno = data.debtorno
		this.branchcode = data.branchcode;

		this.loadCustomer();
	}
	toggleCustomerInfo() {
		this.customerinfo = (this.customerinfo) ? false : true;
	}

	toggleAddItems() {
		this.addingitems = (this.addingitems) ? false : true;
		this.customerinfo = (this.addingitems) ? false : true;
	}

	back(): void {
		this.location.back()
	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

	openModal(content, item) {

		this.item_details = item;

		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	invoice() {
		this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
	}

	updateCustomer(event: any){
		this.cdr.detectChanges();
	}

	ngAfterViewInit() {


	}


	// CART FUNCTIONS
	stopProp(event: any): void {
		event.stopPropagation();
	}


	sendCartUpdate() {

		if (this.user) {

			const data = [{ customer: this.debtorno, user: this.user }]

			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	customerChanged(customer: any) {
		this.customerinfo = true;
		this.selectedindex = 0;
		this.router.navigate(['/receivables/credit-note/' + customer.debtorno + '/' + customer.branchcode]);
	}

	toggleGuide() {
		this.showGuide = (this.showGuide) ? false : true;
	}

}
