<ng-template #partSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add More Parts</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngIf="!loading">
			<form [formGroup]="searchForm">
				<div class="col-md-12">
					<mat-form-field appearance="outline" class="searchbar">
						<input matInput #itemrec formControlName="searchVal" id="itemrec" class="margin-top" autocomplete="off" placeholder="* Item Search  " (keyup.enter)="$event.stopPropagation();$event.preventDefault();itemSearch();$event.target.blur();">
						<div matSuffix class="text-right">
							<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();itemSearch()">
								<mat-icon>search</mat-icon>
							</button>
						</div>
					</mat-form-field>
				</div>
			</form>
		</div>
		<div class="row">
			<table *ngIf="items.length > 0">
				<tr>
					<th>Item #</th>
					<th>Description</th>
					<th>QOH at Warehouse</th>
					<th> QOH at Store</th>
				</tr>
				<tr *ngFor="let part of items">
					<td>
						{{part.stockid}}
					</td>
					<td>
						{{part.description}}
					</td>
					<td>
						{{part.fromqoh}}
					</td>
					<td>
						{{part.toqoh}}
					</td>
					<td>
						<div class="row">
							<div class="col-2 mt-3 ml-0 mr-0 ml-auto">
								<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(part)">
									<small><i class="material-icons">remove</i></small>
								</button>
							</div>
							<div class="col-6 m-0 ">
								<mat-form-field appearance="outline">
									<mat-label>Qty</mat-label>
									<span matPrefix>#</span>
									<input matInput tabindex="{{ i + 2 }}" type="number" value="{{ part.quantity | number }}" autofocus (input)="updateSearchQuantity($event, part )" class="text-right">
								</mat-form-field>
							</div>
							<div class="col-2 mt-3 ml-0 mr-0 mr-auto ">
								<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(part)">
									<small>
										<i class="material-icons">add</i></small>
								</button>
							</div>
							<div class="col-2 mt-3 ml-0 mr-0 mr-auto">
								<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2" (click)="addItems(items)">
									<small><i class="material-icons">add_box</i></small>

								</button>
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</ng-template>
<ng-template #nonStock let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add Non-Stocked Part</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<form [formGroup]="nonStockForm">
			<div class="row" *ngIf="!loading">

				<div class="col-md-6">
					<mat-form-field appearance="outline" class="searchbar">
						<input matInput #itemdesc formControlName="itemDesc" id="itemdesc" class="margin-top" autocomplete="off" placeholder="* Description">
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="searchbar">
						<input matInput #itemqty formControlName="itemQty" id="itemqty" class="margin-top" autocomplete="off" placeholder="0" appNumericField>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();nsAdd()">
						<mat-icon>add</mat-icon> Add To Order
					</button>
				</div>

			</div>
		</form>
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Store Reorder Reports</h4>
						<p class="card-category text-right">Reordering</p>
					</div>
					<div class="card-body ">
						<ng-container>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field appearance="fill">
										<mat-label>Location</mat-label>
										<mat-select [formControl]="branch" (selectionChange)="resetReorder();">
											<mat-option *ngFor="let v of branches" [value]="v.loccode">
												{{v.locationname}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>History As Of</mat-label>
										<input matInput (dateChange)="resetReorder();" [formControl]="dateCtrl" [matDatepicker]="payfrom">
										<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
										<mat-datepicker #payfrom></mat-datepicker>
									</mat-form-field>
								</div>
								<div class="col-4" style="text-align:center;vertical-align:center;">
								<br>
									<mat-label>Weekly&nbsp;&nbsp;</mat-label> <mat-slide-toggle [formControl]="reportType"></mat-slide-toggle> <mat-label>&nbsp;&nbsp;3-month Average</mat-label>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="outline" class="full-width">
										<mat-chip-list #chipListRef></mat-chip-list>
										<input matInput #prodInput [formControl]="prodCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipListRef" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)" placeholder="Add a product line... * to add all matching">
										<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
											<mat-option *ngFor="let prod of filteredProds" [value]="prod.line_field">
												{{prod.line_description}}
											</mat-option>
										</mat-autocomplete>
									</mat-form-field>
									<span (click)="clearAll()" class="clear-all-btn" *ngIf="prods.length">
										<mat-icon class="mt-3">clear</mat-icon>
										Clear All ( {{prods.length }} )
									</span>
									<div class="chips-container" *ngIf="prods.length">
										<mat-chip-list #chipList aria-label="Product Line Selection">
											<mat-chip *ngFor="let prod of prods" [selectable]="true" [removable]="true" (removed)="remove(prod)">
												{{prod.line_description}}
												<mat-icon matChipRemove>cancel</mat-icon>
											</mat-chip>

										</mat-chip-list>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<button mat-stroked-button color="warn" class="mt-2 mb-2" (click)="getRecomendations()">Run</button>
									<button *ngIf="results.length" mat-stroked-button color="warn" class="mt-2 mb-2" (click)="addMore()">Add Items Manually</button>
									<button *ngIf="results.length" mat-stroked-button color="warn" class="mt-2 mb-2" (click)="addNonStock()">Add Non-Stock</button>
									<button *ngIf="results.length" mat-stroked-button color="primary" class="mt-2 mb-2" (click)="save()">Save For Later</button>
									<button *ngIf="results.length == 0 && canload" class="mt-2 mb-2" mat-stroked-button color="primary" (click)="load()">Load Saved Reorder</button>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12" #print_table>

									<div class="row mt-3">
										<div class="col-md-4 noprint" *ngIf="!loading && results.length">
											<button mat-stroked-button color="success" (click)="createTransfer()">Create Transfer Request</button>

											&nbsp;&nbsp;
											<button mat-raised-button (click)="$event.preventDefault();exportPdf()">
												<i class="fa fa-file-pdf-o"></i> PDF
											</button>
										</div>
										<div class="col-md-4 noprint" *ngIf="!loading && results.length">

										</div>
										<div class="col-md-4" *ngIf="!loading && results.length">

											<h4 class="ml-auto text-right">Total Quantity Purchased: {{ getTotalQtyPurchase() }}</h4>
											<h4 class="ml-auto text-right">Total  Value: {{ getTotalQtyTimesPrice()
												 | currency }}</h4>

										</div>
									</div>

									<div class="table table-responsive text-center" *ngIf="loading">
										<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</div>
									<div class="table-wrapper"  *ngIf="!loading && results.length">
									<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
									  <table class="table table-sm table-striped">
										<thead class="thead-dark">
										  <tr>
											<th class="text-center ">
											</th>
											<th (click)="sortBy('stockid')">Item#</th>
											<th (click)="sortBy('description')">Description</th>
											<th (click)="sortBy('qoh')" class="text-center">QOH</th>
											<th class="text-center">Price</th>

											<th *ngIf="reportType.value == 0" (click)="sortBy('thismo')" class="text-center">Past 7 Days</th>
											<th *ngIf="reportType.value == 1" class="text-center"> 3Mos </th>
											<th *ngIf="reportType.value == 1" class="text-center"> 2Mos </th>
											<th *ngIf="reportType.value == 1" class="text-center"> 1Mos </th>
											<th *ngIf="reportType.value == 1" (click)="sortBy('thismo')" class="text-center"> MTD </th>
											<th class="text-center">Available</th>
											<th class="text-center">Back/Ord</th>
											<th class="text-center noshow">Recommended Request</th>
											<th (click)="sortBy('qty_purchase')"class="text-center noprint">Recommended Request</th>
											<th class="text-center noprint"></th>
										  </tr>
										</thead>
										<tbody>
										<tr class="datarow" *ngFor="let item of dataObs | async;let i = index">
											<td class="text-center ">
												{{ i + 1 }}.
											</td>
											<td>{{ item.stockid }}</td>
											<td>{{ item.description }}</td>
											<td class="text-center">{{ item.qoh }}</td>
											<td class="text-center">{{ item.price | currency }}</td>
											<td *ngIf="reportType.value == 1" class="text-center">{{ item.threemo }}</td>
											<td *ngIf="reportType.value == 1" class="text-center">{{ item.twomo }}</td>
											<td *ngIf="reportType.value == 1" class="text-center">{{ item.onemo }}</td>
											<td class="text-center">{{ item.thismo }}</td>
											<td class="text-center">{{ item.avail }}</td>
											<td class="text-center">{{ item.qos }}</td>
											<td class="text-center noshow">
												{{ item.qty_purchase }}
											</td>
											<td class="text-center noprint">
											  <div class="quantity-control">
												<button mat-icon-button (click)="decrementQty(item)">
												  <mat-icon>remove_circle</mat-icon>
												</button>
												<mat-form-field appearance="outline" class="quantity-input">
												  <input matInput type="text" [(ngModel)]="item.qty_purchase" (input)="updateBuyQuantity($event, item)" class="text-right ">
												</mat-form-field>
												<button mat-icon-button (click)="incrementQty(item)">
												  <mat-icon>add_circle</mat-icon>
												</button>
											  </div>
											</td>


											<!-- <td class="text-right noprint">

												<div class="quantity-control">


											  <mat-form-field appearance="outline">
												<mat-label>Quantity</mat-label>
												<span matPrefix>#</span>
												<input matInput type="text" [(ngModel)]="item.qty_purchase" (input)="updateBuyQuantity($event, item)" class="text-right mt-2" [ngClass]="{'focus': item === highlightedItem}">
											  </mat-form-field>

											</td> -->
											<td class="text-center noprint">
											  <button mat-icon-button (click)="confirmRemoveItem(item)" matTooltip="Remove Item">
												<mat-icon>delete</mat-icon>
											  </button>
											</td>
										  </tr>
										</tbody>
									  </table>
									</div>
									<div class="table" *ngIf="!loading && !results.length">
										<h4 class="text-center"> No Results</h4>
									</div>

								</div>
							</div>

						</ng-container>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
