import { Component, OnInit , Output, EventEmitter } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../../app.config';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';


@Component({
	selector: 'app-config-switcher',
	templateUrl: './config-switcher.component.html',
	styleUrls: ['./config-switcher.component.scss']
})
export class ConfigSwitcherComponent implements OnInit {
	availableConfigs: string[];
	currentConfig: string;
	@Output() reload = new EventEmitter < boolean > ();

	constructor(private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private appConfig: AppConfig, private configService: ConfigService) {}

	ngOnInit(): void {
		this.configService.loadAvailableConfigs().subscribe(configs => {
			this.availableConfigs = configs;
			this.globalSearchService.configsubscription.subscribe(r => {
				this.currentConfig = (r.env) ? r.env.client : environment.name;
			});
		});
	}

	onChangeConfig(newConfig: string): void {
		this.configService.loadConfig(newConfig).subscribe((r) => {
			this.currentConfig = newConfig;
			this.globalSearchService.setConfig(r);
			this.globalsService.updateConfigFile(r)
			this.globalSearchService.reload();
			this.reload.emit(true);
		});
	}



}
