import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { FormBuilder, Validators, ControlContainer, FormGroup,FormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'

@Component({
	selector: 'app-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
	@Input() user: any;
	@Output() updated = new EventEmitter < any > ();
	title = 'edit users';

	userForm: FormGroup;
	salesForm: FormGroup;
	isSalesmanToggle = new FormControl(false);
	userdetails: any = [];
	locations: any = [];
	userlocations: any = [];
	usergroups: any = [];
	alllocations: any = [];
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	grouplevels : any[] = [
		{ name : 'Default', value: '0' },
		{ name : 'Salesman', value: '1' },
		{ name : 'Sales Manager', value: '2' },
		{ name : 'Regional Manager', value: '3' },
	]

	groups: any = [];

	formEditAllowed = [
		'userid',
		'password',
		'realname',
		'phone',
		'email',
		'defaultlocation',
		'fullaccess',
		'cancreatetender',
		'editinv',
		'dispatchadmin',
		'customeradmin',
		'user_group',
		'blocked',
		'empcode',
		'department',
		'salesman',
		'viewcost'
		//'phoneno',
	]
	salesmanEditAllowed =[
		'salesmancode',
		'salesmanname',
		'smantel',
		'smanfax',
		'commissionrate1',
		'breakpoint',
		'commissionrate2',
		'current',
		'chatid',
		'group_id',
		'group_level',
		'group_type',
		'username',
	];

	userid: any = '';
	color: any = 'blue';

	company_devices: any = false;
	user_devices: any = false;
	zones: any = [];
	deviceTypes = ['ANY', 'INVOICE', 'CREDIT', 'PICK', 'RECEIPT']
	selectedDeviceTypes: {
		[deviceID: string]: string[]
	} = {};
	selectedPrintTypes: {
		[deviceID: string]: string[] } = {};

	managers: any = [];

	issalesman: false;
	salesman_data:any = false
	salespeople: any = [];
	govern_salespeople = new FormControl([])
	salespeopleArray: any = [];
	constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private userService: UsersService, public router: Router, private location: Location, public globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();

		this.salesForm = this.formBuilder.group({
			salesmancode: ['', Validators.required],
			salesmanname: ['', Validators.required],
			smantel: [''],
			smanfax: [''],
			commissionrate1: [''],
			breakpoint: [''],
			commissionrate2: [''],
			current: ['1'],
			chatid: [''],
			group_id: ['1'],
			group_level: ['0'],
			group_type: ['1'],
			username: [''],
			smanlogin: [''],
			smanpass: [''],
			smanaddr: [''],
			smancity: [''],
			smanstate: [''],
			smanzip: [''],
			smantab: [''],
			smancell: [''],
			smanemail: [''],
			access_level: ['0'],
			csremails: [''],
			manager: [''],
			viewcost: ['0'],
			assinged_salespeople: []
		});
	}

	ngOnInit(): void {

		this.globalsService.getActiveSalesPeople().subscribe(results => {
			this.salespeople = results;
		});

		this.globalsService.getSalesManagers().subscribe((results: any) => {
			this.managers = results;
		});

		this.globalSearchService.zones.subscribe((results: any) => {
			this.zones = results;
		});

		this.route.params.subscribe(params => {
			if (params['id'] && params['id'] !='') {
				this.userid = params['id'];
				this.loadUser()
			} else {
				this.userid = this.user.userid;
				this.loadUser();
			}
		});
	}

	loadUser() {
		this.userService.getUser(this.userid).subscribe((user: any) => {
			this.user = user;
			this.userdetails = user;

			if(user.issalesman) {
				this.isSalesmanToggle.setValue(true);
				const data = {
					salesman: user.salesman
				}
				this.globalsService.getSalesPerson(data).subscribe(salesperson => {

					this.salesman_data = salesperson;
					this.salespeopleArray = salesperson.assinged_salespeople;

					if(salesperson.smanemail == '') {
						salesperson.smanemail = this.userForm.get('email').value
					}

					if(salesperson.username == '') {
						salesperson.username = this.userForm.get('userid').value
					}

					if(salesperson.smantel == '') {
						salesperson.smantel = this.userForm.get('phone').value
					}



					this.salesForm = this.formBuilder.group({
						salesmancode: [salesperson.salesmancode, Validators.required],
						salesmanname: [salesperson.salesmanname, Validators.required],
						smantel: [salesperson.smantel],
						smanfax: [salesperson.smanfax],
						commissionrate1: [salesperson.commissionrate1],
						breakpoint: [salesperson.breakpoint],
						commissionrate2: [salesperson.commissionrate2],
						current: [salesperson.current],
						chatid: [salesperson.chatid],
						group_id: [salesperson.group_id],
						group_level: [salesperson.group_level],
						group_type: [salesperson.group_type],
						username: [salesperson.username],
						smanlogin: [salesperson.smanlogin],
						smanpass: [salesperson.smanpass],
						smanaddr: [salesperson.smanaddr],
						smancity: [salesperson.smancity],
						smanstate: [salesperson.smanstate],
						smanzip: [salesperson.smanzip],
						smantab: [salesperson.smantab],
						smancell: [salesperson.smancell],
						smanemail: [salesperson.smanemail],
						access_level: [salesperson.access_level],
						csremails: [salesperson.csremails],
						manager: [salesperson.manager],
						viewcost:[salesperson.viewcost],
						assinged_salespeople: [salesperson.assinged_salespeople]
					});

				});
			}

			const controls = [];
			const details = user;
			const formGroup: UntypedFormGroup = new FormGroup({});
			Object.keys(details).forEach(key => {
				if (this.formEditAllowed.includes(key)) {
					const value = (details[key]) ? details[key] : '';
					controls.push({ name: key, control: new FormControl(value, []) })
				}
			});
			controls.forEach(f => {
				formGroup.addControl(f.name, f.control, {})
			});

			this.userForm = formGroup;
			this.userForm.controls['userid'].disable();
			this.userForm.controls['password'].setValue('');
			const data = { userid: this.user.userid }

			this.userService.getCompanyDevices(data).subscribe(cd => {
				this.company_devices = cd;
				this.user_devices = this.userService.getPrintSettings(this.user.userid);
			})

			this.userForm.get('salesman').valueChanges.subscribe(newValue => {
				if(this.isSalesmanToggle.value) {
					if(this.salesForm) {
						this.salesForm.get('salesmancode').setValue(newValue);
					}
				}

				if(newValue == '') {
					this.isSalesmanToggle.setValue(false);
				}
			});
		});
	}

	getSelectedDeviceTypes(deviceID: string) {
		return this.selectedDeviceTypes[deviceID] || [];
	}

	updatePrintSettings(deviceID: string, deviceTypes: string[]) {
		const selectedDevice = this.user_devices.find(device => device.deviceID === deviceID);

		if (selectedDevice) {
			this.userService.setPrintSetting(this.user.userid, selectedDevice.machineUser, selectedDevice.machineName, deviceID, deviceTypes);
		}
	}


	addUserDevice(device: any) {

		const payload = {
			userid: this.user.userid,
			deviceid: device.device_id
		}
		// this.userService.addUserDevice(payload).subscribe( ud => {
		// 	let data = { userid: this.user.userid}

		//this.userService.setPrintSetting( this.user.userid, device.device_id, 'ALL')

		this.userService.setPrintSetting(this.user.userid, device.machine_user, device.machine_name, device.device_id, this.selectedDeviceTypes[device.device_id])
		// this.userService.getUserDevices(data).subscribe( ud => {
		// 	this.user_devices = ud;
		// })

		this.user_devices = this.userService.getPrintSettings(this.user.userid);

		//})
	}

	removeUserDevice(device: any) {

		const payload = {
			userid: this.user.userid,
			deviceid: device.deviceID
		}
		// this.userService.removeUserDevice(payload).subscribe( ud => {
		// 	let data = { userid: this.user.userid}
		this.userService.deletePrintSetting(this.user.userid, device.deviceID);
		// this.userService.getUserDevices(data).subscribe( ud => {
		// 	this.user_devices = ud;
		// })

		this.user_devices = this.userService.getPrintSettings(this.user.userid);
		//})
	}

	// Check if a device is already set up for a user
	isDeviceSetupForUser(deviceID: string) {
		const settings = JSON.parse(localStorage.getItem('printSettings')) || {};
		const userSettings = settings[this.user.userid] || [];
		return userSettings.some((device: any) => device.deviceID === deviceID);
	}

	back(): void {
		this.location.back()
	}

	onSave($event) {}

	updateUser() {

		this.userService.updateUser(this.user.userid, this.userForm.value).subscribe(async (results) => {
			this.user.user = results;
			this.globalSearchService.showNotification('Updated', 'success', 'bottom', 'right');
			this.updated.emit(results);

		});

	}

	getUserDevicesByPrintType(printType: string): any[] {
	  return this.user_devices.filter(device => device.deviceTypes.includes(printType));
	}

	ngAfterViewInit() {

		this.globalsService.getAllLocations().subscribe(async details => {
			this.locations = details;
			this.alllocations = details;
		});

		this.userService.getUserGroups().subscribe(async groups => {
			this.usergroups = groups;
		});

	}

	updateLocations() {
		const data = {
			userid: this.userid,
			locations: this.user.locations
		};

		this.userService.updateUserLocations(data).subscribe(async (user: any) => {
			this.user = user;
			this.globalsService.getAllLocations().subscribe(async details => {
				this.locations = details;
				this.alllocations = details;
			});
			this.globalSearchService.showNotification('Modifed', 'success', 'bottom', 'left')
		});
	}



	hasDevice(deviceid: any): boolean {
		const devices: any[] = [];
		//
		// 		if (this.user) {
		// 			devices = this.user_devices.filter(loc => loc.device_id === deviceid);
		// 		}

		return devices.length > 0;
	}

	saveSalesPerson() {
		if (this.salesForm.valid) {
			this.updateUser();




			this.salesForm.get('assinged_salespeople').setValue(this.salespeopleArray)

			this.globalsService.saveSalesPerson(this.salesForm.value).subscribe(result => {
			})
		}

	}

	hasLocation(location: any) {
		//this.user becomes false when modalService closes
		let access = []

		if (this.user) {
			access = this.user.locations.filter(loc => {
				return loc.loccode == location;
			})
		};

		return (access.length > 0) ? true : false;
	}

	addUserLocation(loc: any) {
		const index = this.locations.indexOf(loc)
		this.locations.splice(index, 1);
		this.user.locations.push(loc)
	}

	addAllLocation(loc: any) {
		this.user.locations = this.locations;
	}
	getIsManager() {
		if(parseFloat(this.salesForm.get('group_level').value) > 1) {
			return true;
		}
		return false;
	}

	removeUserLocation(loc: any) {
		const index = this.user.locations.indexOf(loc);
		this.user.locations.splice(index, 1);
	}

	toggleCheckbox(value: string) {
		const index = this.salespeopleArray.indexOf(value);
		if (index === -1) {
		  this.salespeopleArray.push(value);
		} else {
		  this.salespeopleArray.removeAt(index);
		}
	  }

	onSubmit() {

	}
}
