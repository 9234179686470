  <table class="mt-3 table table-hover table-striped">
	<thead class="sticky-top thead-dark">
	  <tr>
		<th scope="col">#</th>
		<th scope="col">Item ID</th>
		<th scope="col">Description</th>
		<th scope="col">Quantity</th>
		<th scope="col">Bins</th>
		<th></th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let item of negativeItems; let i = index">
		<th scope="row">{{ i + 1 }}</th>
		<td>{{ item.stockid }}</td>
		<td>{{ item.description }}</td>
		<td>{{ item.qoh }}</td>
		<td>{{ item.bins }}</td>
		<td>
		  <a [routerLink]="'/inventory/view/'+item.stockid" >View</a>
		</td>
	  </tr>
	</tbody>
  </table>
