<ng-container *ngIf="showInvoiceDetails">
	<ng-template #invoiceDetails let-modal>
		<div class="modal-header" *ngIf="displaydetails">
			<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
		</div>
		<div class="modal-body">
			<div class="mb-3" *ngIf="displaydetails">
				<h4></h4>
				<ng-container *ngIf="displaydetails.type == 'html'">
					<div [innerHTML]="displaydetails.display">
					</div>
				</ng-container>
				<ng-container *ngIf="displaydetails.type == 'pdf'">
					<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
				</ng-container>
			</div>
		</div>
		<div class="modal-footer">
		</div>
	</ng-template>
</ng-container>
<ng-container *ngIf="showOrderDetails">
	<ng-template #orderDetails let-modal>
		<div class="modal-header" *ngIf="orderdetails">
			<div class="modal-title row m-0 p-0" id="modal-title">
				<h4>Order# {{orderdetails.header.orderno}}</h4>
			</div>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
		</div>
		<div class="modal-body" *ngIf="orderdetails">
			<div class="mb-3" *ngIf="orderdetails">
				<div class="" *ngIf="orderdetails.dispatch_details && orderdetails.dispatch_details.status_id == '29'">

					<div #mapDiv class="w-100" [ngClass]="{'d-none': !orderdetails.dispatch_details.nextlast}">
						EST: {{orderdetails.dispatch_details.nextlast }}
						<div class="w-100" id="map">
						</div>
					</div>
				</div>

				<div class="row border-bottom">
					<div class="col-md-3 text-center">
						<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
					</div>
					<div class="col-md-3 text-center">
						<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
					</div>
					<div class="col-md-3 text-center">
						<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
					</div>
					<div class="col-md-3 text-center">
						<h5>Created: {{ orderdetails.header.date_created }}</h5>
					</div>
				</div>
				<div class="row mt-2 mb-2 border-bottom">
					<div class="col-md-3 ">
						<h5 class="mb-0">Ship To:</h5>
						<mat-divider></mat-divider>
						<span class="mt-2" [innerHTML]="orderdetails.header.shipto"></span>
					</div>
					<div class="col-md-3">
						<h5 class="mb-0">Phone:</h5>
						<mat-divider></mat-divider>
						<span class="mt-2">{{orderdetails.header.contactphone}}</span>

						<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
							<h5 class="mb-0 mt-2">Email:</h5>
							<mat-divider></mat-divider>
							<span class="mt-2">{{orderdetails.header.contactemail}}</span>
						</ng-container>
					</div>
					<div class="col-md-3">
						<h5 class="mb-0">Notes:</h5>
						<mat-divider></mat-divider>
						<span class="mt-2">{{orderdetails.header.comments}}</span>
					</div>
					<div class="col-md-3">
						<h5 class="mb-0">History: </h5>
						<mat-divider></mat-divider>
						<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
							<ul class="mt-2">
								<li *ngIf="orderdetails.dispatch_details.truck_name">
									ShipVia: {{ orderdetails.dispatch_details.truck_name }}<br>
									{{ orderdetails.dispatch_details.driveruserid }}
								</li>
								<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
									{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
									{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

								</li>
								<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
									{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
									{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
								</li>
							</ul>
						</ng-container>
						<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
							N/A
						</ng-container>
					</div>
				</div>
				<div class="table">
					<table class="table table-sm fs-bigger">
						<tr>
							<th>ITEM</th>
							<th>DESC</th>
							<th class="text-center">QOH</th>
							<th class="text-center">ORDERED</th>
							<th class="text-center">INVOICED</th>
							<th class="text-center">OUTSTANDING</th>
							<th class="text-right">PRICE</th>
							<th class="text-right" *ngIf="config.discounts">DISCOUNT</th>
							<th class="text-right">TOTAL</th>
						</tr>
						<tr *ngFor="let item of orderdetails.details">

							<td>{{item.stkcode}}</td>
							<td>{{item.description}}</td>
							<td class="text-center">{{item.qoh}}</td>
							<td class="text-center">{{item.ordered}}</td>
							<td class="text-center">{{item.qtyinvoiced}}</td>
							<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
							<td class="text-right">{{item.unitprice | currency}}</td>
							<td class="text-right" *ngIf="config.discounts">{{item.discountpercent | percent }}</td>
							<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
						</tr>
						<tr *ngFor="let t of orderdetails.saved_totals">
							<td></td>
							<td></td>
							<td></td>
							<td class="text-center"></td>
							<td class="text-center"></td>
							<td class="text-center"></td>
							<td class="text-center" *ngIf="config.discounts"></td>
							<td class="text-right">{{ t.title }}:</td>
							<td class="text-right">{{t.text | currency}}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="modal-footer">
		</div>
	</ng-template>
</ng-container>
<div class="container-fluid">
	<!-- Display Data -->
	<h4 class="text-left">Recent Transactions: ({{calculateDaysBetween(datefrom.value, dateto.value)}}) days
	</h4>
	<div class="row m-0 p-0 ">

		<ng-container>
			<div class="col-4">
				<mat-form-field appearance="fill">
					<input matInput [matDatepicker]="pickerFrom" placeholder="Date From" [formControl]="datefrom">
					<mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
					<mat-datepicker #pickerFrom></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-4">

				<mat-form-field appearance="fill">
					<input matInput [matDatepicker]="pickerTo" placeholder="Date To" [formControl]="dateto">
					<mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
					<mat-datepicker #pickerTo></mat-datepicker>
				</mat-form-field>
			</div>
		</ng-container>
		<div class="col-4">
			<mat-form-field appearance="fill" class="example-full-width">
				<mat-label>Search</mat-label>
				<input matInput placeholder="Search..." [formControl]="searchInput">
				<button mat-icon-button matSuffix (click)="loadAllData()">
					<mat-icon>search</mat-icon>
				</button>
				<div *ngIf="running" class="spinner-container">
					<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</div>
			</mat-form-field>
		</div>
	</div>
	<div class="row m-0 p-0">
		<div class="col-12">
			<div class="table-container mat-elevation-z8 mt-0 ">
				<table mat-table [dataSource]="dataSource" matSort class="mat-table table-fixed">

					<!-- Action Column -->
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef class="text-left"> </th>
						<td mat-cell *matCellDef="let item" class="text-left">
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon >more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<ng-container *ngIf="item.orderno">
									<button mat-menu-item (click)="viewOrder(item.orderno)" >
										<mat-icon>visibility</mat-icon>
										<span>View HTML</span>
									</button>
									<a href="{{invoicelink}}{{item.orderno}}" mat-menu-item>
										<mat-icon>print</mat-icon>
										<span>Customer Copy</span>
									</a>
								</ng-container>
								<ng-container *ngIf="item.transno">
									<button mat-menu-item (click)="openDocument(item, 'html')" *ngIf="item.transno">
										<mat-icon>visibility</mat-icon>
										<span>View HTML</span>
									</button>
									<button mat-menu-item (click)="openDocument(item, 'pdf')">
										<mat-icon>visibility</mat-icon>
										<span>View PDF</span>
									</button>
								</ng-container>
							</mat-menu>


						</td>
					</ng-container>

					<!-- Order # Column -->
					<ng-container matColumnDef="orderno">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Order #</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ item.orderno || item.order_ }}</td>
					</ng-container>

					<!-- Order Date Column -->
					<ng-container matColumnDef="trandate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Order Date</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ item.trandate || item.deliverydate }}</td>
					</ng-container>

					<!-- Order Status Column -->
					<ng-container matColumnDef="typename">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Order Status</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ item.typename || item.statusname }}</td>
					</ng-container>

					<!-- Customer Column -->
					<ng-container matColumnDef="customer">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Customer</th>
						<td mat-cell *matCellDef="let item" class="text-left">({{ item.debtorno }}.{{ item.branchcode }}) {{ item.name || item.deliverto }}</td>
					</ng-container>

					<!-- Amount Column -->
					<ng-container matColumnDef="ovamount">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Amount</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ (item.ovamount ?? 0) | currency }}</td>
					</ng-container>

					<!-- Tax Column -->
					<ng-container matColumnDef="ovgst">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Tax</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ (item.ovgst ?? 0) | currency }}</td>
					</ng-container>

					<!-- Freight Column -->
					<ng-container matColumnDef="ovfreight">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Freight</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ (item.ovfreight ?? 0) | currency }}</td>
					</ng-container>

					<!-- Total Column -->
					<ng-container matColumnDef="total">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Total</th>
						<td mat-cell *matCellDef="let item" class="text-left">{{ item.total | currency }}</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>

				<div class="totals">
					<div><strong>TOTALS</strong></div>
					<div>Amount: {{ total_sub | currency }}</div>
					<div>Tax: {{ total_tax | currency }}</div>
					<div>Freight: {{ total_freight | currency }}</div>
					<div>Total: {{ total_trans | currency }}</div>
				</div>
			</div>
			<mat-paginator #paginator [pageSizeOptions]="[10, 25, 50, 100, 500]" showFirstLastButtons aria-label="select page">
			</mat-paginator>

		</div>
	</div>
</div>
