<div class="table table-responsive w-100">
	<table class="m-0 p-0 table table-sm  smaller-font" *ngIf="aged" >
		<tr>

			<th class="text-center w-20">BALANCE</th>
			<th class="text-center w-20">FUTURE</th>
			<th class="text-center w-20">CURRENT</th>
			<th class="text-center w-20">30</th>
			<th class="text-center w-20">60</th>
			<th class="text-center w-20">90</th>
			<th class="text-center w-20">120</th>
		</tr>
		<tr>
			<td class="text-center w-20 pointer" [ngClass]="aged.balance > 0 ? 'bg-danger text-white bolder': '' " (click)="emitZero()">{{ aged.balance | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.future > 0 ? 'bg-danger text-white bolder': '' " (click)="emitOne()">{{ aged.future | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.current > 0 ? 'bg-danger text-white bolder': '' " (click)="emitTwo()">{{ aged.current | currency}}</td>

			<td class="text-center w-20 pointer" [ngClass]="aged.due > 0 ? 'bg-danger text-white bolder': '' " (click)="emitThree()">{{ aged.due | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.overdue1 > 0 ? 'bg-danger text-white bolder': '' " (click)="emitFour()">{{ aged.overdue | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.overdue2 > 0 ? 'bg-danger text-white bolder': '' " (click)="emitFive()">{{ aged.overdue1 | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.overdue2 > 0 ? 'bg-danger text-white bolder': '' " (click)="emitSix()">{{ aged.overdue2 | currency}}</td>

		</tr>
	</table>
</div>
