<ng-container>
	<form @grow [formGroup]="itemForm" (ngSubmit)="onSubmit()" class="needs-validation product-add" *ngIf="itemForm">
		<div class="card-header">
			<div class="col-12 text-right">
				<button class="ml-auto mr-0" mat-button mat-raised-button [color]="itemForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>

			</div>
		</div>
		<div class="row">
			<div class="row mt-2">
				<div class="col-md-12">

					<mat-form-field appearance="outline">
						<mat-label>Primary Barcode</mat-label>
						<input matInput required formControlName="barcode">
					</mat-form-field>
					<mat-form-field appearance="outline" [ngClass]="config.env.package == 'tires' ? '': 'd-none'">
						<mat-label>Additional Charge (FET)</mat-label>
						<input matInput required formControlName="additionalfee">
					</mat-form-field>
					<!--
				<mat-form-field appearance="outline">
					<mat-label>EOQ</mat-label>
					<input matInput required  formControlName="eoq">
				</mat-form-field>
			-->
					<mat-form-field appearance="outline" class="">
						<mat-label>Unit of Measure</mat-label>
						<mat-select formControlName="units">
							<mat-option *ngFor="let k of properties.uom" [value]="k.value">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<br>
					<mat-slide-toggle formControlName="commissionable">Commissionable</mat-slide-toggle>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>Commission Rate</mat-label>
						<input matInput formControlName="commpct" class="text-right">
						<mat-icon matSuffix><small>&nbsp;&nbsp;%</small></mat-icon>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-slide-toggle formControlName="dwo" *ngIf="qoh > 0 && !item.discontinued">DWO</mat-slide-toggle>
					&nbsp;&nbsp;<mat-slide-toggle formControlName="enabled">Web Enabled</mat-slide-toggle>
				</div>
				<div class="col-md-6" >
					<mat-slide-toggle formControlName="discontinued" *ngIf="qoh <= 0 || item.discontinued">Discontinued</mat-slide-toggle>
					&nbsp;&nbsp;
					<mat-slide-toggle formControlName="controlled">Controlled</mat-slide-toggle>
					&nbsp;&nbsp;
				</div>
				<div class="col-md-12">

					<mat-form-field appearance="outline" class="" *ngIf="itemForm.get('controlled').value == '1'">
						<mat-label>Serialized</mat-label>
						<mat-select formControlName="serialised">
							<mat-option *ngFor="let k of properties.serialized" [value]="k.value">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
</ng-container>
