<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #voidDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Void Transaction #{{ void_trans.transno }}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="isPosUser">
			<div class="col-xs-12 col-md-3 col-3">
				<mat-form-field appearance="outline">
					<mat-label>Employee Code</mat-label>
					<input matInput #empCode autocomplete="off" [formControl]="empcode" class="text-center mt-0 mb-0 pt-0 pb-0 " value="" placeholder="EMPLOYEE CODE"  autofocus>
				</mat-form-field>
			</div>
		</ng-container>
		<mat-form-field appearance="outline" class="col-2">
			<mat-label>Void Date</mat-label>
			<input matInput [formControl]="voiddate" [matDatepicker]="payfrom">
			<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
			<mat-datepicker #payfrom></mat-datepicker>
		</mat-form-field>

		<div class="row">
			<div class="col-md-4">
				<ul class="list-group">
					<li class="list-group-item no-border">Void Transaction#{{ void_trans.transno }}</li>
					<li class="list-group-item no-border">Type: {{ void_trans.banktranstype  }}</li>
					<li class="list-group-item no-border">Order#: {{ void_trans.cc_data.order_number }}</li>
					<li class="list-group-item no-border">Amount: {{ void_trans.amount | currency }}</li>
					<li class="list-group-item no-border">Return Inventory?&nbsp;&nbsp;&nbsp;<mat-checkbox class="mt-4" [formControl]="create_credit">Return Inventory</mat-checkbox>
					</li>
				</ul>
			</div>
			<div class="col-md-4">
				<ul class="list-group">
				</ul>
			</div>
			<div class="col-md-4">
				<ul class="list-group">
				</ul>
			</div>
		</div>
		<button mat-raised-button color="accent" (click)="processVoid()" *ngIf="!voiding">Void</button>
		<ng-container *ngIf="emperror">
			<h3 class="text-danger mt-2 pt-0">EMPLOYEE CODE NOT FOUND</h3>
		</ng-container>
		<span *ngIf="voiding"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							{{ title }}
						</h4>
					</div>
					<div class="card-body">

						<div class="row">
							<div class="col-md-2" [ngClass]="isPosUser ? 'd-none': ''">
								<mat-form-field appearance="outline" >
									<mat-label>Warehouse</mat-label>
									<mat-select [formControl]="defaultlocation" multiple appSelectAll>
										<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
											{{ loc.locationname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-2" *ngIf="config.env.package == 'beauty'">
								<mat-form-field appearance="outline" >
									<mat-label>Salesperson</mat-label>
									<mat-select [formControl]="salesperson" multiple appSelectAll>
										<mat-option *ngFor="let sales of salespeople" [value]="sales.salesmancode">
											{{ sales.salesmancode }} - {{ sales.salesmanname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div [ngClass]="config.env.package == 'beauty' ? 'col-md-2' : 'col-md-3'">
								<mat-form-field appearance="outline">
									<mat-label>From Date</mat-label>
									<input matInput [formControl]="datefrom" [matDatepicker]="payfrom">
									<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
									<mat-datepicker #payfrom></mat-datepicker>
								</mat-form-field>
							</div>
							<div [ngClass]="config.env.package == 'beauty' ? 'col-md-2' : 'col-md-3'">
								<mat-form-field appearance="outline">
									<mat-label>To Date</mat-label>
									<input matInput  [formControl]="dateto" [matDatepicker]="payto">
									<mat-datepicker-toggle matSuffix [for]="payto"></mat-datepicker-toggle>
									<mat-datepicker #payto></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-4">

								<button class="mt-3"
								 mat-raised-button  color="accent" (click)="loadData()" [disabled]="running">Load</button>&nbsp;&nbsp;

								 <span *ngIf="running || exporting"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									  </svg>
								  </span>


								 <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="payments.summary && payments.transactions && !running && !exporting && payments.transactions.length" >
									<i class="fa fa-file-excel-o"></i> XLS
								</button>
								&nbsp;&nbsp;
								<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="payments.summary&& payments.transactions && !running && !exporting && payments.transactions.length" >
									<i class="fa fa-file-pdf-o"></i> PDF
								</button>
							</div>
						</div>
						<!--left-->
						<div class="row m-0 p-0 mt-3 ">

							<div class="col-md-12" id="payrep" *ngIf="!running" >
								<h4>Total Trans {{ total_trans | currency }}</h4>
							</div>
							<div class="col-md-12" id="payrep" *ngIf="!running" >
								<ng-container *ngIf="payments.transactions">
									<div class="table" >
									<div class="bg-white mt-2 rounded">
										<div id="receipts">
											<h4 class="mt-2 mb-4 noprint">{{ title }} {{ getLocationName() }}</h4>
											<mat-slide-toggle [checked]="hideextrafields" (change)="hideextrafields = $event.checked" class="d-none"></mat-slide-toggle>&nbsp;&nbsp;

											<div class="row m-0 p-0">
												<div class="col-6">
													<mat-form-field appearance="outline">
														<mat-label>Search</mat-label>
														<input type="text" class="text-left" matInput [formControl]="searcher" />
													</mat-form-field>
												</div>
												<div class="col-6">
													<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
												</div>
											</div>
											<div class="table table-responsive table-fluid">
												<table id="rcptTable" name="rcptTable" class="table compact  table-sm table-fixed table-fluid table-sm-responsive collapsed  w-100" *ngIf="payments.transactions && payments.transactions.length">
												<thead>
													<tr>
														<th class="text-left">Date</th>
														<th class="text-center" *ngIf="locations.length > 1">Location</th>
														<th class="text-center">Order#</th>
														<th class="text-center" *ngIf="!hideextrafields">Document#</th>
														<th class="text-center">Type#</th>
														<th class="text-center" *ngIf="!hideextrafields">Terms#</th>
														<th class="text-left">Customer#</th>
														<th class="text-left">Name</th>
														<th class="text-right">Amount</th>
														<th class="text-right">Freight</th>
														<th class="text-right">Discount</th>
														<th class="text-right">Tax</th>
														<th class="text-right" *ngIf="config.env.package =='tires'">FET</th>
														<th class="text-right"><b>Total</b></th>
														<th class="text-right"><b>Payment</b></th>
														<th class="text-center">Settled</th>
														<th class="text-center">Reference</th>
														<th class="text-center">Payments</th>
														<th *ngIf="config.env.package != 'tires' && !this.user.user.issalesman"></th>
													</tr>
												</thead>
												<tbody>
													<ng-container *ngFor="let pay of dataObs | async">
													<tr>

														<td class="text-left"> {{ pay.inputdate }}</td>
														<td class="text-center" *ngIf="locations.length > 1">{{ pay.loccode }}</td>
														<td class="text-center"><a href="#" (click)="$event.preventDefault();openDocument(pay,'html')"> {{ pay.order_ }}</a></td>
														<td class="text-center" *ngIf="!hideextrafields"> {{ pay.transno }} </td>
														<td class="text-center"> {{ pay.typename }} </td>
														<td class="text-center" *ngIf="!hideextrafields"> {{ pay.payterms }} </td>

														<td class="text-left text-nowrap">
															<a [routerLink]="'/customers/view/' + pay.debtorno">
																<b>{{ pay.debtorno }}.{{ pay.branchcode }}</b>
															</a>
														</td>
														<td class="text-left text-nowrap">{{ pay.name }}</td>
														<td class="text-right"> {{ pay.ovamount | currency }}</td>
														<td class="text-right"> {{ pay.ovfreight | currency }}</td>
														<td class="text-right"> {{ pay.ovdiscount | currency }}</td>
														<td class="text-right"> {{ pay.ovgst | currency }}</td>
														<td class="text-right" *ngIf="config.env.package =='tires'"> {{ pay.ovfee | currency }}</td>
														<td class="text-right">{{ pay.total | currency }}</td>
														<td class="text-right" [ngClass]="{'text-success
														': pay.settled == 'Yes', 'text-danger' : pay.settled =='No'}">{{ pay.alloc | currency }}</td>
														<td class="text-center"> {{ pay.settled }}</td>
														<td class="text-center"> {{ pay.reference}}</td>
														<td class="text-center"> {{ pay.paytext}}</td>
														<td *ngIf="config.env.package != 'tires' && !this.user.user.issalesman ">
															<a (click)="voidPayment(pay)" class="pointer" *ngIf="pay.reference != 'VOID' && pay.type != '11' ">VOID</a>
														</td>
													</tr>
													</ng-container>

													<tr>
														<td class="text-center" *ngIf="locations.length > 1">  </td>
														<td class="text-center"><b>TOTALS</b></td>
														<td class="text-center" *ngIf="!hideextrafields"></td>
														<td class="text-center" *ngIf="!hideextrafields">  </td>
														<td class="text-center" *ngIf="config.env.package != 'tires' ">  </td>
														<td class="text-right text-nowrap"></td>
														<td class="text-right text-nowrap"></td>
														<td class="text-left text-nowrap"></td>
														<td class="text-right text-nowrapnumber"> {{ total_sub | currency }}</td>
														<td class="text-right text-nowrapnumber"> {{ total_freight | currency }}</td>
														<td class="text-right text-nowrapnumber"> {{ total_discount | currency }}</td>
														<td class="text-right text-nowrapnumber"> {{ total_tax | currency }}</td>
														<td class="text-right text-nowrapnumber" *ngIf="config.env.package =='tires'"> {{ total_fee | currency }}</td>
														<td class="text-right text-nowrapnumber">{{ total_trans | currency }}</td>
														<td class="text-right" ></td>
														<td class="text-center"></td>

														<td class="text-center" ></td>
														<td *ngIf="config.env.package != 'tires' && !this.user.user.issalesman "></td>
													</tr>
												</tbody>
											</table>
											</div>
										</div>
									</div>
								</div>

									<div class="d-none">
										<div class="table" #print_table >
											<div class="bg-white mt-2 rounded">
												<div id="receipts">
													<h4 class="mt-2 mb-4 noprint">{{ title }} {{ getLocationName() }}</h4>
													<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed" *ngIf="payments.transactions && payments.transactions.length">
														<thead>
															<tr>
																<th class="text-center" *ngIf="locations.length > 1">Location</th>
																<th class="text-center">Date</th>
																<th class="text-center">Order#</th>
																<th class="text-center">Document#</th>
																<th class="text-center">Type#</th>
																<th class="text-center">Terms#</th>
																<th class="text-right">Customer#</th>
																<th class="text-left">Name</th>
																<th class="text-right">Amount</th>
																<th class="text-right">Freight</th>
																<th class="text-right">Discount</th>
																<th class="text-right">Tax</th>
																<th class="text-right" *ngIf="config.env.package =='tires'">FET</th>
																<th class="text-right"><b>Total</b></th>
																<th class="text-right"><b>Payment</b></th>
																<th class="text-center">Settled</th>
																<th class="text-center">Reference</th>
																<th class="text-center">Payments</th>
															</tr>
														</thead>
														<tbody>
															<ng-container *ngFor="let pay of payments.transactions">
															<tr>
																<td class="text-left" *ngIf="locations.length > 1">{{ pay.loccode }}</td>
																<td class="text-center"> {{ pay.trandate }}</td>
																<td class="text-center"> {{ pay.order_ }} </td>
																<td class="text-center"> {{ pay.transno }} </td>
																<td class="text-center"> {{ pay.typename }} </td>
																<td class="text-center"> {{ pay.payterms }} </td>

																<td class="text-right text-nowrap">
																	<a [routerLink]="'/customers/view/' + pay.debtorno">
																		<b>{{ pay.debtorno }}.{{ pay.branchcode }}</b>
																	</a>
																</td>
																<td class="text-left text-nowrap">{{ pay.name }}</td>
																<td class="text-right"> {{ pay.ovamount | currency }}</td>
																<td class="text-right"> {{ pay.ovfreight | currency }}</td>
																<td class="text-right"> {{ pay.ovdiscount | currency }}</td>
																<td class="text-right"> {{ pay.ovgst | currency }}</td>
																<td class="text-right" *ngIf="config.env.package =='tires'"> {{ pay.ovfee | currency }}</td>
																<td class="text-right">{{ pay.total | currency }}</td>
																<td class="text-right" [ngClass]="{'text-success
																': pay.settled == 'Yes', 'text-danger' : pay.settled =='No'}">{{ pay.alloc | currency }}</td>
																<td class="text-center"> {{ pay.settled }}</td>
																<td class="text-center"> {{ pay.reference}}</td>
																<td class="text-center"> {{ pay.paytext}}</td>
															</tr>
															</ng-container>

															<tr>
																<td class="text-center"><b>TOTALS</b></td>
																<td class="text-center"></td>
																<td class="text-center">  </td>
																<td class="text-center">  </td>
																<td class="text-center" *ngIf="config.env.package =='tires'">  </td>
																<td class="text-center" *ngIf="config.env.package =='tires'">  </td>
																<td class="text-center">  </td>
																<td class="text-right text-nowrap"></td>
																<td class="text-left text-nowrap"></td>
																<td class="text-right text-nowrapnumber"> {{ total_sub | currency }}</td>
																<td class="text-right text-nowrapnumber"> {{ total_freight | currency }}</td>
																<td class="text-right text-nowrapnumber"> {{ total_discount | currency }}</td>
																<td class="text-right text-nowrapnumber"> {{ total_tax | currency }}</td>
																<td class="text-right text-nowrapnumber" *ngIf="config.env.package =='tires'"> {{ total_fee | currency }}</td>
																<td class="text-right text-nowrapnumber">{{ total_trans | currency }}</td>
																<td class="text-right" ></td>
																<td class="text-center"></td>
																<td class="text-center"></td>
																<td class="text-center"></td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="payments.transactions && !payments.transactions.length">
									<h3 class="text-center">No Transactions Found</h3>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
