import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit, Directive, HostListener  } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';

@Component({
	selector: 'app-counter-pos-invoice',
	templateUrl: './counter-pos-invoice.component.html',
	styleUrls: ['./counter-pos-invoice.component.scss'],
	animations: [
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class CounterPosInvoiceComponent {

	CHAT_ROOM = 'OrderBoard';

	order: any = {
		header: [],
		details: [],
		tax_data: {},
	};

	orderno: any = '';
	debtorno: any = '';
	branchcode: any = '';
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching = false;
	invoicing = true;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total = 0;
	freight_charge = 0.00;
	payment_total = 0.00
	freight_taxes: any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		discount: 0.00,
		total: 0.00,
	};
	taxdatatotal = 0;

	prefered_vendor: any = [];
	payments: any = [];
	color = 'blue'
	sending = false;

	constructor(private omsService:OmsService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {
		this.color = this.globalSearchService.getColor();
	}

	showInvoiceResultandReset(event: any) {
		const data = [];
		this.omsService.sendOrderCheckUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
		});
	}

	emitCustomerUpdate(event:any) {

	}

	ngOnInit(): void {

		this.route.params.subscribe(params => {
			this.orderno = params.id
			this.orderService.getOrder(this.orderno).subscribe((details) => {
				this.order = details
				this.freight_charge = parseFloat(this.order.header.freightcost)
				this.debtorno = this.order.header.debtorno
				this.branchcode = this.order.header.branchcode
				this.orderno = this.order.header.orderno
				console.log(this.order);

				const data = {
					orderno: this.order.header.orderno,
					debtorno: this.order.header.debtorno,
					branchcode: this.order.header.branchcode,
				}

				this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
					this.payments = results;
					this.updateTotals();
				})
			})
		})
	}

	getBalance() {
		const value = this.payment_total - this.totals.total;
		//if(value < 0) {
		//	value = 0.00
		//}
		return value;
	}

	createInvoice() {
		if (this.order) {
			const data = {
				order: this.order,
				totals: this.totals,
				payments: this.payments_added,
				taxes: this.taxes
			}
			this.sending = true;
			this.orderService.createInvoice(data).subscribe((results: any) => {
				this.sending = false;
				if (results.success) {
					this.router.navigate(['/orders/success/' + results.id]);
				} else {
					alert('Error');
				}
			});
		}
	}

	updateDispatchQty(event: any, item: any) {


		if(event.target.value < 0) {
			event.target.value = 0;
		}

		if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {

			event.target.value = parseFloat(item.ordered);
			item.dispatch = parseFloat(item.ordered)
		}

		this.updateTotals()
	}

	setQty(event: any, item: any) {
		if (parseFloat(item.ordered) < parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {
			item.dispatch = item.ordered;
		}

		this.updateTotals()
	}

	updatePayments(event: any) {
		this.payments_added = event;
		this.payment_total = this.payments_added.reduce(function(accumulator, item) {
			return parseFloat(accumulator) + parseFloat(item.amount);
		}, 0);
	}

	setFreightCharge(event: any) {
		this.order.header.freightcost = this.santizeNumberValue(event.target.value);
		this.freight_charge = this.order.header.freightcost;
		this.updateTotals();
	}

	setFreightTax(event: any, tax: any) {
		tax.taxrate = this.santizeNumberValue(event.target.value);
		this.updateTotals();
	}

	setTax(event: any, item: any, taxinndex: any) {
		item.taxes[taxinndex].taxrate = this.santizeNumberValue(event.target.value);
		this.updateTotals();
	}

	getFreightTax() {
		const total = 0
		this.freight_tax_total = 0;
		const freight = this.freight_charge
		let freighttax = 0;
		if (this.order) {
			const taxauths = [];
			this.freight_taxes = this.order.freight_tax;

			//by auth
			this.order.freight_tax.forEach((tax, index) => {
				taxauths.push({ taxauthid: tax.taxauthid, value: ((tax.taxrate / 100)* freight) })
			});

			this.freight_taxes = taxauths;
			//total freight tax
			freighttax = this.order.freight_tax.reduce(function(accumulator, tax) {
				return parseFloat(accumulator) + ((tax.taxrate /100) * freight);
			}, 0);
		}

		this.freight_tax_total = freighttax;
		return freighttax;
	}

	updateTotals() {
		//todo clean this up. requires two containers..
		//parse items subtotals / taxes first.
		this.totals.total = 0;
		let totaltax = 0;
		//zero out tax containers
		this.order.tax_data.authorities.forEach((item, index) => {
			this.order.tax_data.authorities[index].total = 0;
		});

		this.order.details.forEach((item, index) => {
			//subtotals
			const subtotal = (item.unitprice - (item.discountpercent * item.unitprice)) * item.dispatch;

			this.order.details[index].linesubtotal = subtotal;
			this.order.details[index].taxtotal = 0;

			//item tax breakdown
			if(this.order.tax_data.authorities.length) {
				item.taxes.forEach((tax, subindex) => {
					this.order.tax_data.authorities.filter(all => all.taxauthid == tax.taxauthid)[0].total = (tax.taxrate * subtotal);
					this.order.details[index].taxes[subindex].total = ((tax.taxrate / 100) * subtotal)
				});
			}

			const total_linetax = item.taxes.reduce(function(accumulator, line) {
				return accumulator + ((line.taxrate / 100) * subtotal);
			}, 0);

			this.order.details[index].taxtotal = total_linetax;
			this.order.details[index].linetotal = total_linetax + subtotal;

			totaltax += total_linetax;
		});

		this.totals.subtotal = this.order.details.reduce(function(accumulator, line) {
			return accumulator + (line.unitprice * line.dispatch);
		}, 0);

		const freighttax = this.getFreightTax()
		//add freight tax to tax total container

		this.freight_taxes.forEach((fghtax) => {
			this.order.tax_data.authorities.forEach((tax, index) => {
				if (tax.taxauthid == fghtax.taxauthid) {
					this.order.tax_data.authorities[index].total += fghtax.value;
				}
			})
		})

		this.taxdatatotal = this.order.tax_data.authorities.reduce(function(accumulator, line) {
			return accumulator + (line.total);
		}, 0);
		//total tax includes freight tax
		this.totals.tax = totaltax + freighttax;
		this.totals.freight = this.freight_charge;
		this.totals.total = this.totals.subtotal + this.totals.freight + this.totals.tax + this.totals.discount;

		//this.display_total = (this.totals.total - this.payment_total).toFixed(2);

	}

	santizeNumberValue(input: any) {
		return (Number.isNaN(parseFloat(input)) || input == '') ? 0 : parseFloat(input);
	}

	back(): void {
		this.location.back()
	}

	addPoItems(supplier: any) {

		const item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	ngAfterViewInit(): void {

	}

}
