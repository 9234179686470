<ng-template #newOrders>
	<div class="container-fluid" *ngIf="pendingorders.length">
		<div class="row">
			<div class="col-md-10">
				<h4>Pending Orders</h4>
			</div>
			<div class="col-md-2 text-right">
				<button mat-raised-button [color]="'accent'" (click)="toggleNewOrders()" class="mt-3">Close</button>
			</div>
		</div>
		<div class="row">
			<div class="col-6">
				<mat-form-field class="full-width text-right" appearance="outline" color="accent">
					<mat-label>Search Orders</mat-label>
					<input type="text" placeholder="Search" aria-label="Text" matInput [formControl]="searchPending" (input)="searchOpenOrders($event.target.value)">
				</mat-form-field>
			</div>
			<div class="col-6">
				<mat-paginator
		  		[pageSizeOptions]="items_per_page"
		  		[pageSize]="pagesizedefault"
				></mat-paginator>
			</div>
		</div>

		<div class="table table-fixed" *ngIf="pendingorders.length">
			<table class="table table-sm table-fixed">
				<thead>
				<tr>
					<th class="text-left">Order#</th>
					<th class="text-left">Customer</th>
					<th class="text-left">Deliver To</th>
					<th class="text-left">Address</th>
					<th class="text-left">City</th>
					<th class="text-center"></th>
					<th class="text-center"></th>
					<th class="text-center"></th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let o of dataObs | async">
					<td>

						 <button mat-raised-button (click)="loadOrder(o.orderno)" matTooltip="Edit Order">
							 {{ o.orderno }}
						</button>
					</td>
					<td>
						<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
					</td>
					<td>{{ o.deliverto }}</td>
					<td>{{ o.deladdress1 }}</td>
					<td>{{ o.deladdress3 }}</td>
					<td><button mat-raised-button (click)="pickOrder(o.orderno)" matTooltip="Pick Order">PICK</button>
					<td><button mat-raised-button (click)="checkOrder(o.orderno)" matTooltip="Check Order">CHECK</button>
					<td><button mat-raised-button (click)="invoiceOrder(o.orderno)" matTooltip="Invoice Order">INVOICE</button>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>
<ng-template #pickingOrders>
	<div class="row">
		<div class="col-md-10">
			<h3>Picking Orders</h3>
		</div>
		<div class="col-md-2 text-right">
			<button mat-raised-button [color]="'accent'" (click)="togglePickingOrders()" class="mt-3">Close</button>
		</div>
	</div>
	<div class="table table-fixed" *ngIf="pickingorders.length">
		<table class="table table-sm table-fixed">
			<thead>
			<tr>
				<th class="text-left">Order#</th>
				<th class="text-left">Customer</th>
				<th class="text-left">Deliver To</th>
				<th class="text-left">Address</th>
				<th class="text-left">City</th>
				<th class="text-center"></th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let o of pickingorders">
				<td>
					<button mat-raised-button (click)="loadOrder(o.orderno)">
						{{ o.orderno }}
					</button>
				</td>
				<td>
					<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
				</td>
				<td>{{ o.deliverto }}</td>
				<td>{{ o.deladd1 }}</td>
				<td>{{ o.deladd3 }}</td>
				<td><button mat-raised-button (click)="checkOrder(o.orderno)">CHECK</button>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>
<ng-template #pickedOrders>
	<div class="row">
		<div class="col-md-10">
			<h3>Picked Orders</h3>
		</div>
		<div class="col-md-2 text-right">
			<button mat-raised-button [color]="'accent'" (click)="togglePickedOrders()" class="mt-3">Close</button>
		</div>
	</div>
	<div class="table table-fixed" *ngIf="pickedorders.length">
		<table class="table table-sm table-fixed">
			<thead>
			<tr>
				<th class="text-left">Order#</th>
				<th class="text-left">Customer</th>
				<th class="text-left">Deliver To</th>
				<th class="text-left">Address</th>
				<th class="text-left">City</th>
				<th class="text-center"></th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let o of pickedorders">
				<td>
					<button mat-raised-button (click)="loadOrder(o.orderno)">
						{{ o.orderno }}
					</button>
				</td>
				<td>
					<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
				</td>
				<td>{{ o.deliverto }}</td>
				<td>{{ o.deladd1 }}</td>
				<td>{{ o.deladd3 }}</td>
				<td><button mat-raised-button (click)="checkOrder(o.orderno)">CHECK</button>

				</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>
<ng-template #checkedOrders>
	<div class="row">
		<div class="col-md-10">
			<h3>Checked Orders</h3>
		</div>
		<div class="col-md-2 text-right">
			<button mat-raised-button [color]="'accent'" (click)="toggleCheckedOrders()" class="mt-3">Close</button>
		</div>
	</div>
	<div class="table table-fixed" *ngIf="invoiceorders.length">
		<table class="table table-sm table-fixed">
			<thead>
			<tr>
				<th class="text-left">Order#</th>
				<th class="text-left">Customer</th>
				<th class="text-left">Deliver To</th>
				<th class="text-left">Address</th>
				<th class="text-left">City</th>
				<th class="text-center"></th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let o of invoiceorders">
				<td>
					<button mat-raised-button (click)="loadOrder(o.orderno)">
						{{ o.orderno }}
					</button>
				</td>
				<td>
					<a [routerLink]="'/customers/view/'+o.debtorno">{{ o.debtorno }}.{{ o.branchcode }}</a>
				</td>
				<td>{{ o.deliverto }}</td>
				<td> {{ o.deladd1 }}</td>
				<td>{{ o.deladd3 }}</td>
				<td><button mat-raised-button (click)="invoiceOrder(o.orderno)">Invoice</button>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>



<app-chat-sidebar *ngIf="right_sidebar"></app-chat-sidebar>
<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-top" [attr.data-background-color]="color" >
	<div class="container-fluid">
		<div class="navbar-wrapper">
			<div class="navbar-minimize" (click)="miniSideBar()">
				<button mat-flat-button=""
				class="btn btn-just-icon btn-white btn-fab btn-round"><i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i><i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i></button></div>
			<!-- <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}} <span *ngIf="idleState == 'TIMED_OUT'"> </span></a> -->
		</div>

		<ng-container *ngIf="config.env.package != 'tires'">
			&nbsp;&nbsp;&nbsp;&nbsp;
			<ng-container *ngIf="pendingorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="toggleNewOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Pending Orders">
						<mat-icon matBadgePosition="after" [matBadge]="pendingorders.length" matBadgeColor="accent" class="mr-3">notifications_active</mat-icon>
					</button>
				</div>
			</ng-container>
			&nbsp;&nbsp;
			<ng-container *ngIf="pickingorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="togglePickingOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Picking Orders">
						<mat-icon matBadgePosition="after" [matBadge]="pickingorders.length" matBadgeColor="accent" class="mr-3">inventory</mat-icon>
					</button>
				</div>
			</ng-container>
			&nbsp;&nbsp;
			<ng-container *ngIf="pickedorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="togglePickedOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Picked Orders">
						<mat-icon matBadgePosition="after" [matBadge]="pickedorders.length" matBadgeColor="accent" class="mr-3">check_circle</mat-icon>
					</button>
				</div>
			</ng-container>
			&nbsp;&nbsp;
			<ng-container *ngIf="invoiceorders.length" >
				<div class="ml-auto mt-3 mr-0 nav-item no-border noshadow" >
					<button (click)="toggleCheckedOrders()" class="bg-transparent border-0 noshadow" matTooltip="View Checking">
						<mat-icon matBadgePosition="after" [matBadge]="invoiceorders.length" matBadgeColor="accent" class="mr-3">playlist_add_check</mat-icon>
					</button>
				</div>
			</ng-container>
		</ng-container>

		<button mat-flat-button class="navbar-toggler text-white" type="button" (click)="sidebarToggle()">
			<span class="sr-only text-white">Toggle navigation</span>
			<span class="navbar-toggler-icon icon-bar text-white"></span>
			<span class="navbar-toggler-icon icon-bar text-white"></span>
			<span class="navbar-toggler-icon icon-bar text-white"></span>
		</button>

		<div class="collapse navbar-collapse justify-content-end" id="navigation">
			<search></search>
			<ul class="navbar-nav d-none">
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)">
						<i class="material-icons">dashboard</i>
						<p><span class="d-lg-none d-md-block">Stats</span></p>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)">
						<i class="material-icons">person</i>
						<p>
							<span class="d-lg-none d-md-block">Account</span>
						</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</nav>
