
<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<ng-container *ngIf="displaydetails.display">
		<div class="modal-body text-dark" [innerHtml]="displaydetails.display" *ngIf="displaydetails">

		</div>
	</ng-container>

	<ng-container *ngIf="podetails">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<mat-tab label="Purchase Order">
				<div class="mb-3 ml-2 mr-2" *ngIf="podetails">
					<h4 class="mt-3">PO # {{ podetails.poheader.orderno }}</h4>
					<div class="table">

						<table class="table table-sm">
							<tr>
								<td>

									{{ podetails.poheader.supplierno }} {{podetails.poheader.contact}}<br>
									{{podetails.poheader.suppdeladdress1}}<br>
									<span *ngIf="podetails.poheader.suppdeladdress2 != ''">
										 {{podetails.poheader.suppdeladdress2}}<br>
									</span>
									{{podetails.poheader.suppdeladdress3}}, {{podetails.poheader.suppdeladdress4}} {{podetails.poheader.suppdeladdress5}}
								</td>
								<td>
									{{podetails.poheader.deladd1}}<br>
									<span *ngIf="podetails.poheader.deladd2 != ''">{{podetails.poheader.deladd2}}<br></span>
									{{podetails.poheader.deladd3}}, {{podetails.poheader.deladd4}} {{podetails.poheader.deladd5}}
								</td>
							</tr>
						</table>
						<div class="card">
							<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
						</div>
						<table class="table table-sm ">
							<tr>
								<th>ITEM</th>
								<th>DESC</th>
								<th class="text-center">QTY</th>
								<th class="text-center">RCVD</th>
								<th class="text-center">PEND</th>
								<th class="text-right">PRICE</th>
								<th class="text-right">TOTAL</th>
							</tr>
							<tr *ngFor="let item of podetails.polines">
								<td><a [routerLink]="'/inventory/view/'+item.itemcode" (click)="modal.dismiss('route change')">{{item.itemcode}}</a></td>
								<td>{{item.itemdescription}}</td>
								<td class="text-center">{{item.quantityord}}</td>
								<td class="text-center">{{item.quantityrecd}}</td>
								<td class="text-center">{{item.quantityord - item.quantityrecd}}</td>
								<td class="text-right">{{item.unitprice | currency}}</td>
								<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>GRN</th>
							<th>Order#</th>
							<th>Item#</th>
							<th>Description</th>
							<th>Received</th>
							<th>Invoiced</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.receiving">
							<td>{{ r.grnno }}</td>
							<td>{{ r.poorderno }}</td>
							<td>{{ r.itemcode }}</td>
							<td>{{ r.itemdescription }}</td>
							<td>{{ r.qtyrecd }}</td>
							<td>{{ r.quantityinv }}</td>
							<td>{{ r.update_date }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					No Receiving Details
				</ng-container>
			</mat-tab>
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>Item#</th>
							<th>Location</th>
							<th>User</th>
							<th>Quantity</th>
							<th>QOH</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.movements">
							<td>{{ r.stockid }}</td>
							<td>{{ r.loccode }}</td>
							<td>{{ r.userid }}</td>
							<td>{{ r.qty }}</td>
							<td>{{ r.newqoh }}</td>
							<td>{{ r.trandate }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					No Movements
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</ng-container>

	<div class="modal-footer">
	</div>
</ng-template>
<form [formGroup]="itemForm" *ngIf="itemForm" class="mt-3">
	<div class="row p-0 m-0">
		<div class="col-md-3">
			<mat-form-field appearance="outline">
				<mat-label>Warehouse</mat-label>
				<mat-select formControlName="loccode">
					<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
						{{ loc.locationname }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field appearance="outline">
				<mat-label>Type</mat-label>
				<mat-select formControlName="systypes">
					<mat-option value="">All Types</mat-option>
					<mat-option *ngFor="let type of systypes" [value]="type.typeid">
						{{ type.typename }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="outline">
				<mat-label>From</mat-label>
				<input matInput [matDatepicker]="fromdatepicker" formControlName="fromdate">
				<mat-datepicker-toggle matSuffix [for]="fromdatepicker"></mat-datepicker-toggle>
				<mat-datepicker #fromdatepicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="outline">
				<mat-label>To</mat-label>
				<input matInput [matDatepicker]="todatepicker" formControlName="todate">
				<mat-datepicker-toggle matSuffix [for]="todatepicker"></mat-datepicker-toggle>
				<mat-datepicker #todatepicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-md-1 text-right mt-3">
			<div *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
					</circle>
				</svg>
			</div>
			<button mat-icon-button (click)="getMovements()" *ngIf="!sending"><mat-icon>refresh</mat-icon></button>
		</div>
	</div>
	<div class="table-responsive">
		<ng-container *ngIf="results">
			<h5 *ngIf="!results.length && !sending" class="text-center">
				<b>No Movements Found</b>
			</h5>
			<table class="table table-sm table-fixed" *ngIf="results.length">
				<thead>
					<tr>
						<th class="text-left">Item#</th>
						<th class="text-left">Type</th>
						<th class="text-left">Document</th>
						<th class="text-center">Date</th>
						<th class="text-center">UserID</th>
						<th class="text-center">Customer#</th>
						<th class="text-left">Narrative</th>
						<th class="text-center">Quantity</th>
						<th class="text-center">Reference</th>
						<th class="text-left">Price</th>
						<th class="text-center">Discount</th>
						<th class="text-center">NewQty</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let r of results">
						<td class="text-left">{{ r.stockid }}</td>
						<td class="text-left">{{ r.typename }}</td>
						<td class="text-left">
							<a *ngIf="r.type == '10' || r.type == '11' || r.type == '25'" (click)="openDocument(r, r.type, 'html')">
								{{ r.transno }}
							</a>
							<span *ngIf="r.type != '10' && r.type != '11' && r.type != '25'">
								{{ r.transno }}
							</span>
						</td>
						<td class="text-center">{{ r.trandate }}</td>
						<td class="text-center">{{ r.userid }}</td>
						<td class="text-center">
							<a [routerLink]="'/customers/view/' + r.debtorno">{{ r.debtorno }}.{{ r.branchcode }}</a>
						</td>
						<td class="text-left">{{ r.narrative }}</td>
						<td class="text-center">{{ r.qty }}</td>
						<td class="text-center">{{ r.reference }}</td>
						<td class="text-left">{{ r.price | currency }}</td>
						<td class="text-center">{{ r.discountpercent }}</td>
						<td class="text-center">{{ r.newqoh }}</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
	</div>

</form>
