import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { DispatchService } from '../../services/dispatch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'app/services/users.service';


@Component({
	selector: 'app-pos-new-customer',
	templateUrl: './pos-new-customer.component.html',
	styleUrls: ['./pos-new-customer.component.scss']
})
export class PosNewCustomerComponent {
	@ViewChild('stepper', { static: true }) stepper: MatStepper;
	@ViewChild('detailsModal') detailsModalEle: ElementRef;

	debtorFromGroup: UntypedFormGroup;
	branchFormGroup: UntypedFormGroup;
	contactFormGroup: UntypedFormGroup;
	isEditable = false;
	terms: any = [];
	customertypes: any = [];
	holdreasons: any = [];
	currencies: any = [];
	salestypes: any = [];
	zones: any = [];
	taxes: any = [];
	alltaxes: any = [];
	locations: any = [];
	areas: any = [];
	salesman: any = [];
	shipvia: any = [];
	tirefee = true;
	disabledbranch = false;
	customer_created: any = [];
	paymentmethods: any = [];
	phone: any = '';
	place: any = false;
	routes: any = [];
	files: File[] = [];
	//todo make this part of configurations or define company variables on login
	defaults = {
		country: 'United States',
		region: 'NY',
		holdreason: '1',
		currency: 'USD',
		terms: 'COD',
		salestype: 'A',
		customertype: '1',
		discount: 0,
		credit: 0,
		email_invoice: false,
		requirepo: false,
		email_statement: false,
		interest: 3,
		warehouse: '01',
		shipvia: '1',
		area: '01',
		territory: '01',
		salesman: '00',
		disabled: false,
		tirefee: true,
		taxauth: '119',
		branchcode: '00',
		default_paymentmehod: '2',
		cosm_license: '',
		licExpDate: '',
		signed_mor: false,
	};

	color = '';
	config: any = false;
	index = 1;
	duplicatewarning: any;
	modalAction: boolean;
	contacttypes: any = false;
	contacts: any = [];
	@Output() newCustomerCreated = new EventEmitter < any > ();
	newDebtorId: any = false;
	userData: any;

	constructor(public dispatchService: DispatchService, public router: Router, private fb: UntypedFormBuilder, public globalsService: GlobalsService, public customerService: CustomerService, public location: Location, private globalSearchService: GlobalSearchService, private modalService: NgbModal, private userService: UsersService) {
		this.globalSearchService.mesagesallowed.next(false);
	}



	ngOnInit(): void {

		this.userData = this.userService.getLocalUser();

		this.globalSearchService.configsubscription.subscribe(c => {
			this.config = c;
		});

		this.color = this.globalSearchService.getColor();

		this.dispatchService.getRoutes().subscribe((r: any) => {
			this.routes = r
		});

		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxes = results;
			this.alltaxes = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salesman = results;
			this.salesman.forEach(slsmn => {
				if(slsmn.defaultloc != undefined){
					if(JSON.parse(slsmn.defaultloc).includes(this.userData.user.defaultlocation.loccode)){
						this.defaults.salesman = slsmn.salesmancode;
					}
				}
			});

		});

		this.globalSearchService.shipvia.subscribe(async (results: any) => {
			this.shipvia = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.zones.subscribe((results: any) => {
			this.zones = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});

		this.globalSearchService.customertypes.subscribe(details => {
			this.customertypes = details;
		});

		this.globalSearchService.currencies.subscribe(details => {
			this.currencies = details;
		});

		this.globalSearchService.salestypes.subscribe(details => {
			this.salestypes = details;
		});

		this.globalSearchService.locations.subscribe(details => {
			this.locations = details;
		});

		this.globalSearchService.areas.subscribe(details => {
			this.areas = details;
		});

		this.globalsService.getPaymentMethods().subscribe(details => {
			this.paymentmethods = details;
		});

		this.globalsService.getTypes().subscribe(async (results: any) => {
			this.globalSearchService.all_system_types.next(results);
			this.contacttypes = results.contacttypes
		});

		this.setFormDefaults();
	}

	selectCustomer(event: any) {

		this.customerService.getDebtor(event.debtorno).subscribe(async (results: any) => {

			this.debtorFromGroup.get('name').setValue(results.customer.name)
			this.debtorFromGroup.get('address1').setValue(results.customer.address1)
			this.debtorFromGroup.get('address2').setValue(results.customer.address2)
			this.debtorFromGroup.get('address3').setValue(results.customer.address3)
			this.debtorFromGroup.get('address4').setValue(results.customer.address4)
			this.debtorFromGroup.get('address5').setValue(results.customer.address5)

			this.debtorFromGroup.get('creditlimit').setValue(results.customer.creditlimit)
			this.debtorFromGroup.get('customerpoline').setValue(results.customer.customerpoline)

			this.branchFormGroup.get('brname').setValue(results.customer.brname)
			this.branchFormGroup.get('braddress1').setValue(results.customer.braddress1)
			this.branchFormGroup.get('braddress2').setValue(results.customer.braddress2)
			this.branchFormGroup.get('braddress3').setValue(results.customer.braddress3)
			this.branchFormGroup.get('braddress4').setValue(results.customer.braddress4)
			this.branchFormGroup.get('braddress5').setValue(results.customer.braddress5)
			this.branchFormGroup.get('contactname').setValue(results.customer.contactname)

		});


	}

	updateContactName(event: any, note: any) {
		const index = this.contacts.indexOf(note);
		this.contacts[index].name = event.target.value;
	}

	updateContactEmail(event: any, note: any) {
		const index = this.contacts.indexOf(note);
		const isok = this.isEmail(event.target.value);

		this.contacts[index].email = event.target.value;
	}

	updateContactPhone(event: any, note: any) {
		const index = this.contacts.indexOf(note);
		this.contacts[index].phone = event.target.value;
	}

	updateContactRole(event: any, note: any) {
		const index = this.contacts.indexOf(note);
		this.contacts[index].role = event.target.value;
	}

	updateBranch() {


		this.branchFormGroup = this.fb.group({
			branchcode: [{ value: this.defaults.branchcode, disabled: true },
				[Validators.required]
			],
			//debtorno: [''],
			brname: [this.debtorFromGroup.controls.name.value, [Validators.required]],
			contactname: [' ', [Validators.required]],
			braddress1: [this.debtorFromGroup.controls.address1.value, [Validators.required]],
			braddress2: [this.debtorFromGroup.controls.address2.value],
			braddress3: [this.debtorFromGroup.controls.address3.value, [Validators.required]],
			braddress4: [this.debtorFromGroup.controls.address4.value, [Validators.required]],
			braddress5: [this.debtorFromGroup.controls.address5.value, [Validators.required]],
			braddress6: [this.debtorFromGroup.controls.address6.value, [Validators.required]],
			//estdeliverydays: [''],
			phoneno: [this.debtorFromGroup.controls.phoneno.value, [Validators.required]],
			email: [this.debtorFromGroup.controls.email.value, [Validators.email, Validators.required]],
			faxno: [''],
			disabletrans: [this.defaults.disabled],
			tirefee: [this.defaults.tirefee],
			defaultlocation: [this.defaults.warehouse, [Validators.required]],
			defaultshipvia: [this.defaults.shipvia, [Validators.required]],
			area: [this.defaults.area, [Validators.required]],
			salesman: [this.defaults.salesman, [Validators.required]],
			tax_ref: ['', [Validators.minLength(9), Validators.maxLength(20)]],
			taxgroupid: [this.defaults.taxauth, [Validators.required]],
			specialinstructions: [''],
			username: [''],
			password: [''],
			lat: [],
			lng: [],
			bypass: false,
			route: ['', [Validators.required]],
			default_paymentmehod: [this.defaults.default_paymentmehod, [Validators.required]],
			//territory_id: [this.defaults.area, [Validators.required]],
			//pricelevel: [''],

		});

		if (this.place) {
			this.branchFormGroup.get('lat').setValue(this.place.geometry.location.lat())
			this.branchFormGroup.get('lng').setValue(this.place.geometry.location.lng())
		}

		this.branchFormGroup.get('email').valueChanges.subscribe(newValue => {
			const valuetest = this.branchFormGroup.get('username').value;
			if (valuetest != newValue || valuetest == '') {
				this.branchFormGroup.get('username').setValue(newValue);
			}

		})

		this.branchFormGroup.get('braddress5').valueChanges.subscribe(newValue => {
			const data_search = {
				zipcode: newValue
			}
			this.customerService.zipCodeCheck(data_search).subscribe(async (results: any) => {
				if (results && results.length) {
					this.taxes = results;
					this.branchFormGroup.get('taxgroupid').setValue(results[0].taxgroupid)
				} else {
					this.taxes = this.alltaxes;
				}
			});
		});

		if (this.config && this.config.env.package == 'beauty') {
			this.branchFormGroup.get('route').setValidators([])
			this.branchFormGroup.get('email').setValidators([])
			this.branchFormGroup.updateValueAndValidity();

		}

		this.branchFormGroup.get('tax_ref').valueChanges.subscribe(newvalue => {
			if (newvalue != '') {
				let currentValue = this.alltaxes.filter(v => {
					return v.taxgroupid == this.branchFormGroup.get('taxgroupid').value
				})[0];


				if (currentValue) {
					currentValue = currentValue.taxgroupdescription
				} else {
					currentValue = '';
				}

				this.filterTaxAuths(true, currentValue);
			} else {
				this.filterTaxAuths(false, '');
			}
		});


		this.branchFormGroup.markAllAsTouched();
	}

	updateContact() {
		const contactname = this.debtorFromGroup.get('firstname').value.trim() + ' ' + this.debtorFromGroup.get('lastname').value.trim();
		const type_default = this.contacttypes.find(type => type.contact_type === 'AR/Invoices')
		const exists = this.contacts.find(contact => contact.name == contactname && contact.phone == this.debtorFromGroup.get('phoneno').value);

		if (!exists) {
			this.contactFormGroup.get('name').setValue(contactname);
			this.contactFormGroup.get('phone').setValue(this.debtorFromGroup.get('phoneno').value);
			this.contactFormGroup.get('email').setValue(this.debtorFromGroup.get('email').value);
			type_default ? this.contactFormGroup.get('role').setValue([type_default.contact_role]) : this.contactFormGroup.get('role').setValue([])

			this.addContact();
		}
	}

	back(): void {
		this.location.back()
	}

	updateZones(input: any) {

	}

	getAddress(place: any) {
		this.place = place

		const componenets = place['address_components'];
		const name = place['name'];
		const phone = place['formatted_phone_number'];
		const website = place['website'];

		const address1 = this.parseComponentShortName(componenets, ['street_number', 'route']);
		const address2 = this.parseComponentShortName(componenets, ['subpremise']);
		const address3 = this.parseComponentShortName(componenets, ['locality']);
		const address4 = this.parseComponentShortName(componenets, ['administrative_area_level_1']);
		const address5 = this.parseComponentShortName(componenets, ['postal_code']);
		const address6 = this.parseComponentLongName(componenets, ['country']);

		this.debtorFromGroup.get('address1').setValue(address1)
		this.debtorFromGroup.get('address2').setValue(address2)
		this.debtorFromGroup.get('address3').setValue(address3)
		this.debtorFromGroup.get('address4').setValue(address4)
		this.debtorFromGroup.get('address5').setValue(address5)
		this.debtorFromGroup.get('address6').setValue(address6)

		this.branchFormGroup.get('braddress1').setValue(address1)
		this.branchFormGroup.get('braddress2').setValue(address2)
		this.branchFormGroup.get('braddress3').setValue(address3)
		this.branchFormGroup.get('braddress4').setValue(address4)
		this.branchFormGroup.get('braddress5').setValue(address5)
		this.branchFormGroup.get('braddress6').setValue(address6)

		this.branchFormGroup.get('lat').setValue(this.place.geometry.location.lat())
		this.branchFormGroup.get('lng').setValue(this.place.geometry.location.lng())

		if (name) {

			if(name !== address1) {
				this.debtorFromGroup.get('name').setValue(name);
				this.branchFormGroup.get('brname').setValue(name);
			}
		}

		if (phone) {
			this.phone = phone;
			this.branchFormGroup.get('phoneno').setValue(phone)
		}
	}

	addContact() {
		if (this.contactFormGroup.valid) {
			this.contacts.push(this.contactFormGroup.value);
			this.contactFormGroup.reset();
		}
	}

	private parseComponentShortName(componenets: any, types: any) {
		const result = [];
		componenets.forEach((comp: any) => {
			const compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					const adda = (comp.short_name) ? comp.short_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}

	private parseComponentLongName(componenets: any, types: any) {
		const result = [];
		componenets.forEach((comp: any) => {
			const compa = comp.types.map((local: any) => {
				if (types.includes(local)) {
					const adda = (comp.long_name) ? comp.long_name : '';
					result.push(adda);
				}
			});
		})
		return result.join(' ');
	}


	createCustomer() {
		this.getFormValidationErrors(this.branchFormGroup);
		this.getFormValidationErrors(this.debtorFromGroup);
		if (this.branchFormGroup.valid && this.debtorFromGroup.valid) {
			this.customerService.createCustomer(this.debtorFromGroup.getRawValue(), this.branchFormGroup.getRawValue(), this.contacts).subscribe((r: any) => {

				if (r.success) {
					//this sends new customer info to pos-customer-notes and triggers them to save
					this.newDebtorId = r;

					//need to send license for saving with customer id
					this.uploadDocuments(r.customer.debtorno)
					this.customer_created = r.customer;
					this.newCustomerCreated.emit(r.customer);
					this.modalService.dismissAll();
				} else {
					const bypass = confirm(r.message);
					if (bypass) {
						this.branchFormGroup.get('bypass').setValue(true);
						this.customerService.createCustomer(this.debtorFromGroup.value, this.branchFormGroup.value).subscribe((ra: any) => {
							this.customer_created = ra.customer;
							this.newDebtorId = ra;
							this.uploadDocuments(ra.customer.debtorno)
							this.customer_created = ra.customer;
							this.newCustomerCreated.emit(ra.customer);
						});

					}

				}
			});
		}
	}

	getFormValidationErrors(form: any) {
		Object.keys(form.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = form.get(key).errors;

			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					form.markAllAsTouched();
					form.markAsUntouched()
					switch(key){
					case 'firstname':
						key = 'First Name';
						break;
					case 'lastname':
						key = 'Last Name';
						break;
					case 'reference':
						key = 'PO Number'
						break;
					case 'name':
						key = 'Business Name';
						break;
					case 'email':
						key = 'Email';
						keyError = keyError == 'email' ? 'format incorrect' : keyError
						break;
					case 'phoneno':
						key = 'Phone #';
						keyError = 'incomplete'
						break;
					case 'address1':
						key = 'Address1'
						break;
					case 'address3':
						key = 'City'
						break;
					case 'address5':
						key = 'Postal Code'
						keyError = 'must be 5 digits long'
						break;
					case 'licExpDate':
						key = 'Expiration'
						break;
					case 'cosm_license':
						key = 'Licence'
						keyError = keyError == 'minlength'? 'must be at least 3 charcters' : keyError;
						break;
					case 'webusername':
						key = 'web username';
						break;
					case 'tax_ref':
						key = 'Tax Id'
						keyError = 'must be between 9-20 characters'
						break;
					}


					if(key == 'tax_ref'){ //
						// form.get(key).setValidators([Validators.minLength(9),Validators.maxLength(20)]);
					}

					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}

	compareAddress() {
		if (!this.debtorFromGroup.valid) {
			this.getFormValidationErrors(this.debtorFromGroup);
			return;
		}

		const a1 = this.debtorFromGroup.get('address1').value;
		const a2 = this.debtorFromGroup.get('address2').value;
		const a3 = this.debtorFromGroup.get('address3').value;
		const cont = false;
		if (a1 != '' || a2 != '' || a3 != '') {
			const data = { add1: a1, add2: a2, add3: a3 }
			this.customerService.addressCompare(data).subscribe((result) => {
				if (result.length) {
					this.duplicatewarning = result;
					this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', keyboard: false }).result.then((result) => {}, (reason) => {

						if (this.modalAction) {
							this.updateBranch();
							this.step();
							this.modalAction = undefined;
						} else {
							this.modalAction = undefined;
						}
					});
				} else {
					this.updateBranch();
					this.step();
				}
			});

		}

	}

	isMatch(entered, found) {
		if (entered.toLowerCase() == found.toLowerCase()) {
			return true;
		}
		return false;
	}

	userSelection(input: boolean) {
		this.modalAction = input;

		document.getElementById('dupeaddress').click();
	}

	removeContact(contact: any) {
		const index = this.contacts.indexOf(contact);
		this.contacts.splice(index, 1);
	}

	step() {
		document.getElementById('stepperNext').click();
	}

	isEmail(search: string): boolean {

		let serchfind: boolean;

		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

		serchfind = regexp.test(search);

		return serchfind
	}

	filterTaxAuths(filter: any, current: any) {
		if (filter) {
			const results = this.taxes.filter((r: any) => {
				let newvalue = '';
				if (current != '') {
					newvalue = current.replace('1', '2');
				}
				if (r.taxgroupdescription.startsWith('2')) {
					if (newvalue != '' && newvalue == r.taxgroupdescription) {
						this.branchFormGroup.get('taxgroupid').setValue(r.taxgroupid);
						//this.globalSearchService.showNotification('Tax Group Updated to Non Taxable', 'warn', 'bottom', 'right');
					}
					return r;
				}
			})
			this.taxes = results;
		} else {
			this.taxes = this.alltaxes;
		}
	}

	onRemove(event: any) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	onSelect(event: any) {
		this.files.push(...event.addedFiles);
	}

	uploadDocuments(debtorno) {
		if (this.files.length > 0) {
			for (let i = 0; i < this.files.length; i++) {
				const name = this.files[i].name;
				const mime = this.files[i].type;
				this.readFile(this.files[i]).then(fileContents => {
					//stuff
					const upload = {
						content: fileContents,
						name: name,
						mime: mime,
					}

					this.customerService.uploadFile(upload, debtorno).subscribe((results: any) => {

					});

				});
			}
			this.files = [];
		}
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	setFormDefaults() {
		this.contactFormGroup = this.fb.group({
			// contid: [0],
			name: ['', Validators.required],
			email: ['', [Validators.email]],
			phone: [''],
			role: [
				[], Validators.required
			],
		});
		this.debtorFromGroup = this.fb.group({
			name: ['', [Validators.required]],
			firstname: ['', [Validators.required]],
			lastname: ['', [Validators.required]],
			address1: ['', [Validators.required]],
			address2: ['', []],
			address3: ['', [Validators.required]],
			address4: [this.defaults.region, [Validators.required]],
			address5: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern('[0-9]*')]],
			address6: [this.defaults.country, [Validators.required]],
			creditlimit: [this.defaults.credit, [Validators.required]],
			discount: [this.defaults.discount, [Validators.pattern('[0-9]*')]],
			pymtdiscount: [this.defaults.discount, [Validators.pattern('[0-9]*')]],
			arintrate: [this.defaults.interest],
			paymentterms: [this.defaults.terms, [Validators.required]],
			salestype: [this.defaults.salestype, [Validators.required]],
			typeid: [this.defaults.customertype, [Validators.required]],
			holdreason: [this.defaults.holdreason, [Validators.required]],
			currcode: [this.defaults.currency, [Validators.required]],
			emailstatement: [this.defaults.email_statement, [Validators.required]],
			statementemailaddress: ['', [Validators.email]],
			ediinvoices: [this.defaults.email_invoice, [Validators.required]],
			invoiceemailaddress: ['', [Validators.email]],
			customerpoline: [this.defaults.requirepo, [Validators.required]],
			cosm_license: [this.defaults.cosm_license, [Validators.minLength(3)]],
			licExpDate: [this.defaults.licExpDate, []],
			signed_mor: [this.defaults.signed_mor, [Validators.required]],
			webusername: [''],
			webpassword: [''],
			phoneno: [this.phone, [Validators.required]],
			email: ['', [Validators.email]],
		});

		this.debtorFromGroup.get('paymentterms').valueChanges.subscribe(newValue => {
			const termcode = this.terms.filter(t => {
				return t.termsindicator == newValue
			})[0];
			if (termcode.collect_payment && termcode.collect_payment == '1') {
				this.debtorFromGroup.get('creditlimit').setValue('0.00')
			}
		});

		this.debtorFromGroup.get('emailstatement').valueChanges.subscribe((newValue: any) => {
			if (newValue) {
				this.debtorFromGroup.get('statementemailaddress').setValidators([Validators.required])
				this.debtorFromGroup.get('statementemailaddress').updateValueAndValidity();
			} else {
				this.debtorFromGroup.get('statementemailaddress').clearValidators()
				this.debtorFromGroup.get('statementemailaddress').updateValueAndValidity();
			}
		})

		this.debtorFromGroup.get('ediinvoices').valueChanges.subscribe((newValue: any) => {
			if (newValue) {
				this.debtorFromGroup.get('invoiceemailaddress').setValidators([Validators.required])
				this.debtorFromGroup.get('invoiceemailaddress').updateValueAndValidity();
			} else {
				this.debtorFromGroup.get('invoiceemailaddress').clearValidators()
				this.debtorFromGroup.get('invoiceemailaddress').updateValueAndValidity();
			}
		})

		this.debtorFromGroup.get('firstname').valueChanges.subscribe((newValue: any) => {
			if (newValue != '' && this.debtorFromGroup.get('name').value == '') {
				this.debtorFromGroup.get('name').setValue(newValue);
				this.debtorFromGroup.get('name').markAsTouched();
			}
		})

		this.debtorFromGroup.get('lastname').valueChanges.subscribe((newValue: any) => {
			if (newValue != '' && this.debtorFromGroup.get('name').value == '') {
				let firstname = this.debtorFromGroup.get('firstname').value;
				firstname += firstname != '' ? ' ' : '';
				this.debtorFromGroup.get('name').setValue(firstname + newValue);
				this.debtorFromGroup.get('name').markAsTouched();
			}
		})

		this.debtorFromGroup.get('licExpDate').valueChanges.subscribe((newV) => {
			if(this.debtorFromGroup.get('licExpDate').invalid){
				return;
			}else if(new Date(newV) < new Date() ){
				this.debtorFromGroup.get('licExpDate').setErrors({ incorrect: true });
			}
		});



		this.updateBranch();
		//reset files & contacts
		this.contacts = [];

		this.files = [];
		this.debtorFromGroup.get('email').valueChanges.subscribe(newValue => {
			this.debtorFromGroup.get('webusername').setValue(newValue)
		})

	}

	updateNoteEdit(event: any) {

	}

	updateCustomer(event: any) {

	}

}
