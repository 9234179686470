<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title">
							<i class="material-icons">arrow_left</i> Statements
						</h4>
					</div>

					<div class="card-body">
						<!--left-->

						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Statement Messages" class="no-overflow">
								<ng-template matTabContent>
									<div class="row mt-3">
										<div class="col-md-3" *ngFor="let r of statement_text">
											<h5>{{ r.confname }}</h5>
											<angular-editor [init]="editorConfig" (onSelectionChange)="updateValue($event, r)" [config]="editorConfig" [initialValue]="r.confvalue"></angular-editor>
										</div>
									</div>
									<div class="col-12 text-right">
										<button mat-raised-button color="accent" (click)="saveMessages()">Save Message Text</button>
									</div>
								</ng-template>
							</mat-tab>

							<mat-tab label="Print Statements" class="no-overflow">
								<ng-template matTabContent>
									<form [formGroup]="statementForm" class="no-overflow" *ngIf="statementForm">
										<div class="row">
											<div class="col-md-3">
												<mat-form-field appearance="outline">
													<mat-label>Starting Customer (Customer code)</mat-label>
													<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="FromCust" aria-label="Reference" matInput />
												</mat-form-field>
											</div>
											<div class="col-md-3">
												<mat-form-field appearance="outline">
													<mat-label>Ending Customer (Customer code)</mat-label>
													<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="ToCust" aria-label="Reference" matInput />
												</mat-form-field>
											</div>
											<div class="col-md-3">
												<mat-form-field appearance="outline">
													<mat-label>Cut off Date</mat-label>
													<input matInput [value]="today" formControlName="dateto" [matDatepicker]="paydatepicker">
													<mat-datepicker-toggle matSuffix [for]="paydatepicker"></mat-datepicker-toggle>
													<mat-datepicker #paydatepicker></mat-datepicker>
												</mat-form-field>
											</div>

											<div class="col-md-3">
												<mat-form-field appearance="outline">
													<mat-label>Statement Due Date</mat-label>
													<input matInput [value]="today" formControlName="datedue" [matDatepicker]="paydatepickerdatedue">
													<mat-datepicker-toggle matSuffix [for]="paydatepickerdatedue"></mat-datepicker-toggle>
													<mat-datepicker #paydatepickerdatedue></mat-datepicker>
												</mat-form-field>
											</div>
											<div class="col-md-3">
												<mat-form-field appearance="outline">
													<mat-label>Terms</mat-label>
													<mat-select formControlName="paymentterms" multiple>
														<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
															{{ term.terms }}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
											<div class="col-md-3">
												<mat-form-field appearance="outline">
													<mat-label>Statement Types</mat-label>
													<mat-select formControlName="type" multiple>
														<mat-option value="credit">Credit Balance</mat-option>
														<mat-option value="debit">Debit Balance</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
											<div class="col-md-6 mt-3">
												Customers with Print Statement Flag Only <mat-slide-toggle formControlName="printonly">
												</mat-slide-toggle>
											</div>

											<div class="table table-responsive text-center" *ngIf="loading">
												Loading Please Wait...
												<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
													<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
												</svg>
											</div>
											<div class="col-md-12" *ngIf="!loading">
												<button mat-raised-button color="accent" (click)="printStatements()">Print Statements</button>
											</div>
										</div>
									</form>
								</ng-template>
							</mat-tab>
							<mat-tab label="Email Statements" class="no-overflow" *ngIf="statementEmailForm">

								<table *ngIf="jobs && jobs.length > 0">
									<thead>
										<tr>
											<th>Scheduled</th>
											<th>Customer Range</th>
											<th>Cut Off Date</th>
											<th>Records Processed</th>
											<th>Last Record | Email</th>
											<th>Status</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let job of jobs">
											<td>{{ job.scheduled }}</td>
											<td>{{ job.customer_range }}</td>
											<td>{{ job.cut_off_date }}</td>
											<td>{{ job.records_processed }}</td>
											<td>{{ job.last_record_email }}</td>
											<td>{{ job.status }}</td>
											<td><button mat-icon-button color="warn" aria-label="Remove" (click)="removeJob(job.job_id)">
													<mat-icon>remove</mat-icon>
												</button></td>
										</tr>
									</tbody>
								</table>
								<p *ngIf="jobs.length === 0">No Jobs Pending</p>


								<form [formGroup]="statementEmailForm" (ngSubmit)="scheduleEmail()">
									<input type="hidden" formControlName="schedule" value="true">
									<input type="hidden" formControlName="script" value="autosender.php">
									<mat-card>
										<mat-card-content>
											<div class="row">
												<div class="col-6">
													<mat-form-field appearance="outline">
														<input matInput [matDatepicker]="cutoffDatePicker" formControlName="dateto" maxlength="10" size="10" placeholder="Date To">
														<mat-datepicker-toggle matSuffix [for]="cutoffDatePicker"></mat-datepicker-toggle>
														<mat-datepicker #cutoffDatePicker></mat-datepicker>
													</mat-form-field>
												</div>

												<div class="col-6">
													<mat-form-field appearance="outline">
														<input matInput [matDatepicker]="schedulerDatePicker" formControlName="datedue" maxlength="10" size="10" placeholder="Date Due">
														<mat-datepicker-toggle matSuffix [for]="schedulerDatePicker"></mat-datepicker-toggle>
														<mat-datepicker #schedulerDatePicker></mat-datepicker>
													</mat-form-field>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<mat-form-field appearance="outline">
														<input matInput type="text" maxlength="10" size="8" formControlName="FromCust" placeholder="Starting Customer (Customer code)">
													</mat-form-field>
												</div>

												<div class="col-6">
													<mat-form-field appearance="outline">
														<mat-select matInput formControlName="job_schedule_time">
															<mat-option *ngFor="let hour of hours" [value]="hour.value">{{hour.label}}</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<mat-form-field appearance="outline">
														<input matInput type="text" maxlength="10" size="8" formControlName="ToCust" placeholder="Ending Customer (Customer code)">
													</mat-form-field>
												</div>

												<div class="col-6">
													<mat-form-field appearance="outline">
														<mat-select matInput formControlName="job_schedule_time_min">
															<mat-option *ngFor="let min of minutes" [value]="min.value">{{min.label}}</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-12">
													<mat-form-field appearance="outline">
														<mat-label>Terms</mat-label>
														<mat-select formControlName="paymentterms" multiple>
															<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
																{{ term.terms }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
											</div>
										</mat-card-content>

										<mat-card-actions>
											<button mat-raised-button color="accent" type="submit">Schedule Email</button>
										</mat-card-actions>
									</mat-card>

								</form>
							</mat-tab>

						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
