import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';
import { ReportsService } from '../../services/reports.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-reports-pmm',
	templateUrl: './reports-pmm.component.html',
	styleUrls: ['./reports-pmm.component.scss']
})
export class ReportsPmmComponent implements OnInit {
	@ViewChild('print_table') printTable: ElementRef;
	form: FormGroup;
	dataSource = new MatTableDataSource < any > ([]);
	excelLink: string;
	pdfLink: string;
	sending = false;
	title = 'PMM';
	filename = 'PMM';
	perks = [];
	salesman = [];
	color: string;

	constructor(
		private printService: PrintService,
		private reportsService: ReportsService,
		private fb: FormBuilder,
		private globalSearchService: GlobalSearchService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private modalService: NgbModal
	) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.initializeForm();

		const now = new Date();
		const firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);

		this.form.patchValue({
			datestart: firstDayOfLastMonth,
			dateend: lastDayOfLastMonth
		});

		this.loadPerks();
		this.loadSalespeople();
	}

	initializeForm(): void {
		this.form = this.fb.group({
			routecode: ['All'],
			salesrepcode: ['All'],
			datestart: [''],
			dateend: ['']
		});
	}

	loadPerks(): void {
		this.reportsService.getJpmsPerks({}).subscribe((data: any) => {
			this.perks = data.perks;
		});
	}

	loadSalespeople(): void {
		this.globalSearchService.salespeople.subscribe((results: any) => {
			this.salesman = results;
		});
	}

	onSubmit(): void {
		this.sending = true;
		this.reportsService.getPmm(this.form.value).subscribe((data: any) => {
			this.dataSource.data = data;
			this.sending = false;
		});
	}

	parseFinancial(value: string): number {
		return parseFloat(value) || 0;
	}

	back(): void {
		this.location.back();
	}

	exportXls(): void {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printTable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: `${this.filename}${today.getMonth() + 1}${today.getDate()}${today.getFullYear()}`,
			title: this.title,
			subtitle: ` created ${today.toLocaleString()}`
		};
		this.sending = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	exportPdf(): void {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printTable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: `${this.filename}${today.getMonth() + 1}${today.getDate()}${today.getFullYear()}`,
			title: this.title,
			subtitle: ` created ${today.toLocaleString()}`
		};
		this.sending = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	trackById(index: number, item: any): number {
		return item.id;
	}

	trackByName(index: number, item: any): string {
		return item.salesmanname;
	}
}
