<div class="main-content">
    <div class="container-fluid">
        <div class="col-md-12 card">
            <div class="card-header  card-header-{{color}}-5"><h4>Bay Pritority Maintenance</h4></div>
            <div class="card-body text-center">
                <mat-spinner *ngIf="!bays" color="accent" diameter="60"> </mat-spinner>
                <div class="row w-100 justify-content-center" *ngIf="bays">
                    <div cdkDropListGroup class="row w-100 justify-content-center">
                        <ng-container *ngFor="let bay of bays | keyvalue">
                            <div class="col-3 m-2 card bay">
                                <div class="col-12 m-0 p-0 bay-header"><h3>Bay {{formatKey(bay.key)}}</h3></div>
                                <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="bay.value">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="text-center">New#</th>
                                                <th class="text-center">Truck</th>
                                                <th class="text-center">Disp#</th>
                                                <th class="text-center">Current#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="bay-row" *ngFor="let disp of bay.value; let i = index" cdkDrag [cdkDragDisabled]="disp.active == 1" [ngClass]="disp.active == 1 ? 'disabled':''">
                                                <td cdkDragHandle><mat-icon>drag_indicator</mat-icon></td>
                                                <td class="text-capitalize text-center">{{i}}</td>
                                                <td class="text-capitalize text-center">{{disp.truck_name}}</td>
                                                <td class="text-capitalize text-center" (click)="openDisp()">{{disp.dispnumber}}</td>
                                                <td class="text-capitalize text-center">{{disp.priority}}</td>
                                                <div *cdkDragPreview class="custom-drag mat-elevation-z8">
                                                    <div>
                                                        {{disp.truck_name}}
                                                        &nbsp;<mat-icon class="align-middle">local_shipping</mat-icon>
                                                        &nbsp;&nbsp;#{{disp.dispnumber}}
                                                    </div>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <button class="m-1" mat-raised-button color="accent" (click)="reset()" [disabled]="!changesMade">Reset</button>
                <button class="m-1" mat-raised-button color="primary" (click)="saveEdits()" [disabled]="!changesMade">Save Edits?</button>
            </div>
        </div>
    </div>
</div>