import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';
import { ReportsService } from '../../services/reports.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Component({
	selector: 'app-jpms-report',
	templateUrl: './jpms-report.component.html',
	styleUrls: ['./jpms-report.component.scss']
})
export class JpmsReportComponent {
	color = '';
	@ViewChild('print_table') printtable: ElementRef;
	form: FormGroup;
	dataSource = new MatTableDataSource([]);
	excelLink: string;
	pdfLink: string;
	sending: boolean = false;
	title: string = 'JPMS';
	filename: string = 'JPMS';

	displayedColumns: string[] = [
		'customer', 'perkprogram', 'customername', 'customeraddress', 'city', 'state', 'zipcode',
		'awapuhi', 'brx', 'cxg', 'cvx', 'jcb', 'mit', 'mvr', 'color', 'colorstuff', 'demi', 'pmitchell',
		'expresstools', 'neurowetline', 'pntline', 'pspline', 'xgcolor', 'ttc', 'tthmp', 'total'
	];


	perks = [

	];

	constructor(private printService: PrintService, private reportsService: ReportsService, private fb: FormBuilder, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, private location: Location, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.form = this.fb.group({
			perks: [
				[]
			],
			allPerks: [false]
		});

		this.reportsService.getJpmsPerks({}).subscribe((data: any) => {
			this.perks = data.perks;
			this.perks.forEach(perk => {
				this.form.addControl('perk_' + perk.clubcode, this.fb.control(false));
			});
			this.form.patchValue({ FormID: data.formID });
		});
	}

	onSubmit(): void {
		this.sending = true;
		this.reportsService.getJpms(this.form.value).subscribe((data: any) => {
			this.dataSource.data = data.reportData;
			this.sending = false;
		});
	}

	calculateLineTotal(element: any): number {
		return this.parseFinancial(element.awapuhi) +
			this.parseFinancial(element.brx) +
			this.parseFinancial(element.cxg) +
			this.parseFinancial(element.cvx) +
			this.parseFinancial(element.jcb) +
			this.parseFinancial(element.mit) +
			this.parseFinancial(element.mvr) +
			this.parseFinancial(element.color) +
			this.parseFinancial(element.colorstuff) +
			this.parseFinancial(element.demi) +
			this.parseFinancial(element.pmitchell) +
			this.parseFinancial(element.expresstools) +
			this.parseFinancial(element.neurowetline) +
			this.parseFinancial(element.pntline) +
			this.parseFinancial(element.pspline) +
			this.parseFinancial(element.xgcolor) +
			this.parseFinancial(element.ttc) +
			this.parseFinancial(element.tthmp);
	}

	parseFinancial(value: string): number {
		return parseFloat(value) || 0;
	}

	back(): void {
		this.location.back()
	}

	exportXls() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate() + today.getFullYear(),
			title: this.title,
			subtitle: ' created ' + today.toLocaleString()
		}
		this.sending = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate() + today.getFullYear(),
			title: this.title,
			subtitle: ' created ' + today.toLocaleString()
		}
		this.sending = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

}
