import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class GlobalsService {

	key = 'UET6DFMPAXW7BKCB';
	config: any;
	color = 'blue';
	user: any = [];
	public localStorageKey: string = 'v8ConfigOverride';
	private defaultConfig: string = `assets/config/config.${environment.name}.json`;

	constructor(private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.loadConfig();
		//this._configSvc.getConfig()
		const user = JSON.parse(localStorage.getItem('user'));
		if (user !== null && user) {
			this.user = user;
		}
	}

	private loadConfig() {
		const localConfig = localStorage.getItem(this.localStorageKey);

		if (localConfig) {
			try {
				const config = JSON.parse(localConfig);
				if (this.isValidConfig(config)) {
					this.config = config;
					//console.log('Using local storage config:', config);
				} else {
					//console.warn('Local storage config is invalid, falling back to server config.');
					this.loadConfigFromServer();
				}
			} catch (error) {
				//console.error('Failed to parse local storage config, falling back to server config:', error);
				this.loadConfigFromServer();
			}
		} else {
			//console.warn('No local storage config found, loading from server.');
			this.loadConfigFromServer();
			//failover for now
			if(!this.config) {
				this.config = this._configSvc.getConfig();
			}
		}
	}

	private loadConfigFromServer() {
		const jsonFile = `assets/config/config.${environment.name}.json`;
		this.http.get(jsonFile).toPromise().then(config => {
			if (this.isValidConfig(config)) {
				this.config = config;
			//	console.log('Using server config:', config);
				try {
					//localStorage.setItem(this.localStorageKey, JSON.stringify(config));
				} catch (e) {
					//console.error('Failed to save config to local storage:', e);
				}
			} else {
				//console.error('Server config is invalid.');
			}
		}).catch(error => {
			//console.error('Failed to load config from server:', error);
		});
	}

	private isValidConfig(config: any): boolean {
		//console.log('Validating configuration:', config);

		if (!config) {
			//console.error('Configuration is null or undefined.');
			return false;
		}
		if (typeof config.apiCredentials.clientId !== 'string') {
			//console.error('Invalid or missing "clientId":', config.clientId);
			return false;
		}
		if (!config.env || typeof config.env.package !== 'string') {
			//console.error('Invalid or missing "env.package":', config.env);
			return false;
		}
		return true;
	}

	public updateConfigFile(newConfig: any) {
		this.config = newConfig;
		try {
			localStorage.setItem(this.localStorageKey, JSON.stringify(newConfig));
		} catch (e) {
			//console.error('Failed to update config in local storage:', e);
		}
	}
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public setColor(value: any): string {
		localStorage.setItem('color', JSON.stringify(value))
		this.color = value;
		return value;
	}

	public getColor(): string {
		let color = JSON.parse(localStorage.getItem('color'));
		if (color === null) {
			color = 'blue';
			localStorage.setItem('color', JSON.stringify('blue'))
		}
		this.color = color;
		return color;
	}

	public getApiConfig() {
		this.config.color = this.getColor();
		return this.config;
	}
	public getItemBarcodes(stockid: any) {
		const method = 'inventory/barcodes&stockid=' + stockid;
		return this.http.get(this.setEndPoint(method));
	}

	public getItemSetup() {
		const method = 'inventory/properties';
		return this.http.get(this.setEndPoint(method));
	}
	public getInvoice(id: any, display: any) {
		const method = 'documents/invoice&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}
	public getTransfer(id: any, display: any) {
		const method = 'documents/transfer&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}
	public getVendorInvoice(id: any, display: any) {
		const method = 'documents/vendorinvoice&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}

	public getPOSReceipt(id: any, display: any) {
		const method = 'documents/receipt/posreceiptorder&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}

	public emailInvoice(id: any, display: any, email: any) {
		const method = 'documents/invoice&id=' + id + '&display=' + display + '&email=' + email;
		return this.http.get(this.setEndPoint(method));
	}

	public emailQuoteInvoice(id: any, email: any, display: any) {
		const method = `documents/quote&id=${id}&email=${email}&display=${display}`;
		return this.http.get(this.setEndPoint(method));
	}

	public getQuoteInvoice(id: any, display: any) {
		const method = `documents/quote&id=${id}&display=${display}`;
		return this.http.get(this.setEndPoint(method));
	}

	public removeSalesType(data: any) {
		const method = 'globals/types/salestyperemove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removeCustomerType(data: any) {
		const method = 'globals/types/customertyperemove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}


	public saveShipVia(data: any) {
		const method = 'globals/shipvia/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removeShiper(data: any) {
		const method = 'globals/shipvia/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removePaymentTerm(data: any) {
		const method = 'globals/terms/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public savePaymentTerm(data: any) {
		const method = 'globals/terms/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public savePaymentType(data: any) {
		const method = 'globals/types/paymentmethodupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removePaymentType(data: any) {
		const method = 'globals/types/paymentmethodremove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public saveCustomerType(data: any) {
		const method = 'globals/types/customertypeupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public saveVendorType(data: any) {
		const method = 'globals/types/vendortypeupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public removeVendorType(data: any) {
		const method = 'globals/types/vendortyperemove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public saveSalesType(data: any) {
		const method = 'globals/types/salestypeupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getCreditNote(id: any, display: any) {
		const method = 'documents/credits&id=' + id + '&display=' + display;
		return this.http.get(this.setEndPoint(method));
	}

	public emailReceipt(id: any, display: any, email: any) {
		const method = 'documents/receipt&id=' + id + '&display=' + display + '&email=' + email;
		return this.http.get(this.setEndPoint(method));
	}

	public emailCreditNote(id: any, display: any, email: any) {
		const method = 'documents/credits&id=' + id + '&display=' + display + '&email=' + email;
		return this.http.get(this.setEndPoint(method));
	}

	public getHowPaid(transno: any, type: any) {
		const method = 'payments/allocations/view&id=' + transno + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getSupplierHowPaid(transno: any, type: any) {
		const method = 'payments/allocations/purchaseview&id=' + transno + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getTransactionGl(transno: any, type: any) {
		const method = 'globals/glaccounts/transaction&transno=' + transno + '&type=' + type;
		return this.http.get(this.setEndPoint(method));
	}

	public getAllocations(id: any) {
		const method = 'payments/allocations/view&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getVendoorAllocations(id: any) {
		const method = 'vendor/allocations/view&id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public dealllocateDocument(data: any) {
		const method = 'globals/allocations/deallocatesupptrans';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getDataSets() {
		const method = 'globals/datasets';
		return this.http.get(this.setEndPoint(method));
	}

	public getPaymentMethods() {
		const method = 'globals/paymentmethods';
		return this.http.get(this.setEndPoint(method));
	}
	public getSettings() {
		const method = 'globals/settings/setting';
		return this.http.get(this.setEndPoint(method));
	}

	public getCompany() {
		const method = 'globals/settings/company';
		return this.http.get(this.setEndPoint(method));
	}

	public getConfigKeyPairs() {
		const method = 'globals/settings/configkeypair';
		return this.http.get(this.setEndPoint(method));
	}

	public getConfigKeyPairsWithType() {
		const method = 'globals/settings/configkeypairwithtype';
		return this.http.get(this.setEndPoint(method));
	}



	public getStores() {
		const method = 'globals/stores';
		return this.http.get(this.setEndPoint(method));
	}
	public saveStore(data: any) {
		const method = 'globals/stores/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public getSalesManagers() {
		const method = 'globals/salesman/getsalesmanmanagers';
		return this.http.get(this.setEndPoint(method));
	}

	public getSalesPerson(data: any) {
		const method = 'globals/salesman/getsalesman';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getSalesPeople() {
		const method = 'globals/salesman/fulldetails';
		return this.http.get(this.setEndPoint(method));
	}

	public getActiveSalesPeople() {
		const method = 'globals/salesman/getactivesalesman';
		return this.http.get(this.setEndPoint(method));
	}


	public removeSalesPerson(data: any) {
		const method = 'globals/salesman/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public saveSalesPerson(data: any) {
		const method = 'globals/salesman/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getDregPayments(data) {
		const method = 'dreg/payments';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public postDreg(dreg) {
		const method = 'dreg/postdreg';
		return this.http.post(this.setEndPoint(method), { dregdata: dreg }, this.httpOptions).pipe(
			catchError(this.handleError(method, dreg))
		);
	}
	public getDailyARTotals(data) {
		const method = 'globals/dayend';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public postDailyARTotals(data) {
		const method = 'globals/dayend/submit';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getRSI(data) {
		const method = 'globals/dayend/rsi';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateLocation(data) {
		const method = 'globals/locations/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public removeLocation(data) {
		const method = 'globals/locations/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('locations', data))
		);
	}
	public updateCompany(data) {
		const method = 'globals/settings/updatecompany'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public updateConfig(data) {
		const method = 'globals/settings/updateconfig'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('config', data))
		);
	}

	public addConfig(data) {
		const method = 'globals/settings/addconfig'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('config', data))
		);
	}

	public getConfig() {
		const method = 'globals/settings/config';
		return this.http.get(this.setEndPoint(method));
	}

	public getTypes() {
		const method = 'globals/types';
		return this.http.get(this.setEndPoint(method));
	}

	public getHoldReasons() {
		const method = 'globals/types/holdreasons';
		return this.http.get(this.setEndPoint(method));
	}

	public getCountryZoness(id: any) {
		const method = 'globals/zones/country&country_id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getCountryZones(id: any) {
		const method = 'globals/zones/country&country_id=' + id;
		return this.http.get(this.setEndPoint(method));
	}

	public getDefaultZones() {
		const method = 'globals/zones/defaultzone';
		return this.http.get(this.setEndPoint(method));
	}

	public getCustomerTypes() {
		const method = 'globals/types/customer';
		return this.http.get(this.setEndPoint(method));
	}

	public getSalesTypes() {
		const method = 'globals/types/sales';
		return this.http.get(this.setEndPoint(method));
	}

	public getShipVia() {
		const method = 'globals/shipvia';
		return this.http.get(this.setEndPoint(method));
	}

	public getSalesman() {
		const method = 'globals/salesman';
		return this.http.get(this.setEndPoint(method));
	}

	public getAreas() {
		const method = 'globals/areas';
		return this.http.get(this.setEndPoint(method));
	}

	public getLocations() {

		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			///return false;
		} else {
			this.user = user;
		}

		const addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		const method = 'globals/locations&userid=' + addon;
		return this.http.get(this.setEndPoint(method));
	}

	public getUserLocations() {

		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			///return false;
		} else {
			this.user = user;
		}

		const addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		const method = 'globals/locations&userid=' + addon;
		return this.http.get(this.setEndPoint(method));
	}

	public getAllLocations() {
		const method = 'globals/locations/all';
		return this.http.get(this.setEndPoint(method));
	}

	public getLocation(data) {
		const method = 'globals/locations/get';
		return this.http.post(this.setEndPoint(method), { loccode: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getBankAccounts() {
		const method = 'globals/bankaccounts';
		return this.http.get(this.setEndPoint(method));
	}

	public getBankAccount(account: any) {
		const method = 'globals/bankaccounts&account=' + account;
		return this.http.get(this.setEndPoint(method));
	}

	public deleteBankAccount(account: any) {
		const method = 'globals/bankaccounts/remove&account=' + account;
		return this.http.get(this.setEndPoint(method));
	}

	public saveBankAccount(data: any) {
		const method = 'globals/bankaccounts/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getAccountSections() {
		const method = 'globals/glaccounts/sections';
		return this.http.get(this.setEndPoint(method));
	}
	public getAccountSection(data) {
		const method = 'globals/glaccounts/sections';
		return this.http.post(this.setEndPoint(method), { sectionid: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateSection(data) {
		const method = 'globals/glaccounts/sectionupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteSection(data) {
		const method = 'globals/glaccounts/sectiondelete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getAccountGroups() {
		const method = 'globals/glaccounts/groups';
		return this.http.get(this.setEndPoint(method));
	}
	public getAccountGroup(data) {
		const method = 'globals/glaccounts/groups';
		return this.http.post(this.setEndPoint(method), { groupname: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateGroup(data) {
		const method = 'globals/glaccounts/groupupdate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteGroup(data) {
		const method = 'globals/glaccounts/groupdelete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getGLAccounts() {
		const method = 'globals/glaccounts';
		return this.http.get(this.setEndPoint(method));
	}
	public getGLAccount(data) {
		const method = 'globals/glaccounts';
		return this.http.post(this.setEndPoint(method), { accountcode: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateGLAccount(data) {
		const method = 'globals/glaccounts/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteGLAccount(data) {
		const method = 'globals/glaccounts/gldelete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}

	public getProdLineMarkups(data) {
		const method = 'globals/inventory/getprodlinemarkup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public saveProdLineMarkup(data) {
		const method = 'globals/inventory/saveprodlinemarkup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public deleteProdLineMarkup(data) {
		const method = 'globals/inventory/removeprodlinemarkup';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getTransAllocations(data) {

		const method = 'globals/allocations/transallocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getAllocationTrans(data: any) {
		const method = 'globals/allocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getAllocationData(data) {
		const method = 'globals/allocations/transallocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public addAllocation(data) {
		const method = 'globals/allocations/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeAllocation(data) {
		const method = 'globals/allocations/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getTerms() {
		const method = 'globals/terms';
		return this.http.get(this.setEndPoint(method));
	}

	public getTaxes() {
		const method = 'globals/taxes';
		return this.http.get(this.setEndPoint(method));
	}
	public getRemoteAPISettings() {
		const method = 'globals/rapi';
		return this.http.get(this.setEndPoint(method));
	}
	public getRemoteAPI(data) {
		const method = 'globals/rapi/fetch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getRemoteMap(data) {
		const method = 'globals/rapi/fetchmap';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getRemoteEx(data) {
		const method = 'globals/rapi/fetchex';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public saveRemote(data) {
		const method = 'globals/rapi/save';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public delRemote(data) {
		const method = 'globals/rapi/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getCurrencies() {
		const method = 'globals/currancy';
		return this.http.get(this.setEndPoint(method));
	}

	public getTerm(code: any) {
		const method = 'inventory/history';
		return this.http.get(this.setEndPoint(method));
	}

	public lookup(data) {
		const method = 'inventory'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey() + this.getPackage();
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getPackage() {
		const p = localStorage.getItem('app')
		if (p) {
			return '&package=' + p;
		} else {
			return '';
		}
	}

	private getApiKey() {
		const addon = (this.user.user) ? '&userid=' + this.user.user.userid : '';
		return '&key=' + this.config.apiCredentials.clientId + addon;
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	isEmail(search: string): boolean {
		let serchfind: boolean;
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}

	private handleError < T > (operation = 'operation', result ? : T) {

		return (error: any): Observable < T > => {
			if (error instanceof HttpErrorResponse && error.status === 401) {
				// Redirect to login page using window.navigate
				window.location.href = '/#/auth/login';
			}

			return error;
		};
	}
}
