<ng-template #changeTruckEle let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title"></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="fill">
					<mat-label>From</mat-label>
					<mat-select [formControl]="fromTruckOms">
						<mat-option *ngFor="let t of allsortcolumns" [value]="t.id">
							{{t.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-1">
				<mat-icon>arrow_forward</mat-icon>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="fill">
					<mat-label>To</mat-label>
					<mat-select [formControl]="toTruckOms">
						<mat-option *ngFor="let t of allsortcolumns" [value]="t.id">
							{{t.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-1">
				<button mat-raised-button mat-icon-button color="accent" class="mt-3" (click)="transferOmsTo()">
					<mat-icon>save</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #driverChange let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Assign Driver</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<form [formGroup]="driverForm">
			<div class="row">
				<div class="col-6">
					<mat-form-field appearance="outline" class="">
						<mat-label>Driver</mat-label>
						<mat-select formControlName="driver">
							<mat-option [value]="''">No Driver</mat-option>
							<mat-option *ngFor="let k of drivers" [value]="k">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-2 mt-3">
					<button mat-stroked-button color="accent" aria-label="" (click)="saveDriver()" class="mt-2">
						<mat-icon>save</mat-icon> Save
					</button>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #addDestination let-modal class="dragmodal" >

		<div class="modal-header">
			<h4 class="modal-title" id="modal-title">Add Custom Destination</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
		</div>
		<div class="modal-body text-dark">

			<app-address-lookup (setAddress)="getAddress($event)" adressType="address"></app-address-lookup>
			<form [formGroup]="customDestination">
				<mat-form-field appearance="outline">
					<mat-label>Label</mat-label>
					<input matInput formControlName="label">
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Address</mat-label>
					<input matInput formControlName="address">
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-select formControlName="type">
						<mat-option [value]="'4'">Custom Point</mat-option>
						<mat-option [value]="'3'">End</mat-option>
					</mat-select>
				</mat-form-field>
			</form>
			<button mat-raised-button color="accent" (click)="addCustomDestination()">Add Destination</button>
		</div>

</ng-template>

<ng-template #viewActive let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Active Dispatch</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body m-0 p-0 text-dark">

	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="row border-bottom">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>
			<div class="row mt-2 mb-2 border-bottom">
				<div class="col-md-3 ">
					<h5 class="mb-0">Ship To:</h5>
					<span [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0">Phone:</h5>
					{{orderdetails.header.contactphone}}
					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
						<h5 class="mb-0">Email:</h5>
						{{orderdetails.header.contactemail}}
					</ng-container>
				</div>
				<div class="col-md-3">
					<h5>Notes:</h5>
					{{ noNewLines(orderdetails.header.comments) }}
				</div>
				<div class="col-md-3">
					<h5>History: </h5>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul>
							<li *ngIf="orderdetails.dispatch_details.truck_name">ShipVia: {{ orderdetails.dispatch_details.truck_name }}</li>
							<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
								{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
								{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

							</li>
							<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
								{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
								{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">QOH</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">
						<td><a [routerLink]="'/inventory/view/'+item.stkcode" (click)="modal.dismiss('route change')">
							{{item.stkcode}}</a>
							</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-center">{{item.qoh}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<ng-template #assignBay let-modal class="dragmodal">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Assign Loading Bay</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark row">
		<div class="col-12 m-0 p-0 row" *ngIf="bays && bayGroup">
			<ng-container *ngFor="let bay of bays | keyvalue">
				<div class="col-4 m-0 text-center baydiv">
					<h3 class="m-0 ">Bay {{removeBaySort(bay.key)}}</h3>
					<table class="table">
						<ng-container *ngIf="bay.value && bay.value[0]">
							<thead>
								<tr class="col-12 text-center">
									<th class="col-4">Truck</th>
									<th class="col-4">ID</th>
									<th class="col-4">Priority</th>
								</tr>
							</thead>
							<ng-container *ngFor="let disp of bay.value">
								<tr *ngIf="disp.truckid" class="col-12 text-center">
									<td class="col-4">{{disp['truckid']}}</td>
									<td class="col-4">{{disp['dispnumber']}}</td>
									<td class="col-4">{{disp['priority']}}</td>
								</tr>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!bay.value[0]">
							<h4 class="fst-italic">Empty</h4>
						</ng-container>
					</table>
				</div>
			</ng-container>

			<hr class="w-100">
			<form [formGroup]="bayGroup">
				<div class="row m-auto pl-2">
					<h4>Force next?</h4>
					<mat-radio-group formControlName="priority" class="bayradio" [value]="0">
						<mat-radio-button value="1">yes</mat-radio-button>
						<mat-radio-button value="0">No</mat-radio-button>
					</mat-radio-group>
				</div>
			</form>
			<hr class="w-100">
			<div class="col-12 p-0 m-0 row">
				<div class="col-4 p-0 m-0 d-flex justify-content-center"><button mat-raised-button color="primary" class="m-auto w-75" (click)="CloseAssignBay(1)">Assign One</button></div>
				<div class="col-4 p-0 m-0 d-flex justify-content-center"><button mat-raised-button color="primary" class="m-auto w-75" (click)="CloseAssignBay(2)">Assign Two</button></div>
				<div class="col-4 p-0 m-0 d-flex justify-content-center"><button mat-raised-button color="primary" class="m-auto w-75" (click)="CloseAssignBay(3)">Assign Three</button></div>
			</div>
		</div>
	</div>
</ng-template>

<div class="modal" tabindex="-1" role="dialog" id="runList">
	<div class="modal-dialog modal-xl" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" *ngIf="active_run">Route List: {{ active_run.name }} x {{ totalonrun  }} </h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" *ngIf="runlist">
				<div *ngFor="let da of run_list">
					<div class="row ">
						<div class="col-6 mr-0 pr-0" [innerHTML]="da.address">
						</div>
						<div class="col-6 m-0 p-0">
							{{ da.sort + 1 }}
							<ul class="entry-list m-0 p-0">
								<li>
									<div class="spec-lable">Order#</div>
									<div class="spec-value">{{ da.orderno }}</div>
								</li>
								<li>
									<div class="spec-lable">Customer#</div>
									<div class="spec-value">{{ da.customer }}</div>
								</li>
								<li>
									<div class="spec-lable">Terms</div>
									<div class="spec-value">{{ da.customer }}</div>
								</li>
							</ul>
						</div>
					</div>
					<mat-divider></mat-divider>
				</div>

				<div class="row border-bottom pickitem" *ngFor="let item of runlist">
					<div class="col-10 text-left">
						<b>DESCRIPTION:</b> {{ item.description }}<br>
					</div>
					<div class="col-2 text-right">
						QTY {{ item.ordered }} | QOH {{ item.qoh }}
					</div>
					<div class="offset-lg-1 col-6 text-left text-nowrap">
						ITEM: <b>{{ item.stockid }}</b>
						<br />
						ORDER: <span class="text-primary"><b>{{ item.orderno }}</b></span>
					</div>
					<div class="col-5 text-right">
						<h6>BIN: <span class="text-primary"><b>{{ item.bin }}</b></span></h6>
						<h6 class="text-primary">{{ item.deliverydate | dateAgo | uppercase  }}</h6>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn  d-none">Save changes</button>
				<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

<!-- Modal content -->
<ng-template #changesEle let-modal>
  <div class="modal-header">
	<h4 class="modal-title">Order Changes</h4>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
	  <span aria-hidden="true">&times;</span>
	</button>
  </div>
  <div class="modal-body" [@flyInOut]="'in'">
	<div class="changes-container">
	  <div *ngFor="let change of pickChanges" class="change-item">
		<span>{{ change }}</span>
		&nbsp;&nbsp;&nbsp;<button type="button" class="ml-auto mr-0btn btn-danger btn-sm" (click)="removeChange(change)">Remove</button>
	  </div>
	</div>
  </div>
</ng-template>

<div class="main-content">

	<div class="row m-0">
		<div class="col-md-6">
			<div class="ml-0 mr-auto " *ngIf="pickChanges.length">
				<button mat-icon-button (click)="openModal()">
					<mat-icon matBadge="{{ pickChanges.length}} "  matBadgeColor="accent" matBadgeSize="small"  >notifications_active</mat-icon>
				</button>
			</div>
		</div>
		<div class="col-md-6">
			<div class="row m-0 p-0 legend">


				<div class="ml-auto col-auto text-center">
					<div class="ml-auto mr-auto legend-block inbound "> </div>
					Inbound
				</div>
				<!-- <div class="ml-auto col-auto text-center">
					<div class="ml-auto mr-auto legend-block claimed "> </div>
					Claimed
				</div> -->
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block notfillable"> </div>
					Not Fillable
				</div>
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block picked"> </div>
					Picked
				</div>
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block credit"> </div>
					Credit
				</div>
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block partialpick"> </div>
					Partial Pick
				</div>
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block notdue"> </div>
					Not Due
				</div>
				<!-- <div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block futurepicked"> </div>
					Not Due & Picked
				</div> -->
			</div>
		</div>
	</div>

	<div class="container-fluid tp-box" [@flipState]="flip">
		<div class="backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<div class="row m-0 p-0 ">
						<div class="col-9 text-left">
							<h4 class="card-title ">
								<i class="material-icons" (click)="toggleFlip(selectedTruck.id);">arrow_left</i> Dispatch
								<b>{{ selectedTruck.driver }}</b> ({{ selectedTruck.name}})
							</h4>
						</div>
						<div class="col-3 text-right">
							<button mat-raised-button type="button" aria-label="Close" (click)="toggleFlip('');" class="m-0 p-0">
								<span aria-hidden="true">&times;</span> Close
							</button>
						</div>
					</div>
				</div>
				<div class="card-body" id="columnfocus">
					<div class="row" cdkDropListGroup>
						<div class="w-15 " *ngIf="currentStep == 0 && !ordershidden">
							<div class="section-heading">Open Orders </div>
							<div class="">
								<mat-form-field appearance="fill">
									<mat-label>Select a Route</mat-label>
									<mat-select [formControl]="selected_route" (selectionChange)="filterOrders()">
										<mat-option [value]="''">ALL</mat-option>
										<mat-option *ngFor="let k of routes" [value]="k.route_Id">
											{{ k.route_Id }}: {{ k.route_description }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="">
								<mat-form-field appearance="fill">
									<mat-label>SCAN ORDERS</mat-label>
									<input matInput class="text-right " #scantoaddRef [formControl]="scantoadd2" (keyup.enter)="scanToAddOrder($event)">
								</mat-form-field>
							</div>
							<div class="">
								<mat-form-field appearance="fill">
									<mat-label>Status</mat-label>
									<mat-select [formControl]="pickstatus" (selectionChange)="filterOrders()" multiple>
										<mat-option *ngFor="let k of pickedFilter" [value]="k.value">
											{{ k.label }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="">
								<mat-form-field appearance="fill">
									<mat-label>Search Orders</mat-label>
									<input matInput class="text-right " #filterSearch (keyup)="onInput($event)">
								</mat-form-field>
							</div>
							<div class="col-12 text-center">
								Results: {{ filteredItems.length }}
							</div>

							<mat-divider></mat-divider>

							<div cdkDropList #openList="cdkDropList" [cdkDropListData]="filteredItems" (cdkDropListDropped)="drop($event, 'openlist')" *ngIf="filteredItems" class="item-list-vertical scrolling-left">
								<ng-container>

									<li class="dd-item dd3-item item-box item-box-vertical" *ngFor="let item of filteredItems" cdkDrag [cdkDragDisabled]="!selectedTruck.unlocked">
										<div class="dd-handle dd3-handle" (mousedown)="$event.stopPropagation()" [ngClass]="{credit: item.ordertype=='11', partialpick: (item.partial_pick),  picked: item.pick_complete, notdue: item.info.daystilldue > 0, future: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, inbound: item.expected}" (click)="viewOrder(item.order)">
											&nbsp;
										</div>
										<mat-card class="dd3-content p-1 border-0">
											<mat-card-title>#{{ item.order }} ({{ item.info.terms }}) </mat-card-title>
											<mat-divider inset></mat-divider>
											<mat-card-content [innerHTML]="item.address"></mat-card-content>
										<mat-card-content class="text-left pl-1 routecolor">
											<div class="row m-0 p-0">
												<div class="col-md-6 m-0 p-0 text-left">
													#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
												</div>
												<div class="col-md-6 m-0 p-0 text-right">
													CREATED:{{ item.info.date_created | dateAgo | uppercase  }}
												</div>
											</div>
										</mat-card-content>
											<mat-divider></mat-divider>
											<mat-card-content class="text-left w-100 dispatch-note">
												{{ item.info.date_created | dateAgo }}
											</mat-card-content>
											<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">
												{{ noNewLines(item.info.notes) }}
											</mat-card-content>
										</mat-card>
									</li>
								</ng-container>
							</div>
						</div>

						<div [ngClass]="{'w-75': (currentStep == 0 || !ordershidden), 'col-12': ordershidden }">
							<mat-stepper #stepper (selectionChange)="stepSelectionChange($event);" (selectedIndex)="currentStep" [@.disabled]="true">
								<mat-step errorMessage="Build Route">
									<ng-template matStepLabel>Build Route</ng-template>
									<div>
										<div class="row">
											<div class="col-6">
												<h5>{{ selectedTruck.driver }}</h5>
												<h5>Run Time: {{ focusruntime }} </h5>
												<h5>Estimated Return Time: {{ this.deliveryReturnTime }}</h5>
												<!-- <div>{{this.driverOverTimeLimit}}</div> -->
											</div>
											<div class="col-6 text-right" [ngClass]="{'highlighter': selectedTruck.capacity < getCapacityLoaded(selectedTruck.id) }" *ngIf="selectedTruck">Capacity: {{getCapacityLoaded(selectedTruck.id) | number: '1.2-2' }} / {{ selectedTruck.capacity | number: '1.2-2'}}
											</div>
										</div>

										<form [formGroup]="driverForm" *ngIf="inlinedriver">
											<mat-form-field appearance="outline" class="">
												<mat-label>Driver</mat-label>
												<mat-select formControlName="driver">
													<mat-option [value]="''">No Driver</mat-option>
													<mat-option *ngFor="let k of drivers" [value]="k">
														{{ k.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
											<button mat-stroked-button color="success" aria-label="" (click)="saveDriverInline()" class="mt-2">
												<mat-icon>save</mat-icon> Save
											</button>
											<button mat-stroked-button color="success" aria-label="" (click)="cancelInlineDriver()" class="mt-2">
												<mat-icon>cancel</mat-icon> cancel
											</button>
										</form>
										<div class="row">
											<div class="nav col-10 text-left">
												<button class="nav-item nav-link" mat-button (click)="hideOrders()" *ngIf="currentStep == 0">
													<mat-icon>featured_play_list</mat-icon>&nbsp;
													<span *ngIf="!ordershidden">Hide Orders</span>
													<span *ngIf="ordershidden">Add Orders</span>
												</button>
												<button mat-button class="nav-item nav-link" (click)="addPoint(selectedTruck.id)">
													<mat-icon>place</mat-icon>
													<span>Add Destination</span>
												</button>
												<button class="nav-item nav-link" mat-button (click)="toggleLock(selectedTruck)" [ngClass]="selectedTruck.unlocked ? 'text-success' : 'text-danger'">
													<mat-icon *ngIf="selectedTruck.unlocked">lock_open</mat-icon>
													<mat-icon *ngIf="!selectedTruck.unlocked">lock</mat-icon>
													<span *ngIf="selectedTruck.unlocked">UnLocked</span>
													<span *ngIf="!selectedTruck.unlocked">Locked</span>
												</button>
												<button mat-button (click)="assignDriverInline(selectedTruck)">
													<mat-icon>person_add_alt</mat-icon>
													<span>Assign Driver</span>
												</button>
												<button mat-button (click)="getRecalcSingle(this.columnfocus)">
													<mat-icon>directions_car</mat-icon>
													<span>Optimize Route</span>
												</button>
												<!--
													<div class="nav-item nav-link">
													<h5>PrePayment: {{ countPrePayments() }}</h5>
												</div>
												accordion-button-->
												<div class="nav-item nav-link">
													<h5 class="text-center ml-2 pl-2 mr-2 pr-2 mt-3" [ngClass]="unpickedcount > 0 ?'bg-danger text-white' : 'bg-success text-white'">
														{{ unpickedcount }} Unpicked Orders
													</h5>

												</div>
												<div class="nav-item nav-link" *ngIf="focusdata">
													<h5 class="text-center ml-2 pl-2 mr-2 pr-2 mt-3" >{{ focusdata.length }} Orders </h5>
												</div>
											</div>
											<div class="col-2 text-right">
												<ng-container *ngIf="countPrePayments() > 0">
													<button mat-raised-button color="accent" class="mt-2 mb-2" (click)="checkPick()">
														<!--(click)="getPrePay(selectedTruck)"-->
														Take {{ countPrePayments() }} PrePayments <mat-icon>chevron_right</mat-icon>
													</button>
												</ng-container>
												<ng-container *ngIf="countPrePayments() == 0">
													<button mat-raised-button color="accent" class="mt-2 mb-2" (click)="submitDispatch(selectedTruck.id);stepper.reset();" *ngIf="!dispatching">
														COMMIT <mat-icon>chevron_right</mat-icon>
													</button>
													<mat-spinner *ngIf="dispatching" color="accent" diameter="30">
													</mat-spinner>
												</ng-container>
											</div>
											<!-- [ngClass]="selectedTruck.unlocked ? 'enabled-list' : 'disabled-list'" -->
											<div class="col-md-8  detailed" [cdkDropListData]="focusdata" [ngClass]="selectedTruck.unlocked ? 'enabled-list' : 'disabled-list'" (cdkDropListDropped)="dropSingle($event, focusdata);" cdkDropList >
												<div *ngFor="let da of focusdata; let i = index;">
													<ng-container>
														<!-- [cdkDragDisabled]="!selectedTruck.unlocked"  -->
														<div class="row drag-modal" cdkDrag [ngClass]="hovered_item == da.orderno ? 'highlighted': '' " [cdkDragDisabled]="!selectedTruck.unlocked">

															<div class="col-auto dd-handle-dispatch dd3-handle-dispatch" [ngClass]="{ invoiced: da.invoiced == '1', credit: da.ordertype=='11', partialpick: da.partial_pick && da.invoiced == '0', picked: da.pick_complete,notdue: da.info.daystilldue > 0, futurepicked: (da.info.daystilldue > 0 && da.pick_complete), notfillable: da.notfillable , inbound: da.expected }">
																<span class="routesort">{{ da.sort + 1 }}</span>
															</div>
															<div class="col-4 mr-0 pr-0 routelist">
																<div [innerHTML]="da.address" (click)="zoomToPoint(da);hovered_item = da.orderno;"></div>
																<div class="text-left routecolor">
																	#{{ da.route }}:{{ da.routename }} ({{ da.totalvolume }})
																</div>
															</div>
															<div class="col-2 m-0 p-0 ml-1">
																<ul class="entry-list m-0 p-0 ">
																	<li>
																		<div class="spec-lable" [ngClass]="{'text-danger bold': da.info.processcc }">TERMS:</div>
																		<div class="spec-value" [ngClass]="{'text-danger bold': da.info.processcc }">{{ da.info.payterms }}</div>
																	</li>
																	<li>
																		<div class="spec-lable">ORDER:</div>
																		<div class="spec-value">{{ da.orderno }}</div>
																	</li>
																	<li>
																		<div class="spec-lable">CUSTOMER:</div>
																		<div class="spec-value">{{ da.customer }}</div>
																	</li>
																	<!-- <li>
																		<div class="spec-lable">QTY:</div>
																		<div class="spec-value">{{ da.customer }}</div>
																	</li> -->
																</ul>
															</div>
															<div class="col-4 m-0 p-0 ml-1">
																<ul class="entry-list m-0 p-0 ">
																	<li>
																		<div class="spec-lable">WANTED:</div>
																		<div class="spec-value" [ngClass]="{'notdue':da.info.daystilldue > 0}">{{ da.info.deliverydate | date:'shortDate' }} </div>
																	</li>
																	<li>
																		<div class="spec-lable">NOTES:</div>
																		<div class="spec-value dispatch-review-note">{{ noNewLines(da.info.notes) }}</div>
																	</li>
																	<!-- <li>
																		<div class="spec-lable">TIME:</div>
																		<div class="spec-value">{{ da.info.rtime }}</div>
																	</li> -->
																	<li>
																		<div class="spec-lable">EST:</div>
																		<div class="spec-value">{{ da.info.dsptime }}</div>
																	</li>
																</ul>
															</div>
															<div class="col-1 text-right m-0 p-0" (mousedown)="$event.stopPropagation()">
																<button mat-icon-button (click)="viewOrder(da.orderno)" color="accent">
																	<mat-icon>visibility</mat-icon>
																</button>
																<br>
																<button mat-icon-button (click)="removeFromOms(da.orderno, i)" color="accent">
																	<mat-icon>remove</mat-icon>
																</button>
															</div>
														</div>
													</ng-container>
												</div>
											</div>

											<div class="col-md-4 mt-0 pt-0 mb-0 pb-0" id="map" [ngClass]="(currentStep == 0) ?  '' : 'd-none'">

											</div>
										</div>


									</div>
								</mat-step>
								<mat-step errorMessage="Payments" *ngIf="countPrePayments() > 0">
									<ng-template matStepLabel>Payments</ng-template>
									<ng-container>
										<div class="row mt-3 mb-3">
											<div class="col-6 text-left">
												<button mat-raised-button matStepperPrevious color="accent">Back</button>
											</div>
											<div class="col-6 text-right" *ngIf="!needpayments.length">
												<button mat-raised-button color="accent" class="mt-2 mb-2" (click)="submitDispatch(columnfocus);stepper.reset();" [disabled]="dispatching" *ngIf="!dispatching">
													COMMIT <mat-icon>chevron_right</mat-icon>
												</button>
												<mat-spinner *ngIf="dispatching" color="accent" diameter="30">
												</mat-spinner>
											</div>
										</div>
										<ng-container *ngIf="invoicesCreated">
											<table class="table table-sm">
												<tr>
													<th>Order#</th>
													<th>Customer</th>
													<th>Amount</th>
												</tr>
												<tr *ngFor="let inv of invoicesCreated">
													<td>{{ inv.orderno }}</td>
													<td [innerHTML]="inv.address"></td>
													<td>{{ inv.banktranstype }} <b>**PAID**</b> {{ inv.amount | currency }}</td>
												</tr>
											</table>
										</ng-container>
										<div class="row" *ngIf="needpayments">
											<div class="table table-responsive text-center" *ngIf="sending">
												<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
													<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
												</svg>
											</div>

											<div class="col-12 text-right" *ngIf="!sending && needpayments.length">
												<button mat-raised-button color="accent" (click)="createPayments()">Create Invoices & Payments</button>
											</div>
										</div>
										<ng-container *ngIf="needpayments">
											<div class="row border-bottom status-{{da.status_id}} mt-3" *ngFor="let da of needpayments;trackBy: identify">
												<ng-container>
													<div class="col-5 text-left">
														<button mat-icon-button color="accent" (click)="removeInvoice(da)">
															<mat-icon>remove</mat-icon>
														</button>
														#{{ da.details.header.orderno }} ({{ da.details.header.debtorno }}.{{ da.details.header.branchcode }}) {{ da.details.header.name }}
													</div>
													<!-- [ngClass]="{ 'notdue': da.details.days_till_due > 0 }"  -->
													<div class="col-5 text-right" >
														Due Date: {{ da.details.due_date }} ({{ da.details.days_till_due }}) {{ da.details.header.terms }}
													</div>
													<div class="col-2 text-right">
														<b>Total:</b> {{ da.details.totals.total | currency }}
													</div>
													<div class="col-md-2 ml-0 mr-0 pr-0 pl-0">
														<ul class="entry-list m-0 p-0 " >
															<li>
																<div class="spec-label">Subtotal</div>
																<div class="spec-value">{{ da.details.totals.subtotal | currency }}</div>
															</li>
															<li>
																<div class="spec-label">Freight</div>
																<div class="spec-value">{{ da.details.totals.freight | currency }}</div>
															</li>
														</ul>
													</div>
													<div class="col-md-2 ml-0 mr-0 pr-0 pl-0">
														<ul class="entry-list m-0 p-0 " >
															<li>
																<div class="spec-label">Fee</div>
																<div class="spec-value">{{ da.details.totals.fees | currency }}</div>
															</li>
															<li>
																<div class="spec-label">Tax</div>
																<div class="spec-value">{{ da.details.totals.tax | currency }}</div>
															</li>
														</ul>
													</div>

													<ng-container>
														<div class="col-7 ml-0 mr-0"  >
															<div class="row" *ngFor="let p of da.details.payments">
																<ng-container *ngIf="da.cc_charge">
																	<div class="" [ngClass]="!p.addingcardparent ? 'col-md-3' : 'd-none'">
																		<!-- {{ p.cc_charge | json }} -->
																		<ng-container>
																			<mat-form-field appearance="outline" >
																				<mat-label>Credit Card</mat-label>
																				<mat-select class="movetotop" [(value)]="p.cc_charge" (selectionChange)="setCreditCard($event.value, p)"  [ngClass]="da.preselected ? 'cc_charge' : '' ">
																					<mat-option [value]="false">DO NOT CHARGE</mat-option>
																					<mat-option *ngFor="let card of da.cardsonfile" [value]="card">
																						{{ card.number }} {{ card.expiry_month}}/{{ card.expiry_year}}
																					</mat-option>
																				</mat-select>
																			</mat-form-field>
																		</ng-container>
																	</div>
																</ng-container>
																<div class="mt-3" [ngClass]="p.addingcardparent ? 'col-md-6' : 'col-md-1'">
																	<button mat-icon-button color="accent" aria-label="Add Card" (click)="toggleAddingCard(p)">
																		<ng-container *ngIf="!addingcardparent">
																			<mat-icon>credit_card</mat-icon>
																		</ng-container>
																		<ng-container *ngIf="addingcardparent">
																			<mat-icon>close</mat-icon>
																		</ng-container>
																	</button>
																	<app-dispatch-add-card [customer]="da.details.header" (newcarddata)="updateProfile($event, p)" *ngIf="p.addingcardparent"></app-dispatch-add-card>
																</div>

																<div class="col-md-4" *ngIf="!p.addingcardparent">
																	<ng-container>
																		<mat-form-field appearance="outline" >
																			<span matPrefix>
																				<mat-icon (click)="removePayment(da.details.payments, da)">remove</mat-icon>
																			</span>
																			<mat-label>Payment Type</mat-label>
																			<mat-select requiredclass="movetotop" [(value)]="p.payment_method" class="movetotop" (selectionChange)="setPaymentMethod($event.value, p)" >
																				<mat-option *ngFor="let method of payment_methods" [value]="method.paymentid">
																					{{method.paymentname }}
																				</mat-option>
																			</mat-select>
																		</mat-form-field>
																	</ng-container>
																</div>
																<div class="col-md-4 ml-0 mr-0 pr-0 pl-0 " *ngIf="!p.addingcardparent">
																	<mat-form-field appearance="outline">
																		<mat-label>Payment</mat-label>
																		<input matInput required class="text-right " [value]="p.payment" (input)="setPayment($event.target.value, p)">
																		<mat-hint>Due: {{ da.details.total - pTotal(da.details.payments) | currency }}</mat-hint>
																	</mat-form-field>
																</div>
															</div>
														</div>
													</ng-container>
													<div class="col-md-1 ml-0 mr-0 text-center mt-4 mb-3 ">
														<button mat-mini-fab color="primary" aria-label="Add Payment" (click)="addPayment(da, da.details.payments)">
															<mat-icon>call_split</mat-icon>
														</button>
													</div>
												</ng-container>
											</div>
										</ng-container>
										<ng-container *ngIf="!needpayments.length && !invoicesCreated.length">
											<div class="row">
												<div class="col-12 text-center">
													<h4>No Pre Payments Found</h4>
												</div>
											</div>
										</ng-container>
									</ng-container>

								</mat-step>
								<mat-step>
									<ng-template matStepLabel>Dispatch</ng-template>
									<div>
										<div class="row mt-3 mb-3">
											<div class="col-6 text-left">
												<button mat-raised-button color="accent" matStepperPrevious>Back</button>
											</div>
											<div class="col-6 text-right">
												<button mat-raised-button (click)="submitDispatch(selectedTruck.id);stepper.reset()" color="accent">Commit Dispatch</button>
											</div>
										</div>
										<h4>{{ selectedTruck.driver }}</h4>
										<h5>Run Time: {{ focusruntime }}</h5>
										<div *ngFor="let da of focusdata">
											<div class="row ">
												<div class="col-6 mr-0 pr-0" [innerHTML]="da.address">
												</div>
												<div class="col-6 m-0 p-0">

													<ul class="entry-list m-0 p-0">
														<li>
															<div class="spec-lable">Order#</div>
															<div class="spec-value">{{ da.orderno }}</div>
														</li>
														<li>
															<div class="spec-lable">Customer#</div>
															<div class="spec-value">{{ da.customer }}</div>
														</li>
														<li>
															<div class="spec-lable">Terms</div>
															<div class="spec-value">{{ da.info.terms }}</div>
														</li>
														<li>
															<div class="spec-lable">EST.</div>
															<div class="spec-value">{{ da.info.dsptime }}</div>
														</li>
														<li *ngIf="getPaid(da.orderno)">
															<div class="spec-lable"></div>
															<div class="spec-value">** PAID **</div>
														</li>
													</ul>
												</div>
											</div>
											<mat-divider></mat-divider>
										</div>
										<div class="row border-bottom pickitem" *ngFor="let item of runlist">
											<div class="col-10 text-left">
												<b>DESCRIPTION:</b> {{ item.description }}<br>
											</div>
											<div class="col-2 text-right">
												QTY {{ item.ordered }} | QOH {{ item.qoh }}
											</div>
											<div class="offset-lg-1 col-6 text-left text-nowrap">
												ITEM: <b>{{ item.stockid }}</b>
												<br />
												ORDER: <span class="text-primary"><b>{{ item.orderno }}</b></span>
											</div>
											<div class="col-5 text-right">
												<h6>BIN: <span class="text-primary"><b>{{ item.bin }}</b></span></h6>
												<h6 class="text-primary">{{ item.deliverydate | dateAgo | uppercase  }}</h6>
											</div>
										</div>
									</div>
								</mat-step>
							</mat-stepper>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- route building all trucks -->
		<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip != 'active' ? '' : 'd-none'">
			<ng-container>
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-5">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Route Builder</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body" *ngIf="pickable && flip == 'inactive'">
							<ng-container *ngIf="editactivedispatch">
								<button mat-raised-button color="accent" (click)="editactivedispatch = false;">
									<mat-icon>
										chevron_left
									</mat-icon>
									Back
								</button>
								<div class="row">
									<div class="col-6 text-left">
										<a class="btn btn-sm btn-danger" href="{{this.delsheetlink + viewactiverun}}">
											<i class="fa fa-file-pdf-o"></i>
										</a>
										<button class="btn btn-sm btn-success" (click)="exportDisaptchXls(viewactiverun)">
											<i class="fa fa-file-excel-o"></i>
										</button>
									</div>
									<div class="col-6 text-right">
										<button class="btn btn-sm btn-primary" (click)="deleteRun(viewactiverun)">
											Delete Run
										</button>
										<button class="btn btn-sm btn-primary" (click)="deliverRun(viewactiverun)">
											Mark all Delivered
										</button>
									</div>
								</div>
								<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
									<mat-tab label="Run Sheet" class="no-overflow fill-available">
										<app-dispatches-reorg-after-commit (removed)="loadData()" [reloaddispatch]="reloaddispatch" [isdispatch]="isdispatch" [selectedId]="viewactiverun"></app-dispatches-reorg-after-commit>
									</mat-tab>
									<mat-tab label="Load Sheet" class="no-overflow fill-available">
										<app-dispatches-load-sheet-only [selectedId]="viewactiverun"></app-dispatches-load-sheet-only>
									</mat-tab>
									<mat-tab label="OC" class="no-overflow fill-available">
										<app-dispatches-order-confirmation [isdispatch]="isdispatch" [selectedId]="viewactiverun"></app-dispatches-order-confirmation>
									</mat-tab>
								</mat-tab-group>
							</ng-container>
							<ng-container *ngIf="!editactivedispatch">
								<div class="row" cdkDropListGroup>

									<div class="w-15 p-0 m-0 ">
										<div class="section-heading">
											Open Orders
											&nbsp;&nbsp;&nbsp;<mat-slide-toggle class="ml-auto mr-0" [formControl]="simpletoggle" [color]="'accent'">
												Scan
											</mat-slide-toggle>
										</div>
										<!--<button class="btn btn-sm  " (click)="exportExcel(filteredItems)">XLS</button>
										<mat-vertical-stepper [linear]="false" #dispatchsteps>
											<mat-step>
												<ng-template matStepLabel>Truck</ng-template>

											</mat-step>
											<mat-step>
												<ng-template matStepLabel>Route</ng-template>
											</mat-step>
										</mat-vertical-stepper>
										-->
										<div class="row">
											<div class="col-12">
												<mat-form-field appearance="fill">
													<mat-label>Select a Truck</mat-label>
													<mat-select [(ngModel)]="selectedColumn" (selectionChange)="updateTruckDisplay()">
														<mat-option [value]="''">ALL</mat-option>
														<mat-option *ngFor="let t of allsortcolumns" [value]="t.id">
															{{t.name }}
														</mat-option>
													</mat-select>
													<span matSuffix *ngIf="selectedColumn != ''" (click)="clearInput()">
														<mat-icon>clear</mat-icon>
													</span>
												</mat-form-field>
											</div>

											<div class="col-12">
												<mat-form-field appearance="fill">
													<mat-label>SCAN ORDERS</mat-label>
													<input matInput class="text-right" id="scantoadd" [formControl]="scantoadd1" #scantoadd (keyup.enter)="scanToAddOrder($event)">
												</mat-form-field>
											</div>
											<mat-divider></mat-divider>

											<div class="col-12">
												<mat-form-field appearance="fill">
													<mat-label>Order Status</mat-label>
													<mat-select [formControl]="pickstatus" (selectionChange)="filterOrders()" multiple>
														<mat-option *ngFor="let k of pickedFilter" [value]="k.value">
															{{ k.label }}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>

											<div class="col-12">
												<mat-form-field appearance="fill">
													<mat-label>Select a Route</mat-label>
													<mat-select [formControl]="selected_route" (selectionChange)="filterOrders()">
														<mat-option [value]="''">ALL</mat-option>
														<mat-option *ngFor="let k of routes" [value]="k.route_Id">
															{{ k.route_Id }}: {{ k.route_description }}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
											<div class="col-12">
												<mat-form-field appearance="fill">
													<mat-label>Search Orders</mat-label>
													<input matInput class="text-right " #filterSearch (keyup)="onInput($event)">
												</mat-form-field>
											</div>
											<div class="col-12 text-center">
												Results: {{ filteredItems.length }}
											</div>
										</div>
										<mat-divider></mat-divider>
										<!-- open orders -->
										<div cdkDropList #openList="cdkDropList" [cdkDropListData]="filteredItems" class="item-list-vertical scrolling-left " (cdkDropListDropped)="drop($event, 'openlist')" *ngIf="filteredItems">
											<ng-container>
												<li class="dd-item dd3-item item-box" *ngFor="let item of filteredItems" cdkDrag [cdkDragDisabled]="selectedColumn.length == 1">
													<!--<div class="card item-box mt-0 mb-1 boxhide" > -->

													<div class="dd-handle dd3-handle" (mousedown)="$event.stopPropagation()" [ngClass]="{claimed: item.claimstatus,credit: item.ordertype=='11', partialpick: (item.partial_pick), picked: item.pick_complete, notdue: item.info.daystilldue > 0, futurepicked: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, invoiced: item.invoiced == '1', notfillable: item.notfillable, inbound: item.expected }" (click)="viewOrder(item.order)">&nbsp;</div>
													<mat-card class="dd3-content p-1 border-0">

														<mat-card-title>#{{ item.order }} <span [ngClass]="{'text-danger bold': item.info.processcc }">({{ item.info.terms }})</span> </mat-card-title>
														<mat-divider inset></mat-divider>
														<mat-card-content [innerHTML]="item.address">
														</mat-card-content>
														<mat-card-content class="text-left pl-1 routecolor">
															<div class="row m-0 p-0">
																<div class="col-md-12 m-0 p-0 text-left">
																	#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
																</div>

															</div>
														</mat-card-content>

														<mat-divider></mat-divider>

														<mat-card-content class="text-left w-100 dispatch-note">
															{{ item.info.date_created | dateAgo }}
														</mat-card-content>

														<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">
															{{ noNewLines(item.info.notes) }}
														</mat-card-content>

														<mat-card-actions align="end" *ngIf="selectedColumn != ''">
															<button mat-flat-button (click)="$event.stopPropagation();moveItem(item);">ADD</button>
														</mat-card-actions>
													</mat-card>
												</li>
											</ng-container>
										</div>
									</div>
									<div class="w-75 p-0 m-0 rightside" *ngIf="sortcolumns">
										<div class="small" *ngFor="let column of sortcolumns">
											<div class="p-0 m-0 ">
												<div class="row p-0 m-0 table">
													<div class="row p-0 m-0 table">
														<div class="col-12 col-lg-12 pt-0 pb-0 mt-0 mb-0 card-header pl-2 pr-0 mr-0 ml-0 card-header-{{color}}-6 no-radius">
															<div class="row m-0 p-0 ">
																<div class="col-auto smaller-font">

																	<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
																		<mat-icon>more_vert</mat-icon>
																	</button>
																	<mat-menu #menu="matMenu">

																		<button mat-menu-item (click)="assignDriver(column)">
																			<mat-icon>person_add_alt</mat-icon>
																			<span>Assign Driver</span>
																		</button>
																		<mat-divider></mat-divider>
																		<a mat-menu-item href="{{this.delsheetlink + column.id}}" *ngIf="activeDispatches[column.id]?.dispatched?.length">
																			<mat-icon>print</mat-icon>PDF
																		</a>
																		<button mat-menu-item (click)="exportExcel(carddata[column.id])" *ngIf="activeDispatches[column.id]?.dispatched?.length">
																			<mat-icon>notifications_off</mat-icon>
																			<span>XLS</span>
																		</button>
																		<mat-divider></mat-divider>

																		<button mat-menu-item (click)="changeTruck(column.id);" *ngIf="!activeDispatches[column.id]?.dispatched?.length">
																			<mat-icon>arrow_forward</mat-icon>
																			<span>Change Truck</span>
																		</button>

																		<button mat-menu-item (click)="clearOmsColumn(column.id);" *ngIf="!activeDispatches[column.id]?.dispatched?.length">
																			<mat-icon>cancel</mat-icon>
																			<span>Remove All</span>
																		</button>

																		<button mat-menu-item (click)="addPoint(column.id)">
																			<mat-icon>place</mat-icon>
																			<span>Add Destination</span>
																		</button>
																		<ng-container *ngIf="activeDispatches[column.id]">
																			<button mat-menu-item (click)="clearRunSaveForLater(column.id)" *ngIf="activeDispatches[column.id].dispatched.length">
																				<mat-icon>360</mat-icon>
																				<span>Clear Run & Invoice Later</span>
																			</button>
																		</ng-container>
																	</mat-menu>
																	<span class="larger">
																		<b>
																			{{ column.name }}
																			<span *ngIf="column.driver">({{ column.driver }})</span>
																			<span *ngIf="column.driver">&nbsp;&nbsp;{{ column.phone }}&nbsp;&nbsp;</span>
																			<span *ngIf="!column.driver">( N/A )</span>
																		</b>
																	</span>
																	<span *ngIf="column.totaltimedsp != '--'">
																		&nbsp;&nbsp;&nbsp;&nbsp;{{ column.totaltimedsp }}
																	</span>

																	<!--


																	<button mat-button data-toggle="modal" data-target="#detailsModal" color="white" (click)="drawMap(column.id)">
																		<mat-icon>map</mat-icon>
																		<span>Map</span>
																	</button>

																	<button mat-button (click)="getRecalc(column.id)">
																		<mat-icon>directions_car</mat-icon>
																		<span>Optimize Route</span>
																	</button>

																	<button mat-button data-toggle="modal" data-target="#runList" (click)="runList(column.id,column)">
																		<mat-icon>list</mat-icon>
																		<span>View Run</span>
																	</button>
																	-->
																</div>
																<div class="col-auto ml-auto  smaller-font mt-2">
																	<b>Orders: ({{ carddata[column.id].length }}) </b>
																</div>
																<div class="col-auto mr-0 smaller-font mt-2">
																	<span [ngClass]="{'highlighter': column.capacity < getCapacityLoaded(column.id) }">Capacity: {{getCapacityLoaded(column.id) | number: '1.2-2' }} / {{ column.capacity | number: '1.2-2'}}</span>

																</div>
																<div class="col-auto ml-auto mr-0 smaller-font ">
																	<ng-container>
																		<!-- <mat-form-field appearance="outline" color="white">
																			<mat-label>Select Scanner</mat-label>
																			<mat-select (selectionChange)="updateDeviceDisplay($event, column.id )">
																				<mat-option *ngFor="let id of scanner" [value]="id.value">
																					{{id.viewValue}}
																				</mat-option>
																			</mat-select>
																		</mat-form-field> -->
																	</ng-container>
																</div>
																<div class="col-auto ml-auto mr-0 smaller-font mt-2">
																	<!-- data-toggle="modal" data-target="#detailsModal" -->
																	<ng-container *ngIf="activeDispatches[column.id]">
																		<span *ngIf="activeDispatches[column.id].dispatched.length" (click)="getActiveRun(column.id); updateDeviceDisplay('LOADING1', column.id)">
																			<b>ACTIVE DISPATCH</b>
																			&nbsp;&nbsp;
																			{{ activeDispatches[column.id].timedisp }}
																			&nbsp;&nbsp;
																		</span>
																	</ng-container>

																	<button n-submit mat-flat-button color="{{ color }}" class="mt-0 btn btn-small" (click)="toggleFlip(column.id);" (dblclick)="$event.preventDefault();" *ngIf="carddata[column.id].length">
																		<mat-icon>local_shipping</mat-icon>
																		<span>&nbsp;Review</span>
																	</button>


																</div>
															</div>
														</div>
														<div class="col-12 col-lg-12 scrolling-wrapper pl-0 ml-0">
															<div id="column-{{column.id}}" cdkDropList [cdkDropListData]="carddata[column.id]" cdkDropListOrientation="horizontal" class="item-list" (cdkDropListDropped)="drop($event, column.id)">
																<div *ngIf="carddata[column.id]">
																	<ng-container *ngFor="let item of carddata[column.id]">
																		<div class="card item-box pt-0 pl-2 mt-0 m-0 boxhide" cdkDrag>
																			<div class="dd-handle dd3-handle" [ngClass]="{credit: item.ordertype=='11', partialpick: (item.partial_pick ), picked: item.pick_complete,notdue: item.info.daystilldue > 0 , futurepicked: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, invoiced: item.invoiced == '1', inbound: item.expected }">&nbsp;</div>
																			<mat-card class="dd3-content pt-1 pb-1 pl-0 pr-0 d-item-wrapper">
																				<mat-card-title>#{{ item.order }} <span [ngClass]="{'text-danger bold': item.info.processcc }">({{ item.info.terms }})

																					<small class="dispatch-note">{{ item.info.date_created | dateAgo }}</small>
																				</span> </mat-card-title>
																				<mat-divider inset></mat-divider>
																				<mat-card-content class="no-shadow d-item" [innerHTML]="item.address">
																				</mat-card-content>
																				<mat-card-content class="text-left pl-1 routecolor">
																					<div class="row m-0 p-0">
																						<div class="col-md-6 m-0 p-0 text-left">
																							#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
																						</div>
																					</div>
																				</mat-card-content>

																				<mat-divider></mat-divider>


																				<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">
																					{{ noNewLines(item.info.notes) }}



																				</mat-card-content>
																			</mat-card>
																		</div>
																	</ng-container>
																</div>
																<ng-container *ngIf="activeDispatches[column.id] && !carddata[column.id].length">
																	<div class="card item-box-small pt-0 pl-2 mt-0 m-0 w-100" *ngIf="activeDispatches[column.id].dispatched.length" >
																		<h5 class="text-center m-0 p-0 pointer mt-3" (click)="getActiveRun(column.id)">
																			<b>ACTIVE DISPATCH</b>
																			&nbsp;&nbsp;
																			{{ activeDispatches[column.id].timedisp }}
																			&nbsp;&nbsp;
																		</h5>
																	</div>
																</ng-container>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>