import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';
import { DashboardService } from '../../services/dashboard.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';


@Component({
	selector: 'app-negative-items',
	templateUrl: './negative-items.component.html',
	styleUrls: ['./negative-items.component.scss']
})
export class NegativeItemsComponent {
	negativeItems: any = [];
	color: string = 'blue';
	subscritionsactive: any = false;
	constructor(
		private dashboardService: DashboardService,
		private globalSearchService: GlobalSearchService,
		private router: Router,
		private location: Location,
		private route: ActivatedRoute
	) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.loadData();
	}

	loadData(): void {
		if(this.subscritionsactive) {
			this.subscritionsactive.unsubscribe();
		}
		this.subscritionsactive= this.dashboardService.getNegative().subscribe(async (results: any) => {
			this.negativeItems = (results.data) ? results.data : [];
		});
	}

	viewItem(item: any): void {
		this.router.navigate(['/inventory/view/' + item.stockid]);
	}

	back(): void {
		this.location.back();
	}

	ngOnDestroy(): void {
	  if (this.subscritionsactive) {
		this.subscritionsactive.unsubscribe();
	  }
	}
}
