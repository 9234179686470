import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import {UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup  } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';

@Component({
	selector: 'app-customer-statements',
	templateUrl: './customer-statements.component.html',
	styleUrls: ['./customer-statements.component.scss']
})
export class CustomerStatementsComponent implements OnInit {
	@Input() customerdata;
	statements:  any;
	config: any = false;
	statementLink: any = '';
	email = new UntypedFormControl('')
	sending: any = false;
	searchsubscripiton: any = false;
	constructor(private route: ActivatedRoute, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {
		const request ={ debtorno: this.customerdata.debtorno, branchcode: this.customerdata.branchcode }
		this.statementLink =  this.config.apiServer.baseUrl+this.config.apiServer.statementLink+'&debtorno='+this.customerdata.debtorno;
		this.customerService.getStatements(request).subscribe( (results: any) => {
			if(this.customerdata && this.customerdata.customer.statementemailaddress) {
				this.email.setValue(this.customerdata.customer.statementemailaddress);
			}
			this.statements = results;
		})
	}

	emailStatement() {
		const data = {
			debtorno: this.customerdata.debtorno,
			branchcode: this.customerdata.branchcode,
			type: 'email',
			email: this.email.value,
		}
		this.sending = true;
		if (this.searchsubscripiton) {
			this.searchsubscripiton.unsubscribe();
		}
		this.searchsubscripiton = this.customerService.sendStatement(data).subscribe((results: any) => {
			this.globalSearchService.showNotification('Email Sent', 'success', 'bottom', 'right');
			this.sending = false;
		});
	}

}
