<nav class="navbar bg-transparent m-0 p-0 noshadow">
	<ul class="navbar-nav bg-transparent ">

		<ng-container *ngIf="config.env.package === 'beauty'">
			<a class="nav-item professional-button forced" mat-raised-button [routerLink]="'/orders/entry'" *ngIf="!isPosUser">
				<mat-icon class="professional-icon forced">note_add</mat-icon>
			</a>
			<a class="nav-item professional-button forced" mat-raised-button [routerLink]="'/counter-pos'" *ngIf="isPosUser">
				<mat-icon class="professional-icon forced">note_add</mat-icon>
			</a>
		</ng-container>

		<!-- <ng-container *ngIf="config.env.package === 'beauty'">
		  <a class="ml-auto mr-auto nav-item fun-button" mat-raised-button [routerLink]="'/orders/entry'">
			<mat-icon class="fun-icon">add_shopping_cart</mat-icon>
		  </a>
		</ng-container> -->

		<li class="nav-item no-border no-shadow" *ngIf="user && locations && locations.length > 1">
			<form class="form-inline my-2 my-lg-0">
				&nbsp;&nbsp;&nbsp;
				<select [(value)]="selected_location" (change)="changeLocation($event.target)" class="select-dropdown professional-select forced">
					<option [value]="loc.loccode" [selected]="selected_location == loc.loccode" *ngFor="let loc of locations">{{ loc.locationname }}</option>
				</select>
			</form>
		</li>

		<ng-container *ngIf="user && user.user && user.user.fullaccess === '9'">
			&nbsp;&nbsp;
			<li class="nav-item no-border no-shadow">
				<form class="form-inline my-2 my-lg-0">
					<app-config-switcher (reload)="initLoad($event)"></app-config-switcher>
				</form>
			</li>
		</ng-container>
	</ul>
</nav>
